import Vue from 'vue'
import router from '@/router'
import { isNativeApp, platform, Plugins } from '@/plugins/native-app/capacitor'
import LegacyApi from '@/lib/api/legacy'
import dayjs from 'dayjs'
import log from '@/plugins/log'
import appReleaseDate from '@/lib/app-release-date'
import logRocket from '@/plugins/third-party/log-rocket'
import { get, isEmpty } from 'lodash'
import clearBrowserCache from '@/lib/clear-cache'

const STORAGE_KEY = 'sml-app'
const existingData = window.localStorage[STORAGE_KEY] || null

const initialState = () => {
  return {
    api: new LegacyApi().hydrate(JSON.parse(existingData)),
    routeLoading: false,
    routeHistory: [],
    isOutOfDate: false,
    isMaintenanceModeEnabled: false,
    isMaintenanceModeBypassed: false,
    latestVersion: {
      web: { version: '0.0.0', releaseDate: '2030-10-21', forceUpdate: false },
      ios: { version: '0.0.0', releaseDate: '2030-10-21', forceUpdate: false },
      android: { version: '0.0.0', releaseDate: '2030-10-21', forceUpdate: false }
    },
    globalMessage: null,
    sessionStartedTimestamp: dayjs(),
    lastVisitedTimer: null,
    lastVisitedTimestamp: 0,
    mobileMenuOpen: false
  }
}

const getters = {
  getAccount: state => {
    return state.api.data.account ? state.api.data.account[0] : {}
  },

  getUser: state => {
    return state.api.data.user ? state.api.data.user[0] : {}
  },

  // Some of the very old legacy APIs require full user details in a format never
  // sent back to us (i.e the format we have the post in originally), so let's create a flat version here
  getUserFlat: state => {
    return state.api.data.user
      ? {
          ...state.api.data.user[0],
          mobile: get(state.api.data.user[0], 'mobiles[0].number'),
          landline: get(state.api.data.user[0], 'landlines[0].number'),
          emailAddress: get(state.api.data.user[0], 'emailAddresses[0].emailAddress')
        }
      : {}
  },

  getCompany: state => {
    return state.api.data.company ? state.api.data.company[0] : {}
  },

  getLocations: state => {
    return state.api.data.location ? state.api.data.location : []
  },

  getActiveSubscriptions: state => {
    return state.api.data.subscriptions || []
  },

  getApp: (state, getters, rootState, rootGetters) => {
    let app = {}

    const account = getters.getAccount
    const user = getters.getUser

    app.genericSupportEmail = process.env.VUE_APP_GENERIC_SUPPORT_EMAIL
    app.genericSupportUserRef = process.env.VUE_APP_GENERIC_SUPPORT_USER_REF
    app.device = rootState.device.info
    app.version = rootState.device.version
    app.releaseDate = rootState.device.releaseDate
    app.user = user
    app.userFlat = getters.getUserFlat
    app.company = getters.getCompany
    app.locations = getters.getLocations
    app.lastVisitedInDays = dayjs().diff(state.lastVisitedTimestamp, 'day')
    app.lastVisitedDate = dayjs(state.lastVisitedTimestamp)
    app.lastVisitedTimestamp = state.lastVisitedTimestamp
    app.legacyEndDate = Vue.prototype.$date('2020-06-15 20:00:00')

    // Current account selected from accounts
    app.account = account
    app.platform = platform
    app.language = rootState.locale.language
    app.countryCode = rootState.locale.countryCode || 'GB'
    app.unread = rootGetters['notifications/unread'] || { total: 0 }

    app.subscriptions = getters.getActiveSubscriptions
    app.activeSubscriptionPlan = rootGetters['payments/getActiveSubscriptionPlan']
    app.activeSubscriptionPlanName = rootGetters['payments/getActiveSubscriptionPlan']?.shortName || ''
    app.featureRestrictions = rootGetters['payments/getFeatureRestrictions']

    return app
  },

  appIs: (state, getters, rootState, rootGetters) => {
    let appIs = {}

    const appData = state.api.data

    appIs.isOutOfDate = state.isOutOfDate === true
    appIs.firstLoad = state.routeHistory.length < 2
    appIs.loading = state.api.loading
    appIs.finishedIntialLoad = !isEmpty(appData)
    appIs.online = rootState.device.network.isOnline
    appIs.inFocus = rootState.device.hasFocus
    appIs.retina = window.devicePixelRatio > 1
    appIs.legacyBrowser = rootState.device.browser.outOfDate
    appIs.capacitorSupported = Plugins !== undefined
    appIs.native = isNativeApp
    appIs.mobileOrNative = isNativeApp || Vue.prototype.$screen.width <= 640
    // appIs.mobileOrNative = true
    appIs[platform] = true
    appIs.usingTouch = window.matchMedia('(any-pointer: coarse)').matches
    appIs.setup = false
    appIs.firstVisit = state.lastVisitedTimestamp === 0
    appIs[process.env.VUE_APP_ENVIRONMENT] = true

    appIs.auth = appData && appData.auth === true ? true : false

    appIs.loaded = appData.status !== undefined

    if (appIs.auth) {
      const user = getters.getUser
      const company = getters.getCompany

      appIs.god = user.fullAccess === 1
      appIs.loggedInAsAnotherUser = user.masterUsed === 1
      appIs.loggedInAsHelpdesk = user.userRef === process.env.VUE_APP_GENERIC_SUPPORT_USER_REF
      appIs.userSetup = user.firstName && user.firstName !== ''
      appIs.companySetup = !!company.name
      appIs.usedNativeBefore = get(rootState, 'storage.data.nativeAppUser') || false

      appIs.securePaymentsBlocked = get(rootState, 'securePayments.profileApi.data.flags.blocked') === true
      appIs.securePaymentsSuspicious = get(rootState, 'securePayments.profileApi.data.flags.suspicious') === true
      appIs.suspicious = appIs.securePaymentsSuspicious || user.suspect === 1

      appIs.portalOwner = rootGetters['portals/ownedPortals'].length > 0
      appIs.verified = user.verified === 1

      if (appData.account) {
        appIs.setup = appData.account.length > 0 && appData.company.length > 0
        appIs.multiAccount = Object.values(user.accounts).length > 1

        // Company types
        if (company.types) {
          company.types.forEach(companyType => {
            appIs[`company${companyType.type}`] = true
          })
        }

        const subscriptionPlanShortName = rootGetters['payments/getActiveSubscriptionPlan']?.shortName || ''

        // appIs.gold appIs.silver
        // Don't use these for feature restriction logic, use app.featureRestrictions instead
        if (subscriptionPlanShortName) {
          appIs[subscriptionPlanShortName.toLowerCase()] = true

          appIs.payingUser = ['sml-silver', 'sml-gold', 'sml-platinum'].includes(
            rootGetters['payments/getActiveSubscriptionPlan']?.slug
          )
        }

        appIs.isLegacyPremiumSubscriber = !!getters.getActiveSubscriptions.find(
          subscription => subscription.depreciated === 1
        )

        appIs.iosSubscriber = !!getters.getActiveSubscriptions.find(
          subscription => subscription.purchasePlatform === 'ios'
        )

        if (appIs.god) {
          appIs.platinum = true
        }
      }
    }

    return appIs
  }
}

const actions = {
  async get({ state, getters, dispatch }) {
    await state.api.get('app.get', state.model)

    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state.api.data))

    dispatch('auth/checkNativeLogin', null, { root: true })

    dispatch('checkAppOutOfDate')

    const user = getters.getUser

    // Let's go get app.storage, if we aren't logged it in with immediately fire initialLoadFinished
    // which we use for cookie checking / kicking off third party scripts
    dispatch('storage/get', null, { root: true })

    // Do we have a user?
    if (user.status) {
      dispatch('notifications/getUnreads', null, { root: true })

      Vue.prototype.$analytics.setUser(user.userRef)

      // Enrich Sentry error logs with the current user
      if (process.env.VUE_APP_REPORT_ERRORS === 'true') {
        const Sentry = require('@sentry/browser')

        Sentry.configureScope(scope => {
          scope.setUser({
            ...user,
            email: user.emailAddresses[0].emailAddress,
            id: user.userRef,
            username: `${user.firstName} ${user.lastName}`
          })
        })

        if (isNativeApp) {
          logRocket.identify({
            userRef: user.userRef,
            name: `${user.firstName} ${user.lastName}`,
            email: user.emailAddresses[0].emailAddress
          })
        }
      }

      dispatch('watchlist/getWatchlist', null, { root: true })
    }
  },

  checkAppOutOfDate({ state, commit, rootState }) {
    // Don't keep spamming app out of date warning if they'e seen it this session
    if (state.isOutOfDate) {
      return false
    }

    log.debug(JSON.stringify(state.latestVersion[platform]))
    log.debug(`Mine: ${rootState.device.version}, Latest: ${state.latestVersion[platform].version}`)

    if (
      state.latestVersion[platform].version !== '0.0.0' &&
      state.latestVersion[platform].version !== rootState.device.version
    ) {
      if (isNativeApp) {
        // If native app then we give more time for the app stores to send the update to devices after which we prompt them to update
        const versionDifferenceInDays = dayjs(state.latestVersion[platform].releaseDate).diff(
          appReleaseDate,
          'day'
        )

        // In case we don't update that often let's check the new version has been out at least 7 days
        const latestVersionReleasedForDays = dayjs().diff(dayjs(state.latestVersion[platform].releaseDate), 'day')

        log.debug('versionDifferenceInDays', versionDifferenceInDays)
        log.debug('latestVersionReleasedForDays', latestVersionReleasedForDays)

        const nativeMaxDaysOutOfDate = 14
        const nativeMinDaysAfterLatestRelease = 5

        if (
          (latestVersionReleasedForDays >= nativeMinDaysAfterLatestRelease &&
            versionDifferenceInDays > nativeMaxDaysOutOfDate) ||
          state.latestVersion[platform].forceUpdate === true
        ) {
          log.debug('Native version is out of date')

          commit('setState', { key: 'isOutOfDate', value: true })

          Vue.prototype.$modal.open('ModalNativeAppOutOfDate')

          Vue.prototype.$analytics.addEvent(
            {
              category: 'app',
              action: 'App out of date',
              label: platform
            },
            true
          )
        }
      } else {
        // For web we don't wait any days to update, but we will wait for at least a few route changes for our service worker to try update itself
        // before forcing a cache clear and reloading the page
        log.debug('Web version is out of date')

        commit('setState', { key: 'isOutOfDate', value: true })
      }
    }
  },

  routeChange({ state, commit }) {
    commit('addRouteHistory')

    // If we've given the service worker enough time to update itself and we
    // are still out of date, let's force clear cache and reload
    if (
      !isNativeApp &&
      state.routeHistory.length > 4 &&
      state.isOutOfDate &&
      process.env.VUE_APP_ENVIRONMENT !== 'local'
    ) {
      // But wait for at least 30 seconds in case we are on a slow connection
      // as the service worker might still be updating
      const secondsSinceSessionStart = dayjs().diff(state.sessionStartedTimestamp, 'second')

      if (secondsSinceSessionStart > 30) {
        log.debug('App failed to self update, forcing cache clear and reload')

        clearBrowserCache()
      } else {
        log.debug('Waiting to force reload', secondsSinceSessionStart)
      }
    }
  },

  startLastVisitedTimer({ state, dispatch, getters }) {
    if (state.lastVisitedTimer) {
      return false
    }

    if (getters.appIs.auth) {
      dispatch(
        'storage/save',
        {
          key: 'lastVisitedTimestamp',
          value: new Date().getTime()
        },
        { root: true }
      )
    }

    // Every 30 mins for those who leave their tabs open
    state.lastVisitedTimer = setInterval(() => {
      if (getters.appIs.auth) {
        dispatch(
          'storage/save',
          {
            key: 'lastVisitedTimestamp',
            value: new Date().getTime()
          },
          { root: true }
        )
      }
    }, 1800000)
  },

  async resetAllModules({ commit }) {
    commit('auth/reset', null, { root: true })
    commit('securePayments/reset', null, { root: true })
    commit('payments/reset', null, { root: true })
    commit('storage/reset', null, { root: true })
    commit('cart/reset', null, { root: true })
    commit('messages/reset', null, { root: true })
  }
}

const mutations = {
  setState(state, { key, value }) {
    state[key] = value
  },

  reset(state) {
    log.debug('Resetting', STORAGE_KEY)
    window.localStorage.removeItem(STORAGE_KEY)
    Object.assign(state, initialState())
  },

  setRouteLoading(state, loading) {
    state.routeLoading = loading
  },

  addRouteHistory(state) {
    state.routeHistory.push(router.currentRoute.fullPath)
  },

  setMaintenanceMode(state, { isMaintenanceModeEnabled, isMaintenanceModeBypassed }) {
    state.isMaintenanceModeEnabled = isMaintenanceModeEnabled
    state.isMaintenanceModeBypassed = isMaintenanceModeBypassed
  },

  setLatestVersion(state, latestVersion) {
    state.latestVersion = latestVersion
  },

  setGlobalMessage(state, globalMessage) {
    state.globalMessage = globalMessage
  },

  setLastVisitedTimestamp(state, timestamp) {
    state.lastVisitedTimestamp = timestamp
  },
  toggleMenuOpen(state, payload) {
    state.mobileMenuOpen = payload
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
}
