<template>
  <div v-if="hasProductsInCart">
    <div :class="{ 'cart-bar': $breakpoint.smAndDown }">
      <a
        @click="$modal.open('ModalCart')"
        class="flex justify-between w-full items-center cursor-pointer font-medium"
      >
        <template v-if="$breakpoint.smAndDown">
          <span>
            <FontAwesomeIcon :icon="faShoppingCart" class="mr-2" />

            <span>{{ $tc('products.productsCount', totalProductsInCart) }} -</span>

            <Currency :value="subTotalPrice" :format="{ key: 'currency', currency: 'GBP' }" />
          </span>

          <UtilButton color="primary" small>View Basket</UtilButton>
        </template>

        <template v-else>
          <a class="cart-preview-wrapper text-white hover:text-primary-button py-2 flex items-center mr-6 ml-6">
            <UtilBadgeCount :count="totalProductsInCart" unread class="mr-2 text-primary hover:text-primary" />

            <FontAwesomeIcon :icon="faShoppingCart" class="text-2xl" />

            <transition name="fade">
              <span v-if="showPreviewDropDown" @click="showPreviewDropDown = false" class="cart-preview">
                <span class="text-xl px-6">
                  <FontAwesomeIcon :icon="faShoppingCart" class="mr-2" />
                  Your Basket
                </span>

                <span class="text-lg px-6 my-4 flex items-center justify-between">
                  <span>{{ $tc('products.productsCount', totalProductsInCart) }}</span>

                  <Currency
                    :value="subTotalPrice"
                    :format="{ key: 'currency', currency: 'GBP' }"
                    class="font-mono"
                  />
                </span>

                <UtilDivider class="mb-4" />

                <span class="px-6 block">
                  <UtilButton color="primary" block>View Basket</UtilButton>
                </span>
              </span>
            </transition>
          </a>
        </template>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons'
import UtilBadgeCount from '@/components/utils/UtilBadgeCount'
import UtilButton from '@/components/utils/UtilButton'
import UtilDivider from '@/components/utils/UtilDivider'

export default {
  name: 'LayoutCartBanner',
  components: {
    UtilBadgeCount,
    UtilButton,
    UtilDivider
  },
  data() {
    return {
      previewTimer: null,
      showPreviewDropDown: false,
      faShoppingCart
    }
  },
  watch: {
    totalProductsInCart(newValue) {
      if (newValue === 0) {
        this.showPreviewDropDown = false
      } else if (this.$store.state.modal.lastOpened !== 'ModalCart') {
        this.showPreview()
      }
    }
  },
  computed: {
    ...mapGetters({
      hasProductsInCart: 'cart/hasProductsInCart',
      totalProductsInCart: 'cart/totalProductsInCart',
      subTotalPrice: 'cart/subTotalPrice'
    })
  },
  methods: {
    showPreview() {
      if (this.previewTimer) {
        clearTimeout(this.previewTimer)
      }

      this.showPreviewDropDown = true

      this.previewTimer = setTimeout(() => (this.showPreviewDropDown = false), 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-bar {
  @apply bg-gray-lighter px-3 py-2 flex justify-center items-center shadow;
}

.cart-preview-wrapper {
  position: relative;
  z-index: 5;
}

.cart-preview {
  @apply py-4 shadow-2xl text-primary text-left rounded;
  min-width: 250px;
  position: absolute;
  top: 48px;
  right: -20px;
  background: #fff;
  z-index: 7;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    right: 21px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
  }
}
</style>