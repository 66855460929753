<template>
  <div
    v-if="isReady && !hasAlreadyCompletedForm && !compIsManagingCookies && app.platform === 'web'"
    class="fixed z-50 bottom-0 left-0 right-0 bg-white border-t border-gray-lighter px-5 pt-4 md:pt-6 pb-4 md:pb-8"
  >
    <div class="max-w-6xl mx-auto">
      <h3 class="font-medium text-lg flex items-center">
        <FontAwesomeIcon :icon="faLock" class="mr-2" />
        <span>Your Cookie Preferences</span>
      </h3>

      <div class="md:flex md:items-start mt-2">
        <p class="mr-6 text-sm">
          We use cookies and similar tools to enhance your trading experience, to provide our services, understand
          how customers use our services so we can make improvements, and display ads. More information can be
          found in our full
          <UtilTextLink to="/terms/cookies">cookie policy.</UtilTextLink>
        </p>

        <div class="flex items-center flex-wrap md:flex-no-wrap mt-4 md:mt-0">
          <UtilButton
            @click="$store.dispatch('cookies/acceptAll')"
            name="cookie-accept-all"
            color="primary"
            :block="$breakpoint.smAndDown"
            class="mb-2 md:mb-0"
          >
            Accept Cookies
          </UtilButton>

          <UtilButton
            @click="manageCookies()"
            :block="$breakpoint.smAndDown"
            :textLink="$breakpoint.mdAndUp"
            :color="$breakpoint.smAndDown ? 'outline' : undefined"
            class="md:ml-6 text-primary hover:underline"
          >
            <span>Manage Cookies</span>
          </UtilButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {faLock} from '@fortawesome/pro-solid-svg-icons'
  import {mapGetters} from 'vuex'
  import UtilTextLink from '@/components/utils/UtilTextLink'
  import UtilButton from '@/components/utils/UtilButton'

  export default {
  name: 'WidgetCookieConsent',
  components: {
    UtilTextLink,
    UtilButton
  },
  data() {
    return {
      isReady: false,
      faLock
    }
  },
  watch: {
    '$store.state.storage.initialLoadFinished'(initialLoadFinished) {
      if (initialLoadFinished) {
        this.isReady = true
        this.$store.dispatch('cookies/loadExistingConsent')
      }
    }
  },
  computed: {
    ...mapGetters({
      hasAlreadyCompletedForm: 'cookies/hasAlreadyCompletedForm'
    }),

    compIsManagingCookies() {
      return this.$route.name === 'manage-cookies'
    }
  },
  methods: {
    manageCookies() {
      this.$analytics.addEvent({
        category: 'cookies',
        action: 'Clicked manage cookies from footer banner'
      })

      this.$router.push('/manage-cookies')
    }
  }
}
</script>
