import router from '@/router'
import { xor, camelCase } from 'lodash'
import dayjs from 'dayjs'
import commodities, { topLevelCommodities } from '@/lib/helpers/commodities'
import { anyDistanceValue } from '@/lib/options/distance'
import algoliasearch from 'algoliasearch/lite'
import algoliasearchHelper from 'algoliasearch-helper'
import log from '@/plugins/log'
import Vue from 'vue'

const STORAGE_KEY_LAYOUT = 'sml-search-layout'
const STORAGE_KEY_GEO = 'sml-search-geo'
const STORAGE_KEY_HITS_PER_PAGE = 'sml-search-hits-per-page'

const existingLayoutPreference = window.localStorage[STORAGE_KEY_LAYOUT] || 'card'

let existingHitsPerPagePreference = window.localStorage[STORAGE_KEY_HITS_PER_PAGE]
  ? parseInt(window.localStorage[STORAGE_KEY_HITS_PER_PAGE])
  : 20

// We removed 80 and 100 so need to default back down
if (existingHitsPerPagePreference > 40) {
  existingHitsPerPagePreference = 40
}

const existingGeoPreference = (window.localStorage[STORAGE_KEY_GEO] &&
  JSON.parse(window.localStorage[STORAGE_KEY_GEO])) || {
  aroundLatLng: '',
  aroundRadius: anyDistanceValue,
  aroundPostcode: ''
}

export const defaultSearchIndex = `${process.env.VUE_APP_ALGOLIA_INDEX_PREFIX}listings_ranking`

// Toggles or dropdowns
export const facets = [
  'commodity',
  'tradeType',
  'status',
  'meta.saleDate',
  'meta.pedigreeStatus',
  'meta.premiumHealthScheme',
  'meta.tbRestricted',
  'meta.cross',
  'meta.pregnant',
  'meta.children',
  'meta.homebred',
  'meta.farmAssured',
  'meta.organicAssured',
  'meta.buyNow',
  'meta.tbInterval',
  'meta.health.Maedi Visna.accredited',
  'meta.health.Scrapie.accredited'
]

// Multiple choice
export const disjunctiveFacets = ['categories.lvl0', 'categories.lvl1', 'meta.sexes', 'meta.types']

let searchClient = null

try {
  searchClient = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_KEY)
} catch (error) {
  log.error('Error configuring algoliasearch', error)

  Vue.prototype.$notify.floatError('There was an error searching. Please reload and try again')
}

let searchHelper = algoliasearchHelper(searchClient, defaultSearchIndex, {
  facets,
  disjunctiveFacets
})

// Used for URL friendly facet name mapping
export const facetMapping = {
  commodity: 'commodity',
  species: 'categories.lvl0',
  breeds: 'categories.lvl1',
  sexes: 'meta.sexes',
  types: 'meta.types',
  tradeType: 'tradeType',
  status: 'status',
  saleDate: 'meta.saleDate',
  pedigreeStatus: 'meta.pedigreeStatus',
  premiumHealthScheme: 'meta.premiumHealthScheme',
  tbRestricted: 'meta.tbRestricted',
  cross: 'meta.cross',
  pregnant: 'meta.pregnant',
  children: 'meta.children',
  homebred: 'meta.homebred',
  farmedAssured: 'meta.farmAssured',
  organicAssured: 'meta.organicAssured',
  buyNow: 'meta.buyNow',
  tbInterval: 'meta.tbInterval',
  mvAccredited: 'meta.health.Maedi Visna.accredited',
  scrapie: 'meta.health.Scrapie.accredited'
}

const initialState = () => {
  return {
    isMobileSearchMenuOpen: false,
    isNavSearchOpen: false,
    helper: searchHelper,
    result: null,
    index: defaultSearchIndex,
    loading: true,
    page: 1, // we only convert to zero based when we perform the search
    hitsPerPage: existingHitsPerPagePreference,
    query: '',
    filters: '',
    childCommodity: '',
    species: '',
    commodity: '',
    popularTag: '',
    geoCountry: '',
    geoRegion: '',
    url: '',
    facetRefinementList: {},
    aroundLatLng: existingGeoPreference.aroundLatLng,
    aroundRadius: existingGeoPreference.aroundRadius,
    aroundPostcode: existingGeoPreference.aroundPostcode,
    layout: existingLayoutPreference
  }
}

const getters = {
  listings(state) {
    if (!state.result) {
      return []
    }

    return state.result.hits
  },

  activeFilters(state) {
    let activeFilterCollection = {}

    if (state.page !== 1) {
      activeFilterCollection.page = state.page
    }

    if (state.query) {
      activeFilterCollection.query = state.query
    }

    if (state.geoCountry) {
      activeFilterCollection.geoCountry = state.geoCountry
    }

    if (state.popularTag) {
      activeFilterCollection.popularTag = state.popularTag
    }

    if (parseInt(state.aroundRadius) !== anyDistanceValue) {
      activeFilterCollection.aroundRadius = state.aroundRadius
    }

    if (state.index !== defaultSearchIndex) {
      activeFilterCollection.index = state.index
    }

    let filteredFacets = []

    // Organise the active ones here as we use them in the UI for active tags
    Object.keys(state.facetRefinementList).forEach(key => {
      if (typeof state.facetRefinementList[key] === 'undefined') {
        return
      }

      if (disjunctiveFacets.includes(key)) {
        if (state.facetRefinementList[key].length > 0) {
          state.facetRefinementList[key].forEach(facetValue => {
            filteredFacets.push({ key, value: facetValue })
          })
        }
      } else {
        filteredFacets.push({ key, value: state.facetRefinementList[key] })
      }
    })

    if (filteredFacets.length) {
      activeFilterCollection.facets = filteredFacets
    }

    return activeFilterCollection
  },

  generateUrl: state => ({ page, returnString = true } = {}) => {
    let params = {}

    params.page = Number.isInteger(page) ? page : state.page

    if (params.page === 1) {
      delete params.page
    }

    params.query = state.query || undefined

    params.orderBy = state.index === defaultSearchIndex ? undefined : state.index

    // Add our custom facets
    Object.keys(facetMapping).forEach(facetCustomKey => {
      if (disjunctiveFacets.includes(facetMapping[facetCustomKey])) {
        params[facetCustomKey] =
          (state.facetRefinementList[facetMapping[facetCustomKey]] &&
            state.facetRefinementList[facetMapping[facetCustomKey]].join('~')) ||
          undefined
      } else {
        params[facetCustomKey] = state.facetRefinementList[facetMapping[facetCustomKey]]
      }
    })

    if (returnString) {
      let paramStringArray = []

      Object.keys(params).forEach(key => {
        if (typeof params[key] !== 'undefined') {
          paramStringArray.push(`${key}=${params[key]}`)
        }
      })

      return paramStringArray.join('&')
    } else {
      return params
    }
  },

  searchIs(state) {
    let is = {}

    if (state.popularTag) {
      is[state.commodity] = true
    } else {
      let commodity = commodities.find(c => c.slug === state.childCommodity)

      if (commodity && commodity.listing) {
        is[camelCase(commodity.listing.commodity)] = true

        if (commodity.listing.categories) {
          commodity.listing.categories.forEach(category => {
            is[category] = true
          })
        }

        if (commodity.listing.meta && commodity.listing.meta.types) {
          commodity.listing.meta.types.forEach(type => {
            is[camelCase(type)] = true
          })
        }
      }

      if (!state.childCommodity) {
        topLevelCommodities.forEach(commodity => {
          is[commodity.slug] = true
        })
      } else {
        is[state.childCommodity] = true
      }
    }

    return is
  },

  searchIndex(state) {
    return `${process.env.VUE_APP_ALGOLIA_INDEX_PREFIX}${state.index}`
  }
}

const actions = {
  fetchListings({ state, commit }) {
    commit('mutate', { key: 'loading', value: true })

    state.helper.clearRefinements()
    state.helper.clearTags()

    // We don't want graindex listings
    searchHelper.addFacetExclusion('commodity', 'grain')
    searchHelper.addFacetExclusion('commodity', 'potatoes')

    // Filter out old listings on all queries
    state.helper.setQueryParameter(
      'filters',
      `start >= ${dayjs().subtract(3, 'month').unix()} ${state.filters ? `AND ${state.filters}` : ''}`
    )

    if (state.popularTag) {
      state.helper.addTag(state.popularTag)
    }

    // Convert miles to meters
    state.helper.setQueryParameter('aroundRadius', state.aroundRadius * 1609)

    state.helper.setQueryParameter('aroundLatLng', state.aroundLatLng)
    state.helper.setQueryParameter('hitsPerPage', getAdjustedHitsPerPage(parseInt(state.hitsPerPage)))
    state.helper.setQueryParameter('getRankingInfo', true)
    state.helper.setQuery(state.query)
    state.helper.setIndex(state.index)

    // Apply our custom facets
    Object.keys(state.facetRefinementList).forEach(facetKey => {
      if (disjunctiveFacets.includes(facetKey)) {
        state.facetRefinementList[facetKey].forEach(facetValue => {
          state.helper.addDisjunctiveFacetRefinement(facetKey, facetValue)
        })
      } else if (typeof state.facetRefinementList[facetKey] !== 'undefined') {
        state.helper.addFacetRefinement(facetKey, state.facetRefinementList[facetKey])
      }
    })

    // Page needs to be zero based for algolia
    // THIS CALL MUST ALWAYS BE LAST BEFORE SEARCH, as other functions tend to reset page to zero
    state.helper.setPage(state.page - 1)

    try {
      state.helper.search()
    } catch (error) {
      log.error('Error searching', error)
    }
  },

  applyFilters({ dispatch, getters }) {
    let generatedUrlObject = getters.generateUrl({ returnString: false })

    dispatch('saveCurrentUrl')

    router.push({ query: generatedUrlObject }).catch(() => {})
  },

  saveCurrentUrl({ commit }) {
    commit('mutate', { key: 'url', value: router.currentRoute.fullPath })
  },

  toggleFacet({ state, commit, dispatch }, { key, value }) {
    let facetRefinementList = { ...state.facetRefinementList }

    if (disjunctiveFacets.includes(key)) {
      facetRefinementList[key] = xor(facetRefinementList[key], [value])
    } else {
      // Remove facet if already enabled
      if (facetRefinementList[key] === value) {
        facetRefinementList[key] = undefined
      } else {
        facetRefinementList[key] = value
      }
    }

    commit('mutate', { key: 'facetRefinementList', value: facetRefinementList })

    // Reset the page since we are searching for new facets
    commit('mutate', { key: 'page', value: 1 })

    dispatch('applyFilters')
  },

  changePage({ commit, dispatch }, pageNumber) {
    commit('mutate', { key: 'page', value: pageNumber })

    dispatch('applyFilters')
  },

  changeHitsPerPage({ commit, dispatch }, hitsPerPage) {
    commit('setHitsPerPage', hitsPerPage)

    dispatch('fetchListings')
  },

  query({ commit, dispatch }, query) {
    commit('mutateBatch', [
      { key: 'query', value: query },
      { key: 'page', value: 1 }
    ])

    dispatch('applyFilters')
  },

  clearGeo({ commit }) {
    commit('setGeo', {
      aroundLatLng: '',
      aroundPostcode: '',
      aroundRadius: anyDistanceValue
    })
  },

  mutateAndApply({ commit, dispatch }, { key, value }) {
    commit('mutate', { key, value })
    dispatch('applyFilters')
  },

  resetGeoDistance({ commit, dispatch }) {
    commit('setGeo', {
      aroundRadius: anyDistanceValue
    })

    // We must dispatch fetchListings rather than applyFilters because geo isn't part of the URL
    dispatch('fetchListings')
  },

  reset({ dispatch, commit }) {
    commit('reset')

    dispatch('applyFilters')
  }
}

const mutations = {
  mutate(state, { key, value }) {
    state[key] = value
  },

  mutateBatch(state, changes = []) {
    changes.forEach(change => {
      state[change.key] = change.value
    })
  },

  setLayout(state, layout) {
    state.layout = layout

    window.localStorage.setItem(STORAGE_KEY_LAYOUT, state.layout)
  },

  setHitsPerPage(state, hitsPerPage) {
    state.hitsPerPage = parseInt(hitsPerPage)

    window.localStorage.setItem(STORAGE_KEY_HITS_PER_PAGE, hitsPerPage)
  },

  setGeo(state, { aroundLatLng, aroundRadius, aroundPostcode }) {
    if (typeof aroundLatLng !== 'undefined') {
      state.aroundLatLng = aroundLatLng
    }

    if (typeof aroundRadius !== 'undefined') {
      state.aroundRadius = aroundRadius
    }

    if (typeof aroundPostcode !== 'undefined') {
      state.aroundPostcode = aroundPostcode
    }

    // Save our geo preferences to storage
    window.localStorage.setItem(
      STORAGE_KEY_GEO,
      JSON.stringify({
        aroundLatLng: state.aroundLatLng,
        aroundRadius: state.aroundRadius,
        aroundPostcode: state.aroundPostcode
      })
    )
  },

  reset(state) {
    state.page = 1
    state.query = ''
    state.geoCountry = ''
    state.popularTag = ''
    state.aroundRadius = anyDistanceValue
    state.facetRefinementList = {}
  }
}

// Need to account for adverts
const getAdjustedHitsPerPage = hitsPerPage => {
  let adjustedHitsPerPage = hitsPerPage

  switch (hitsPerPage) {
    case 12:
      adjustedHitsPerPage = hitsPerPage - 1
      break
    case 20:
      adjustedHitsPerPage = hitsPerPage + 2
      break
    case 40:
      adjustedHitsPerPage = hitsPerPage + 4
      break
    case 80:
      adjustedHitsPerPage = hitsPerPage + 8
      break
    case 100:
      adjustedHitsPerPage = hitsPerPage + 10
      break
  }

  return adjustedHitsPerPage
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters
}
