<template>
  <ValidationProvider
    tag="div"
    :name="name"
    :vid="name"
    :rules="rules"
    v-slot="{ errors, validated, touched, valid }"
    class="form-group"
  >
    <FormInputFeedBackWrapper
      :hasError="errors[0] !== undefined"
      :hasSuccess="touched && validated && valid && compHasRules"
    >
      <slot :errors="errors"></slot>
    </FormInputFeedBackWrapper>
  </ValidationProvider>
</template>

<script>
import FormInputFeedBackWrapper from '@/components/forms/elements/FormInputFeedBackWrapper'

export default {
  name: 'FormInputValidation',
  components: {
    FormInputFeedBackWrapper
  },
  props: {
    name: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object]
    }
  },
  computed: {
    compHasRules() {
      return this.rules && this.rules !== ''
    }
  }
}
</script>
