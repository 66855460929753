import Vue from 'vue'
import { platform } from '@/plugins/native-app/capacitor'
import facebookPixel from '@/plugins/third-party/facebook-pixel'

export default {
  allowAnalytics: true,
  allowAdvertising: true,

  init(allowAnalytics, allowAdvertising) {
    const isBotVisit = document.cookie.indexOf('sml-bot=true') !== -1

    if (isBotVisit) {
      return false
    }

    this.allowAnalytics = allowAnalytics
    this.allowAdvertising = allowAdvertising

    // Only load scripts when the page has fully loaded
    if (document.readyState === 'complete') {
      this.load()
    } else {
      window.addEventListener('load', () => {
        this.load()
      })
    }
  },

  load() {
    if (this.allowAnalytics) {
      this.loadGoogleAnalytics()
    }

    if (this.allowAdvertising) {
      this.loadFacebookPixel()
    }
  },

  loadGoogleAnalytics() {
    Vue.prototype.$analytics.updateConsent(this.allowAnalytics, this.allowAdvertising)
  },

  loadFacebookPixel() {
    if (platform === 'web' && process.env.VUE_APP_ENVIRONMENT === 'production') {
      facebookPixel.init('1991380994466563')
    }
  }
}
