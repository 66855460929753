import Vue from 'vue'
import scrollIntoView from 'scroll-into-view-if-needed'

const $body = document.querySelector('body')
let scrollPosition = 0

const scroll = {
  install(vm) {
    vm.prototype.$scroll = {
      toTop: () => {
        const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style

        if (isSmoothScrollSupported) {
          // Native smooth scrolling
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        } else {
          // Old way scrolling without effects and legacy 2 params required
          window.scrollTo(0, 0)
        }
      },

      intoViewIfNeeded: (node, always = false) => {
        if (node) {
          scrollIntoView(node, {
            scrollMode: always ? 'always' : 'if-needed',
            block: 'start',
            inline: 'center',
            behavior: 'smooth'
          })
        }
      },

      lock: () => {
        scrollPosition = window.pageYOffset
        $body.style.overflow = 'hidden'
        $body.style.position = 'fixed'
        $body.style.top = `-${scrollPosition}px`
        $body.style.width = '100%'
        $body.style.paddingRight = '15px'
      },

      unlock: () => {
        $body.style.removeProperty('overflow')
        $body.style.removeProperty('position')
        $body.style.removeProperty('top')
        $body.style.removeProperty('width')
        $body.style.removeProperty('padding-right')

        window.scrollTo(0, scrollPosition)
      }
    }
  }
}

Vue.use(scroll)
