import Vue from 'vue'
import { get } from 'lodash'
import Api from '@/lib/api'
import HaulageModel from '@/lib/models/haulage'
import dayjs from 'dayjs'

const initialState = () => {
  return {
    haulageApi: new Api(new HaulageModel()),
    isUsingListingData: false, // Have we come from a listing
    hasSetupComplete: false, // We only want to setup options / prefill once
    formStage: 'arrange',
    collectionListingRef: null,
    estimatePrice: null,
    collectionLocations: [],
    deliveryLocations: [],
    selectedCollectionLocationKey: '',
    selectedDeliveryLocationKey: '',
    selectedDate: null,
    selectedDateFlexibility: 0
  }
}

const getters = {}

const actions = {}

const mutations = {
  setQuantity(state, value) {
    state.haulageApi.model.quantity = value
  },

  setProduct(state, value) {
    state.haulageApi.model.weight = ''
    state.haulageApi.model.product = value
  },

  setWeight(state, value) {
    state.haulageApi.model.weight = value
  },

  setNotes(state, value) {
    // Which notes are we setting? Delivery, collection or model.notes?
    state.haulageApi.model.notes = value
  },

  setDates(state, payload) {
    // Keep a reference of the actual date selected to show on the quote step
    state.selectedDate = dayjs(payload.selectedDate).format('DD/MM/YYYY')
    state.selectedDateFlexibility = payload.selectedDateFlexibility

    Vue.set(state.haulageApi.model.points[0], 'date', payload.dates)
    Vue.set(state.haulageApi.model.points[1], 'date', payload.dates)
  },

  setDistance(state, distance) {
    Vue.set(state.haulageApi.model.points[1], 'distance', distance)
  },

  setEstimateId(state, id) {
    Vue.set(state.haulageApi.model, 'estimate', id)
  },

  // Need to save the location options to state in case we go back to edit details
  addCollectionLocationOption(state, location) {
    state.collectionLocations.push(location)
  },

  addDeliveryLocationOption(state, location) {
    state.deliveryLocations.push(location)
  },

  setSelectedCollectionLocationKey(state, key) {
    state.selectedCollectionLocationKey = key
  },

  setSelectedDeliveryLocationKey(state, key) {
    state.selectedDeliveryLocationKey = key
  },

  setLocations(state, locations) {
    const collection = locations.collection || {}
    const delivery = locations.delivery || {}

    Vue.set(state.haulageApi.model.points[0], 'geo', {
      address: [
        collection.buildingName,
        collection.buildingNo,
        collection.line1,
        collection.locality,
        collection.region,
        collection.code
      ]
        .filter(item => item)
        .join(', '),
      building: get(collection, 'buildingName', null),
      line1: get(collection, 'line1', null),
      line2: get(collection, 'line2', null),
      locality: get(collection, 'locality', null),
      region: get(collection, 'region', null),
      code: get(collection, 'code', null),
      country: get(collection, 'countryCode', null),
      coordinates: [get(collection, 'longitude', null), get(collection, 'latitude', null)]
    })

    Vue.set(state.haulageApi.model.points[1], 'geo', {
      address: [
        delivery.buildingName || null,
        delivery.buildingNo || null,
        delivery.line1,
        delivery.locality,
        delivery.region,
        delivery.code
      ]
        .filter(item => item)
        .join(', '),
      building: get(delivery, 'buildingName', null),
      line1: get(delivery, 'line1', null),
      line2: get(delivery, 'line2', null),
      locality: get(delivery, 'locality', null),
      region: get(delivery, 'region', null),
      code: get(delivery, 'code', null),
      country: get(delivery, 'countryCode', null),
      coordinates: [get(delivery, 'longitude', null), get(delivery, 'latitude', null)]
    })
  },

  setDeliveryLocation(state, location) {
    Vue.set(state.haulageApi.model.points[1], 'geo', {
      address: [
        get(location, 'buildingName', null),
        get(location, 'buildingNo', null),
        get(location, 'line1', null),
        get(location, 'locality', null),
        get(location, 'region', null),
        get(location, 'code', null)
      ]
        .filter(item => item)
        .join(', '),
      building: get(location, 'buildingName', null),
      line1: get(location, 'line1', null),
      line2: get(location, 'line2', null),
      locality: get(location, 'locality', null),
      region: get(location, 'region', null),
      code: get(location, 'code', null),
      country: get(location, 'countryCode', null),
      coordinates: [get(location, 'longitude', null), get(location, 'latitude', null)]
    })
  },

  setCollectionLocation(state, location) {
    Vue.set(state.haulageApi.model.points[0], 'geo', {
      address: [
        get(location, 'buildingName', null),
        get(location, 'buildingNo', null),
        get(location, 'line1', null),
        get(location, 'locality', null),
        get(location, 'region', null),
        get(location, 'code', null)
      ]
        .filter(item => item)
        .join(', '),
      building: get(location, 'buildingName', null),
      line1: get(location, 'line1', null),
      line2: get(location, 'line2', null),
      locality: get(location, 'locality', null),
      region: get(location, 'region', null),
      code: get(location, 'code', null),
      country: get(location, 'countryCode', null),
      coordinates: [get(location, 'longitude', null), get(location, 'latitude', null)]
    })
  },

  setWeighbridge(state, value) {
    state.haulageApi.model.points[1].flags.weighbridge = value
  },

  setBedding(state, value) {
    state.haulageApi.model.points[1].flags.bedding = value
  },

  setCarbonOffset(state, value) {
    state.haulageApi.model.flags.carbonOffset = value
  },

  setListingRef(state, listingRef) {
    state.collectionListingRef = listingRef
    state.isUsingListingData = true
  },

  setSetupComplete(state) {
    state.hasSetupComplete = true
  },

  setCollectionContact(state, value) {
    Vue.set(state.haulageApi.model.points[0], 'contact', value)
  },

  setDeliveryContact(state, value) {
    Vue.set(state.haulageApi.model.points[1], 'contact', value)
  },

  prefillListingQuantity(state, quantity) {
    state.haulageApi.model.quantity = quantity
  },

  setEstimatePrice(state, price) {
    state.estimatePrice = price
  },

  restartQuote(state) {
    state.haulageApi = new Api(new HaulageModel())
    state.isUsingListingData = false
    state.hasSetupComplete = false
    state.collectionListingRef = ''
    state.estimatePrice = null
    state.collectionLocations = []
    state.deliveryLocations = []
    state.selectedCollectionLocationKey = ''
    state.selectedDeliveryLocationKey = ''
    state.formStage = 'arrange'
  },

  gotoFormStage(state, stage) {
    state.formStage = stage
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters
}
