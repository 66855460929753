export default {
  YEAR: 'Y',
  MONTH: 'M'
}

export const planIntervalCodeToHumanPer = {
  Y: 'year',
  M: 'month'
}

export const planIntervalCodeToHuman = {
  Y: 'Yearly',
  M: 'Monthly'
}
