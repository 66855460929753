<template>
  <span>{{ $numberFormat(value, { style: 'currency', currency: 'GBP' }) }}</span>
</template>

<script>
export default {
  name: 'Currency',
  props: {
    value: [Number, String],
    format: Object
  }
}
</script>
