import { mapKeys, set, union } from 'lodash'
import LegacyApi from '@/lib/api/legacy'
import log from '@/plugins/log'

const STORAGE_KEY = 'sml-generic-storage'
const existingData = window.localStorage[STORAGE_KEY] ? JSON.parse(window.localStorage[STORAGE_KEY]) : {}

const initialState = () => {
  return {
    initialLoadFinished: false,
    api: new LegacyApi(),
    data: existingData
  }
}

const getters = {
  savedSearches: state => {
    return state.data.savedSearches || []
  },

  dismissedMessages: state => {
    return state.data.dismissedMessages || []
  },

  holdingNumber: state => {
    return state.data.holdingNumber || ''
  },

  hasKey: state => (key, valueIsTrue = false) => {
    // We don't care if the value is true or not
    if (!valueIsTrue) {
      return key in state.data
    }

    return key in state.data && state.data[key] === true
  }
}

const actions = {
  async get({ state, rootState, commit, dispatch, rootGetters }) {
    let appIs = rootGetters['app/appIs']

    if (!appIs.auth) {
      commit('setInitialLoadFinished', true)
      return false
    }

    await state.api.get('app-storage.get').catch(() => {})

    let keyedData = mapKeys(state.api.data, value => value.key)

    Object.keys(keyedData).forEach(key => {
      keyedData[key] = keyedData[key].data
    })

    // Update all our data at once, replacing the API response layout with our keyed data
    commit('setState', {
      key: 'data',
      value: keyedData
    })

    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(keyedData))

    // Set our last visited timestamp from previous session
    if (rootState.app.lastVisitedTimestamp === 0) {
      commit('app/setLastVisitedTimestamp', state.data.lastVisitedTimestamp || 0, { root: true })
      dispatch('app/startLastVisitedTimer', null, { root: true })
    }

    setTimeout(() => {
      commit('setInitialLoadFinished', true)
    }, 500)
  },

  async save({ state, dispatch, commit, rootGetters }, { key, value, expiry }) {
    let appIs = rootGetters['app/appIs']

    // If using the master password then don't save user preferences
    if (appIs.loggedInAsAnotherUser) {
      console.log('Logged in as another user, skipping storage save')
      return
    }

    // Commit locally immediately so the user has instant feedback
    commit('setState', {
      key: `data.${key}`,
      value
    })

    if (!appIs.auth) {
      return false
    }

    await state.api.post('app-storage.save', {
      key,
      data: value,
      expiry
    })

    if (appIs.local) {
      log.debug('Storage saved', { key, value, expiry })
    }

    await dispatch('get')
  },

  dismissMessage({ getters, dispatch }, messageId) {
    // Use union to remove duplicates
    dispatch('save', {
      key: 'dismissedMessages',
      value: union(getters.dismissedMessages, [messageId])
    })
  }
}

const mutations = {
  setState(state, { key, value }) {
    // We use lodash's set() to allow us to pass the key as a dot notation string
    set(state, key, value)
  },

  setInitialLoadFinished(state, finished) {
    state.initialLoadFinished = finished
  },

  reset(state) {
    state.initialLoadFinished = false
    state.api = new LegacyApi()

    state.data = {}
    window.localStorage.removeItem(STORAGE_KEY)
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations
}
