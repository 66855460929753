import LegacyApi from '@/lib/api/legacy'
import { isEmpty } from 'lodash'

const STORAGE_KEY_COUNTRIES = 'sml-geo-countries'
const STORAGE_KEY_REGIONS = 'sml-geo-regions'

const existingCountryData = window.localStorage[STORAGE_KEY_COUNTRIES] || null
const existingRegionData = window.localStorage[STORAGE_KEY_REGIONS] || null

const initialState = () => {
  return {
    countries: new LegacyApi().hydrate(JSON.parse(existingCountryData)),
    regions: new LegacyApi().hydrate(JSON.parse(existingRegionData)),
    locations: new LegacyApi()
  }
}

const getters = {
  getCountrySelectList: state => {
    let countryList = []

    state.countries.data.forEach(country => {
      countryList.push({
        text: country.name,
        value: country.countryId,
        meta: country.countryCode
      })
    })

    return countryList
  },

  getUserCountry: (state, getters, rootState) => {
    return getters.getCountryByCode(rootState.locale.countryCode)
  },

  getCountryByCode: state => countryCode => {
    return state.countries.data.find(country => country.countryCode === countryCode)
  },

  getCountryById: state => countryId => {
    return state.countries.data.find(country => country.countryId === countryId)
  },

  getRegionByName: state => regionName => {
    return state.regions.data.find(regionData => regionData.region === regionName)
  },

  getRegionSelectList: state => {
    let regionList = []

    state.regions.data.forEach(region => {
      regionList.push({
        text: region.region,
        value: region.region
      })
    })

    return regionList
  },

  getLocationByRef: state => locationRef => {
    return state.locations.data.find(location => location.locationRef === locationRef)
  },

  getLocationSelectList: state => {
    let locationList = []

    state.locations.data.forEach(location => {
      locationList.push({
        text: location.name,
        value: location.locationRef
      })
    })

    return locationList
  },

  getLocationFullAddressSelectList: state => {
    let locationList = []

    state.locations.data.forEach(location => {
      let fullAddress = `${location.buildingNo} ${location.buildingName} ${location.line1} ${location.code}`

      locationList.push({
        text: fullAddress,
        value: location.locationRef
      })
    })

    return locationList
  }
}

const actions = {
  async getCountries({ state }) {
    await state.countries.get('countries.get').catch(() => {})

    if (!isEmpty(state.countries.data)) {
      window.localStorage.setItem(STORAGE_KEY_COUNTRIES, JSON.stringify(state.countries.data))
    }
  },

  async getRegionsByCountryId({ state }, countryId) {
    await state.regions
      .get('regions.get', {
        countryId
      })
      .catch(() => {})

    if (!isEmpty(state.regions.data)) {
      window.localStorage.setItem(STORAGE_KEY_REGIONS, JSON.stringify(state.regions.data))
    }
  },

  async getLocations({ state }) {
    await state.locations.get('locations.get').catch(() => {})
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters
}
