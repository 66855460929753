import Vue from 'vue'
import VueRouter from 'vue-router'
import qs from 'qs'
import store from '@/store'
import i18n from '@/plugins/i18n'
import log from '@/plugins/log'
import { serviceWorkerHandler } from '@/plugins/service-worker/service-worker-register'
import brochurewareRoutes from './brochureware'
import authRoutes from './auth'
import postRoutes from './post'
import accountRoutes from './account'
import searchRoutes from './search'
import shopRoutes from './shop'

Vue.use(VueRouter)

let routes = [
  ...brochurewareRoutes,
  ...authRoutes,
  ...postRoutes,
  ...accountRoutes,
  ...searchRoutes,
  ...shopRoutes
]

// 404 not found
routes.push({
  name: '404',
  path: '*',
  component: () => import(/* webpackChunkName: "RouteNotFound" */ '@/views/RouteNotFound.vue')
})

const Router = new VueRouter({
  base: process.env.VUE_APP_PUBLIC_PATH || '/',
  mode: 'history',
  routes: routes,

  parseQuery(query) {
    return qs.parse(query)
  },

  stringifyQuery(query) {
    const result = qs.stringify(query)

    return result ? '?' + result : ''
  },

  scrollBehavior(to, from, savedPosition) {
    if (to.name === 'search' && from.name === 'search') {
      return false
    }

    if (to.hash) {
      return {
        selector: to.hash
      }
    }

    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

Router.beforeEach((to, from, next) => {
  // Set route loading state so we can show spinners if chunks have to be downloaded on slow connections
  store.commit('app/setRouteLoading', true)

  // Clear our available adverts so they can be refetched
  store.commit('adverts/reset')

  // Let's check our locale is applied before showing any content (avoid flash of un-translated text)
  if (store.state.locale.language && store.state.locale.language !== i18n.locale) {
    i18n.locale = store.state.locale.language
    next()
  } else if (!store.state.locale.language) {
    store.dispatch('locale/setLanguage', navigator.language).then(() => {
      i18n.locale = store.state.locale.language
      next()
    })
  } else {
    next()
  }
})

Router.beforeResolve((to, from, next) => {
  // Now our chunk has finished loading lets remove the spinner
  store.commit('app/setRouteLoading', false)

  next()
})

Router.afterEach((to, from) => {
  if (to.fullPath !== from.fullPath) {
    Vue.prototype.$analytics.addPageView(to.name, to.fullPath)
  }

  if (to.name !== from.name) {
    let appIs = store.getters['app/appIs']

    // After a route change let's check to see if our service worker has any updates
    if ('serviceWorker' in navigator && !appIs.native) {
      if (store.state.device.needsFullRefresh) {
        log.debug('Route has changed, perfoming full refresh...')

        let worker = serviceWorkerHandler.waiting

        log.debug('worker', worker)

        if (worker) {
          worker.postMessage({ action: 'skipWaiting' })
        }
      } else {
        if (serviceWorkerHandler) {
          serviceWorkerHandler.update()
        }
      }
    }

    if (to.name !== 'logout') {
      store.dispatch('notifications/getUnreads')
    }

    store.commit('notifications/routeChange')
    store.dispatch('app/routeChange')

    if (!to.name.includes('account-messages')) {
      store.dispatch('modal/closeModals')
    }

    if (to.name !== 'logout') {
      store.dispatch('cart/get')
    }
  }
})

// Global catch for bad navigation
Router.onError(error => {
  log.debug('Routing error', error)
})

export default Router
