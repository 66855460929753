import i18n from '@/plugins/i18n'

export default {
  HEAD: 'H',
  KG: 'K',
  OUTFIT: 'O',
  ALL: 'X',
  TONNE: 'T',
  ACRE: 'A',
  BALE: 'B',
  DOG: 'D',
  LITRE: 'L',
  HECTARE: 'HC'
}

export const unitCodeToHuman = {
  H: 'Head',
  K: 'KG',
  O: 'Outfit',
  X: 'All',
  T: 'Tonne',
  A: 'Acre',
  B: 'Bale',
  D: 'Dog',
  L: 'Litre',
  HC: 'Hectare'
}

export const unitHumanReadablePerUnits = {
  H: i18n.t('units.perHead'),
  K: i18n.t('units.perKG'),
  O: i18n.t('units.perOutfit'),
  X: i18n.t('units.inTotal'),
  T: i18n.t('units.perTonne'),
  A: i18n.t('units.perAcre'),
  B: i18n.t('units.perBale'),
  D: i18n.t('units.perDog'),
  L: i18n.t('units.perLitre'),
  HC: i18n.t('units.perHectare'),
  short: {
    H: i18n.t('units.perHeadShort'),
    K: i18n.t('units.perKGShort'),
    O: i18n.t('units.perOutfitShort'),
    X: i18n.t('units.inTotalShort'),
    T: i18n.t('units.perTonneShort'),
    A: i18n.t('units.perAcreShort'),
    B: i18n.t('units.perBaleShort'),
    D: i18n.t('units.perDogShort'),
    L: i18n.t('units.perLitreShort'),
    HC: i18n.t('units.perHectareShort')
  }
}
