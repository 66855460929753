import LocationModel from '@/lib/models/location'

export default function (company = {}) {
  const types = company.types && company.types.length ? company.types.map(type => type.typeRef) : []

  return {
    companyRef: company.companyRef || '',
    name: company.name || '',
    bio: company.bio || '',
    types: types,
    number: company.number || '',
    vatNumber: company.vatNumber || '',
    location: company.location ? [new LocationModel(company.location[0])] : [new LocationModel()],
    main: company.main || 0,
    picFileRef: company.picFileRef || ''
  }
}
