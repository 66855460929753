import Vue from 'vue'
import store from '@/store'

const notifications = {
  install(vm) {
    vm.prototype.$notify = {}

    vm.prototype.$notify.add = (message, type, position = 'sticky', data = null) => {
      store.dispatch('notifications/add', {
        message,
        type,
        position,
        data
      })
    }

    vm.prototype.$notify.success = (message, data = null) =>
      vm.prototype.$notify.add(message, 'success', 'sticky', data)

    vm.prototype.$notify.information = (message, data = null) =>
      vm.prototype.$notify.add(message, 'information', 'sticky', data)

    vm.prototype.$notify.warning = (message, data = null) =>
      vm.prototype.$notify.add(message, 'warning', 'sticky', data)

    vm.prototype.$notify.error = (message, data = null) =>
      vm.prototype.$notify.add(message, 'error', 'sticky', data)

    vm.prototype.$notify.floatSuccess = (message, data = null) =>
      vm.prototype.$notify.add(message, 'success', 'floating', data)

    vm.prototype.$notify.floatInformation = (message, data = null) =>
      vm.prototype.$notify.add(message, 'information', 'floating', data)

    vm.prototype.$notify.floatWarning = (message, data = null) =>
      vm.prototype.$notify.add(message, 'warning', 'floating', data)

    vm.prototype.$notify.floatError = (message, data = null) =>
      vm.prototype.$notify.add(message, 'error', 'floating', data)
  }
}

Vue.use(notifications)
