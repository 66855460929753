import Vue from 'vue'
import LegacyApi from '@/lib/api/legacy'
import { isNativeApp } from '@/plugins/native-app/capacitor'
import log from '@/plugins/log'
import AuthModel from '@/lib/models/auth'
import { capitalize, get } from 'lodash'

const STORAGE_KEY = 'sml-user'
const TOKEN_KEY = `${STORAGE_KEY}-token`
const EMAIL_TOKEN_KEY = `${STORAGE_KEY}-email`

const initialState = () => {
  return {
    api: new LegacyApi({ auth: [new AuthModel(window.localStorage.getItem(EMAIL_TOKEN_KEY))] }),
    token: window.localStorage.getItem(TOKEN_KEY),
    nativeLoggedOut: false,
    singleUseToken: null
  }
}

const getters = {
  isLoggingIn: (state, getters, rootState) => {
    return state.api.loading || rootState.app.api.loading
  }
}

const actions = {
  async login({ dispatch }) {
    await dispatch('sendAuth', 'login')
  },

  async register({ dispatch }) {
    await dispatch('sendAuth', 'register')
  },

  // Call the API to login or register then wait for app.get
  async sendAuth({ state, dispatch, commit }, action = 'login') {
    commit('storage/setInitialLoadFinished', false, { root: true })

    await state.api.post(`users.${action}`, state.api.model).catch(() => {})

    if (state.api.model.auth[0].emailAddress) {
      window.localStorage.setItem(EMAIL_TOKEN_KEY, state.api.model.auth[0].emailAddress)
    }

    Vue.prototype.$analytics.addEvent({
      category: 'auth',
      action: capitalize(action)
    })

    if (state.api.message === 'Email Address is not valid') {
      Vue.prototype.$analytics.addEvent(
        {
          category: 'auth',
          action: '*Invalid register email*',
          label: get(state.api, 'model.auth[0].emailAddress')
        },
        true
      )
    }

    await dispatch('app/get', null, { root: true })
  },

  checkNativeLogin({ state, rootState, dispatch }) {
    // Mobile users must be logged in at all times
    if (isNativeApp && !rootState.app.api.data.auth && !state.nativeLoggedOut) {
      dispatch('modal/showModal', 'ModalAuthLoginNativeApp', { root: true })
    }
  },

  async logout({ state, commit, dispatch }, showNotification = true) {
    log.debug('Logging out')

    await state.api.post(`users.logout`)

    await dispatch('app/resetAllModules', null, { root: true })

    Vue.prototype.$analytics.addEvent({
      category: 'auth',
      action: 'Logout'
    })

    if (isNativeApp) {
      // Clear biometric data
      if (window.plugins.touchid) {
        window.plugins.touchid.delete(process.env.VUE_APP_KEYCHAIN_PASSWORD_KEY)
      }

      commit('setNativeLoggedOut', true)

      dispatch('modal/showModal', 'ModalAuthLoggedOutNativeApp', { root: true })
    } else if (showNotification) {
      Vue.prototype.$notify.floatWarning('You have logged out')
    }

    dispatch('app/get', null, { root: true })
  },

  async loginWithSingleUsetoken({ commit, dispatch }, token) {
    log.debug('Logging in with single use token...')

    await dispatch('app/resetAllModules', null, { root: true })

    commit('setSingleUseToken', token)
    commit('setToken', token)

    let appApi = new LegacyApi()

    try {
      await appApi.get('app.get')
    } catch (error) {
      // Single use failed, don't tell the user just carry on as normal
      log.debug('Single use login failed')
    }

    commit('setSingleUseToken', null)

    log.debug('Finished logging in with single use token, did it work?', get(appApi, 'data.auth'))
  }
}

const mutations = {
  setToken(state, token) {
    state.token = token
    window.localStorage.setItem(TOKEN_KEY, token)
  },

  setSingleUseToken(state, token) {
    state.singleUseToken = token
  },

  reset(state) {
    log.debug('Resetting auth module', STORAGE_KEY)
    window.localStorage.removeItem(TOKEN_KEY)
    Object.assign(state, initialState())
  },

  setNativeLoggedOut(state, nativeLoggedOut) {
    state.nativeLoggedOut = nativeLoggedOut
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters
}
