export default function (existingData = {}) {
  return {
    initiator: null,
    quantity: 1,
    description: '',
    currencyCode: 'GBP',
    amount: null,
    vat: 0,
    vatRef: '',
    fee: {
      seller: 0.5,
      buyer: 0.5,
      ...existingData.fee
    },
    seller: {
      email: '',
      ...existingData.seller
    },
    buyer: {
      email: '',
      ...existingData.buyer
    },
    isBuyer: false,
    listingRef: null,
    termsAgreed: 0,
    type: 'Secure Payment',
    ...existingData
  }
}
