<template>
  <div :class="compResultsVisible ? 'z-50' : 'z-5'" class="relative">
    <transition name="fade">
      <div v-if="compResultsVisible" @click="setNavSearchOpen(false)" class="results-background z-2"></div>
    </transition>

    <div class="relative z-3">
      <FormTextInput
        name="quick-search"
        type="search"
        :placeholder="compPlaceholder"
        v-model="searchQuery"
        :iconPrefix="faSearch"
        :isLoading="isLoading"
        @input="querySearch($event)"
        @keyup.down="selectNextResult()"
        @keyup.up="selectPreviousResult()"
        @keyup.enter="navigateToResult()"
        @click.native="restoreResults()"
        autocomplete="off"
        class="search-input mb-0 placeholder-darker"
        targetClass="w-full"
      />

      <ul
        v-if="compResultsVisible"
        class="search-results"
        :class="$store.state.search.isMobileSearchMenuOpen ? 'right-0' : 'w-full'"
      >
        <li v-for="(result, index) in compResults" :key="result.objectID">
          <UtilTextLink
            @click="navigateToResult(result.path)"
            class="search-result relative flex items-center justify-between no-underline border-b border-gray-lighter hover:bg-gray-lightest hover:text-black px-6 py-2"
            :class="{ 'bg-gray-lightest': selectedIndex === index }"
          >
            <span class="truncate">
              <span v-html="result.label" class="whitespace-no-wrap" />
            </span>

            <span class="flex-shrink-0 text-gray uppercase text-sm ml-6">
              {{ result.type }}
            </span>
          </UtilTextLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { throttle, get } from 'lodash'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import algoliasearch from 'algoliasearch'
import algoliasearchHelper from 'algoliasearch-helper'
import FormTextInput from '@/components/forms/elements/FormTextInput'
import UtilTextLink from '@/components/utils/UtilTextLink'

export default {
  name: 'UtilSearchBar',
  components: {
    FormTextInput,
    UtilTextLink
  },
  data() {
    return {
      searchClient: null,
      searchHelper: null,
      isLoading: false,
      searchQuery: '',
      searchResult: null,
      selectedIndex: -1,
      isNavigatingToSearchPage: false,
      faSearch
    }
  },
  created() {
    this.setup()
  },
  computed: {
    compResults() {
      if (!get(this.searchResult, 'results.hits')) {
        return []
      }

      return this.searchResult.results.hits.map(result => ({
        ...result,
        label: this.$breakpoint.xs
          ? result._highlightResult.title.value.replace('Livestock > ', '')
          : result._highlightResult.title.value
      }))
    },

    compHasResults() {
      return this.compResults.length > 0
    },

    compResultsVisible() {
      return this.compHasResults && this.$store.state.search.isNavSearchOpen && this.searchQuery
    },

    compPlaceholder() {
      if (this.$breakpoint.lgAndUp) {
        return 'Search for anything (e.g calves, stores)'
      }

      if (this.$breakpoint.xs) {
        return 'Search for anything (e.g calves, stores)'
      }

      return 'Search for anything'
    }
  },
  methods: {
    setup() {
      this.searchClient = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_KEY)

      this.searchHelper = algoliasearchHelper(
        this.searchClient,
        `${process.env.VUE_APP_ALGOLIA_INDEX_PREFIX}sml_search`
      )

      this.searchHelper.setQueryParameter('hitsPerPage', 20)

      this.searchHelper.on('result', event => {
        this.searchResult = event
        this.isLoading = false

        this.setNavSearchOpen(this.compHasResults && !this.isNavigatingToSearchPage)
      })

      this.searchHelper.on('error', error => {
        this.isLoading = false
        this.$log.debug('Search error', error)
      })
    },

    querySearch: throttle(
      function () {
        if (!this.searchQuery) {
          this.setNavSearchOpen(false)
          return
        }

        if (this.searchQuery.length < 3) {
          return
        }

        this.isLoading = true

        this.searchHelper.setQuery(this.searchQuery)
        this.searchHelper.search()

        this.selectedIndex = -1
      },
      1000,
      { leading: true }
    ),

    navigateToResult(path) {
      // Arrive here via click or keyboard
      if (!path && this.selectedIndex > -1) {
        path = this.compResults[this.selectedIndex].path
      }

      // Have we actually highlighted anything yet
      if (path) {
        this.$router.push(path).catch(() => {})
        this.reset()

        this.$emit('chosenItem', path)

        this.$analytics.addEvent({
          category: 'navigation',
          action: 'Clicked global search result',
          label: this.searchQuery
        })
      } else {
        this.isNavigatingToSearchPage = true

        this.$analytics.addEvent({
          category: 'navigation',
          action: 'Pressed enter immediately after typing result',
          label: this.searchQuery
        })

        this.$router.push(`/search/query/${this.searchQuery}`).catch(() => {})

        this.setNavSearchOpen(false)

        this.reset()

        this.$emit('chosenItem', false)

        setTimeout(() => {
          this.isNavigatingToSearchPage = false
        }, 2000)
      }
    },

    selectNextResult() {
      this.selectedIndex++

      if (this.selectedIndex >= this.compResults.length - 1) {
        this.selectedIndex = this.compResults.length - 1
      }
    },

    selectPreviousResult() {
      this.selectedIndex--

      if (this.selectedIndex < 0) {
        this.selectedIndex = 0
      }
    },

    // We keep the search query in the input so if the user clicks back in we should show the previous results
    restoreResults() {
      if (this.compHasResults && this.searchQuery) {
        this.setNavSearchOpen(true)
      }
    },

    reset() {
      this.selectedIndex = -1
      this.isLoading = false
      this.setNavSearchOpen(false)
      this.searchQuery = ''
    },

    setNavSearchOpen(isOpen) {
      this.$store.commit('search/mutate', { key: 'isNavSearchOpen', value: isOpen })
    }
  }
}
</script>

<style lang="scss" scoped>
.results-background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

.search-input::v-deep input {
  @apply border-brand-darker;
}

.search-results {
  @apply bg-white absolute shadow-2xl border-gray rounded overflow-y-auto mt-1 right-0;
  top: 100%;
  max-height: 80vh;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0, 0, 0);
}

.search-result::v-deep em {
  @apply not-italic font-bold;
}

.search-results li:last-child .search-result {
  @apply border-b-0;
}
</style>