<template>
  <div class="bg-red-400 text-primary py-1 flex items-center justify-center">
    <FontAwesomeIcon :icon="faExclamationTriangle" class="mr-1" />
    <span>
      You are testing with
      <span class="font-bold">LIVE</span>
      data
    </span>
  </div>
</template>

<script>
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'UtilWarningStaging',
  data() {
    return {
      faExclamationTriangle
    }
  }
}
</script>
