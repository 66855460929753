// The layout might be a little confusing here.
// Just before launch we decided to drop buyer/seller/member in favour of just member.
// So everything around subscriptions is setup around choosing 1 of 3, instead of just 1

import planInterval from '@/lib/mapping/plan-interval'

const config = {
  availablePlans: [
    {
      text: 'Bronze Member',
      shortName: 'Bronze',
      value: 'sml-bronze',
      benefits: { discounts: false, qualityReview: false }
    },
    {
      text: 'Silver Member',
      shortName: 'Silver',
      value: 'sml-silver',
      benefits: { discounts: true, qualityReview: false },
      priceProducts: {
        [planInterval.YEAR]: 'sml-silver-yearly',
        [planInterval.MONTH]: 'sml-silver-monthly'
      }
    },
    {
      text: 'Gold Member',
      shortName: 'Gold',
      value: 'sml-gold',
      benefits: { discounts: true, qualityReview: true },
      priceProducts: {
        [planInterval.YEAR]: 'sml-gold-yearly',
        [planInterval.MONTH]: 'sml-gold-monthly'
      }
    },
    {
      text: 'Platinum Member',
      shortName: 'Platinum',
      value: 'sml-platinum',
      benefits: { discounts: true, qualityReview: true }
    }
  ]
}

export default config

export const plansWithPriceProducts = config.availablePlans.filter(plan => plan.priceProducts)
