import Vue from 'vue'
import { cloneDeep, get, set, uniq } from 'lodash'
import LegacyApi from '@/lib/api/legacy'
import log from '@/plugins/log'
import subscribeConfig, { plansWithPriceProducts } from '@/lib/helpers/subscribe'
import subscriptionStatus from '@/lib/mapping/subscription-status'
import iapStatus from '@/lib/mapping/iap-status'
import { listingStatus } from '@/lib/mapping'
import planInterval from '@/lib/mapping/plan-interval'

const initialState = () => {
  let state = {
    plansApi: new LegacyApi(),
    cardsApi: new LegacyApi(),
    iosValidatorApi: new LegacyApi(),
    existingListingsApi: new LegacyApi(),
    enquiriesApi: new LegacyApi(),
    generateStripeSessionApi: new LegacyApi(),
    iosConfigured: false,
    iosPlans: {},
    iosProductSlugs: {},
    usedLimits: {
      listings: 0,
      enquiries: 0,
      existingListingEnquiryRefs: []
    },
    subscriptionPurchaseOrigin: null
  }

  plansWithPriceProducts.forEach(plan => {
    if (!plan.priceProducts[planInterval.YEAR]) {
      return
    }

    state.iosPlans[plan.value] = { priceProduct: {} }

    Object.keys(plan.priceProducts).map(interval => {
      const planConfig = {
        registration: {
          // Convert our premium-member slug to sml.premium.member (Apple product ID)
          id: `${process.env.VUE_APP_SUBSCRIBER_IOS_PREMIUM_PRODUCT_PREFIX}${plan.priceProducts[interval].replace(
            /-/g,
            '.'
          )}${process.env.VUE_APP_SUBSCRIBER_IOS_PREMIUM_PRODUCT_SUFFIX}`,
          alias: plan.priceProducts[interval],
          type: 'paid subscription'
        },
        interval,
        status: iapStatus.REGISTERED,
        product: {}
      }

      state.iosPlans[plan.value].priceProduct[interval] = planConfig

      // This is a helper object to help us easily navigate the child plans
      state.iosProductSlugs[plan.priceProducts[interval]] = {
        parentPlanSlug: plan.value,
        interval
      }
    })
  })

  return state
}

const getters = {
  getPlan: (state, getters) => planSlug => {
    if (getters.plans.length === 0) {
      return {}
    }

    // If subscribed to legacy sml-premium plan then treat as gold
    if (planSlug === 'sml-premium' || planSlug === 'premium-account') {
      planSlug = 'sml-gold'
    }

    let plan = state.plansApi.data.find(plan => {
      return plan.slug === planSlug
    })

    let matchingPlanConfig = subscribeConfig.availablePlans.find(planConfig => {
      return planConfig.value === planSlug
    })

    if (matchingPlanConfig && plan) {
      plan.shortName = matchingPlanConfig.shortName
      plan.benefits = matchingPlanConfig.benefits
      plan.priceProducts = matchingPlanConfig.priceProducts
    }

    // API returns strings for numbers and 1/0 for booleans. Let's normalise
    plan.listingEnquiriesPerYear = parseInt(plan.listingEnquiriesPerYear)
    plan.listingContactDelay = parseInt(plan.listingContactDelay)
    plan.viewListingDelay = parseInt(plan.viewListingDelay)
    plan.listingsPerYear = parseInt(plan.listingsPerYear)
    plan.listingDetailVisible = !!plan.listingDetailVisible
    plan.listingManageAutoRenewal = !!plan.listingManageAutoRenewal
    plan.listingQualityReview = !!plan.listingQualityReview
    plan.listWithPoa = !!plan.listWithPoa

    return plan
  },

  getPlanFromPriceProductSlug: (state, getters) => priceProductSlug => {
    let matchingParentPlan = null

    plansWithPriceProducts.forEach(plan => {
      Object.keys(plan.priceProducts).map(interval => {
        if (plan.priceProducts[interval] === priceProductSlug) {
          matchingParentPlan = plan
        }
      })
    })

    if (!matchingParentPlan) {
      return {}
    }

    return getters.getPlan(matchingParentPlan.value)
  },

  plans: state => {
    return state.plansApi.data || []
  },

  getActiveSubscriptionPlan: (state, getters, rootState, rootGetters) => {
    const user = rootGetters['app/getUser']

    // We can't get this from app.subscriptions because app/getApp calls this getter
    // and we'd hit a stack call limit for them calling each other forever
    const activeSubscriptions = rootGetters['app/getActiveSubscriptions']

    // Not logged in user has no subscription
    if (!user.userRef || activeSubscriptions.length === 0) {
      return null
    }

    let activePlan = null

    // If subscribed to legacy sml-premium plan then treat as gold
    if (activeSubscriptions.find(subscription => subscription.depreciated === 1)) {
      activePlan = getters.getPlan('sml-gold')
    } else if (user.fullAccess === 1) {
      // God mode get's platinum plan
      activePlan = getters.getPlan('sml-platinum')
    } else {
      // This account may have GDX or other unrelated subscription plans
      let smlPlanSlugs = subscribeConfig.availablePlans.map(plan => plan.value)

      let activeAccountSubscriptions = activeSubscriptions.filter(
        subscription =>
          smlPlanSlugs.includes(subscription.productSlug) && subscription.status === subscriptionStatus.LIVE
      )

      activePlan =
        activeAccountSubscriptions.length > 0
          ? getters.getPlan(activeAccountSubscriptions[0].productSlug)
          : getters.getPlan('sml-bronze')
    }

    return activePlan
  },

  getFeatureRestrictions: (state, getters) => {
    const activeSubscriptionPlan = getters['getActiveSubscriptionPlan']

    const startOfNextYearDate = `${new Date().getFullYear() + 1}-01-01`

    const defaultLimits = {
      postListing: {
        isUnlimited: false,
        quota: 0,
        used: 0,
        remaining: 0,
        resetDate: startOfNextYearDate
      },
      enquireListing: {
        isUnlimited: false,
        quota: 0,
        used: 0,
        remaining: 0,
        resetDate: startOfNextYearDate,
        existingListingEnquiryRefs: state.usedLimits.existingListingEnquiryRefs
      },
      viewListingDelay: {
        hours: 72
      }
    }

    let restrictionConfig = {
      canPostListing: false,
      canViewListingDetail: false,
      canEnquireListing: false,
      canPostPOA: false,
      canManageAutoRenewListing: false,
      canRequestListingReview: false,
      limits: { ...defaultLimits }
    }

    if (activeSubscriptionPlan) {
      const numberOfListingsPosted = state.usedLimits.listings
      const numberOfListingsEnquired = state.usedLimits.enquiries

      // Post listing
      restrictionConfig.limits.postListing.isUnlimited = activeSubscriptionPlan.listingsPerYear === 9999
      restrictionConfig.limits.postListing.quota = activeSubscriptionPlan.listingsPerYear
      restrictionConfig.limits.postListing.used = numberOfListingsPosted
      restrictionConfig.limits.postListing.remaining =
        restrictionConfig.limits.postListing.quota - numberOfListingsPosted
      restrictionConfig.canPostListing = restrictionConfig.limits.postListing.remaining > 0

      // Enquire listing
      restrictionConfig.limits.enquireListing.isUnlimited = activeSubscriptionPlan.listingEnquiriesPerYear === 9999
      restrictionConfig.limits.enquireListing.quota = activeSubscriptionPlan.listingEnquiriesPerYear
      restrictionConfig.limits.enquireListing.used = numberOfListingsEnquired
      restrictionConfig.limits.enquireListing.remaining =
        restrictionConfig.limits.enquireListing.quota - numberOfListingsEnquired
      restrictionConfig.canEnquireListing = restrictionConfig.limits.enquireListing.remaining > 0

      restrictionConfig.limits.viewListingDelay = activeSubscriptionPlan.listingContactDelay

      restrictionConfig.canViewListingDetail = activeSubscriptionPlan.listingDetailVisible
      restrictionConfig.canManageAutoRenewListing = activeSubscriptionPlan.listingManageAutoRenewal
      restrictionConfig.canRequestListingReview = activeSubscriptionPlan.listingQualityReview
      restrictionConfig.canPostPOA = activeSubscriptionPlan.listWithPoa
    }

    return restrictionConfig
  },

  getSubscriptionPurchaseOrigin: state => {
    return state.subscriptionPurchaseOrigin
  }
}

const actions = {
  async fetchRestrictedFeaturesUsage({ state, commit, rootGetters }) {
    let appIs = rootGetters['app/appIs']

    if (!appIs.auth) {
      return false
    }

    // API has a DD-MM-YYYY format
    const startOfThisYearLegacyDate = `01-01-${new Date().getFullYear()}`

    // Get number of listings for this user for this year
    const getListings = async () => {
      await state.existingListingsApi.get('listings.summary', {
        commodities: ['livestock'],
        statuses: [
          listingStatus.LIVE,
          listingStatus.SOLD,
          listingStatus.SOLD_ALT,
          listingStatus.WITHDRAWN,
          listingStatus.FINISHED
        ],
        DateFrom: startOfThisYearLegacyDate
      })

      commit('setUsedLimits', { key: 'listings', value: state.existingListingsApi.data.listings.length })
    }

    // Get number of enquiries
    const getEnquiries = async () => {
      await state.enquiriesApi.get('enquiries.listings', {
        dateFrom: startOfThisYearLegacyDate
      })

      if (state.enquiriesApi.data.calls) {
        const callListingRefs = state.enquiriesApi.data.calls
        const threadListingRefs = state.enquiriesApi.data.threads

        const uniqueListingRefs = uniq([...callListingRefs, ...threadListingRefs])

        commit('setUsedLimits', { key: 'enquiries', value: uniqueListingRefs.length })
        commit('setUsedLimits', { key: 'existingListingEnquiryRefs', value: uniqueListingRefs })
      }
    }

    await Promise.all([getListings(), getEnquiries()])
  },

  // Use context to describe this user so we can tailer messaging.
  // combined or buyer or seller
  showSubscriptionPlanChooser({ dispatch }, contextData) {
    dispatch(
      'modal/showModal',
      {
        modalName: 'ModalSubscribeChoosePlan',
        modalData: {
          ...contextData
        }
      },
      { root: true }
    )
  },

  async getPlans({ state }) {
    await state.plansApi.get('subscriptions.products?NewProducts=1', undefined, { throttleMs: 5000 })
  },

  async getCards({ state }, force = false) {
    await state.cardsApi.get('cards.get', undefined, { throttleMs: force ? undefined : 5000 })
  },

  async setupNativeIAPRegistration({ commit, dispatch, state, rootGetters }) {
    if (!window.store) {
      return log.error('Native IAP: Error initialising, no store object')
    }

    window.store.verbosity = window.store.DEBUG

    let appIs = rootGetters['app/appIs']

    // Don't double initialise
    if (state.iosConfigured || !appIs.ios) {
      return false
    }

    commit('setNativeIAPConfigured')

    dispatch('setupNativeIAPValidator')
    dispatch('setupNativeIAPListeners')

    // Setup IAP subscription product, we'll need to call window.store.refresh()
    // to actually get the products
    const iosRegistrationPlans = []

    Object.keys(state.iosPlans).map(planSlug => {
      const plan = state.iosPlans[planSlug]

      Object.keys(plan.priceProduct).map(interval => {
        iosRegistrationPlans.push(plan.priceProduct[interval].registration)
      })
    })

    window.store.register(iosRegistrationPlans)

    log.debug('Native IAP: Registered and ready')

    setTimeout(() => dispatch('refreshNativeIAPProducts'), 2000)
  },

  // Load the product data from the app store and whole bunch of first time background processes to get us ready.
  // This is a potentially expensive operation so don't constantly call.
  // If you need just product data updates use window.store.update()
  refreshNativeIAPProducts(context, callback) {
    log.debug('Native IAP: Refreshing store', callback)

    window.store.refresh().finished(() => {
      if (callback) {
        callback()
      }
    })
  },

  setupNativeIAPListeners({ state, commit, rootGetters }) {
    log.debug('Native IAP: Setting up plan listenerss')

    let app = rootGetters['app/getApp']

    // Loop through our available plans and setup event listeners to keep their status updated in the store
    Object.keys(state.iosProductSlugs).forEach(planSlug => {
      // We can use the slug here even though it doesn't match our app store id because we created an alias using it
      // inside setupNativeIAPRegistration()
      let planAlias = planSlug

      window.store.when(planAlias).updated(product => {
        console.log('Product updated', planAlias, { state: product.state, owned: product.owned })

        commit('setNativeIAPPlan', {
          planAlias,
          product
        })
      })

      // The user has approved the IAP, we now need to sent it off to our own API to
      // verify the transaction and set the user as subscribed in the db
      window.store.when(planAlias).approved(product => {
        log.debug('Native IAP: APPROVED', product)

        // We receive the original download of the app as an approved transaction!?
        if (product.id === process.env.VUE_APP_APP_BUNDLE_ID) {
          log.debug('Native IAP: Ignoring app download receipt in approved call', product)
          return false
        }

        commit('setNativeIAPPlan', {
          planAlias,
          product
        })

        try {
          product.verify()
        } catch (error) {
          log.error('Native IAP: Validation Error', error)

          product.finish()

          Vue.prototype.$notify.floatError(
            `There was a problem with your ${app.platform} subscription. Please try again.`
          )
        }
      })

      // Our API has returned and the purchase has been verified, let's finish up
      window.store.when(planAlias).verified(product => {
        log.debug('Native IAP: VERIFIED', product)

        commit('setNativeIAPPlan', {
          planAlias,
          product
        })

        // Vue.prototype.$notify.floatSuccess(`You are now subscribed!`)

        product.finish()
      })

      // Our API returned a problem with the verification, let's inform the user
      window.store.when(planAlias).unverified(product => {
        log.error('Native IAP: UN-VERIFIED', product)

        commit('setNativeIAPPlan', {
          planAlias,
          product
        })

        Vue.prototype.$notify.floatError(
          `There was a problem verifying your ${app.platform} subscription. Please try again.`
        )
      })

      window.store.when(planAlias).expired(product => {
        log.debug('Native IAP: Product expired, finishing')
        product.finish()
      })

      window.store.when(planAlias).cancelled(product => {
        log.debug('Native IAP: CANCELLED')

        commit('setNativeIAPPlan', {
          planAlias,
          product
        })

        Vue.prototype.$notify.floatSuccess(`Your ${app.platform} subscription was cancelled`)
      })
    })

    window.store.error(error => {
      log.error('Native IAP: Error', error)
    })
  },

  async upgradeComplete({ getters, dispatch, rootGetters }, { planSlug, planRef }) {
    let app = rootGetters['app/getApp']
    let plan = getters['getPlan'](planSlug)
    let actualPlan = plan.plans.find(product => product.planRef === planRef)

    Vue.prototype.$analytics.addEvent(
      {
        category: 'payments',
        action: `Paid for subscription plan - ${app.platform}`,
        label: actualPlan.slug,
        value: actualPlan.price
      },
      true
    )

    await dispatch('app/get', null, { root: true })
  },

  getIosPremiumSubscriptionProduct() {
    log.debug('Native IAP: Refreshing')
    window.store.update()
  },

  async setupNativeIAPValidator({ state, getters, dispatch, rootGetters }) {
    window.store.validator = async (product, callback) => {
      log.debug('Native IAP: Validating', product)

      const app = rootGetters['app/getApp']

      // The plugin sends us a receipt for the original application purchase.
      // We need to ignore this
      if (product.type === 'application') {
        log.debug('Native IAP: Ignoring app download receipt in verification call', product)

        return callback(true)
      }

      console.log(JSON.stringify(product))

      // We need to check this IAP product has our custom data
      // And that the current userRef matches that embedded into the validator
      log.debug(JSON.stringify(product.additionalData), app.user.userRef)

      if (get(product, 'additionalData.applicationUsername') !== app.user.userRef) {
        log.debug('Native IAP: Ignoring, payload invalid', product)

        // Tell the validator plugin that the receipt has expired and not to bother
        // trying again
        product.finish()

        return callback(false, {
          code: window.store.PURCHASE_EXPIRED
        })
      }

      try {
        // Call API and add the subscription
        let planSlug = product.alias
        let parentPlan = getters['getPlanFromPriceProductSlug'](planSlug)

        const priceProduct = parentPlan.plans.find(product => product.slug === planSlug)

        console.log('plan debug', planSlug, priceProduct)

        await state.iosValidatorApi.post('subscriptions.ios', {
          userRef: app.user.userRef,
          accountRef: app.account.accountRef,
          planRef: priceProduct.planRef,
          payload: product
        })

        if (state.iosValidatorApi.data.subscriptionRef) {
          log.debug('Native IAP: Returning TRUE to IAP validator')

          await dispatch('upgradeComplete', { planSlug: parentPlan.slug, planRef: priceProduct.planRef })

          return callback(true)
        } else {
          log.error(
            'Native IAP: Returning false to IAP validator, no subscription ref from API',
            state.iosValidatorApi.data
          )

          product.finish()

          return callback(false, {
            code: window.store.PURCHASE_EXPIRED
          })
        }
      } catch (error) {
        log.error('Native IAP: Returning false to IAP validator, error from API', error)

        product.finish()

        return callback(false, {
          code: window.store.PURCHASE_EXPIRED
        })
      }
    }
  },

  async openStripeMembershipPage({ state, rootGetters }, returnUrl) {
    let appIs = rootGetters['app/appIs']

    Vue.prototype.$analytics.addEvent(
      {
        category: 'payments',
        action: 'Visited stripe billing portal'
      },
      true
    )

    await state.generateStripeSessionApi.get('subscriptions.stripesession', {
      returnUrl
    })

    const sessionUrl = state.generateStripeSessionApi.data.sessionUrl

    if (sessionUrl) {
      if (appIs.native) {
        Vue.prototype.$openWindow(sessionUrl, true)
      } else {
        window.location.href = sessionUrl
      }
    }
  }
}

const mutations = {
  setNativeIAPConfigured(state) {
    state.iosConfigured = true
  },

  setNativeIAPPlan(state, { planAlias, product }) {
    let parentPlanSlug = state.iosProductSlugs[planAlias].parentPlanSlug
    let interval = state.iosProductSlugs[planAlias].interval

    set(state.iosPlans[parentPlanSlug], `priceProduct[${interval}].product`, product)
    set(state.iosPlans[parentPlanSlug], `priceProduct[${interval}].status`, product.state)
  },

  setUsedLimits(state, { key, value }) {
    state.usedLimits[key] = value
  },

  setSubscriptionPurchaseOrigin(state, origin) {
    state.subscriptionPurchaseOrigin = origin
  },

  reset(state) {
    // We don't want to have to re-call our plans API when switching users
    const plansApiBackup = cloneDeep(state.plansApi)
    Object.assign(state, initialState())

    state.plansApi = plansApiBackup
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters
}
