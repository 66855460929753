import dayjs from 'dayjs'
import { contractType, listingStatus, listingType, movementType, unit } from '@/lib/mapping'
import { anyDistanceValue } from '@/lib/options/distance'

export default function (existingListing = {}) {
  return {
    account: undefined,
    autoExtend: 0,
    assurances: [],
    categories: [],
    commodity: '',
    company: undefined,
    contractTypes: [contractType.FIXED],
    description: '',
    end: '4 weeks',
    excludeFromSearch: 0,
    featureListing: 0,
    featureImageRef: '',
    fileRefs: [],
    flexible: 1,
    imageRefs: [],
    listingRef: undefined,
    listingCode: undefined,
    location: existingListing.location || [],
    media: [],
    meta: {
      ...existingListing.meta
    },
    movement: {
      radius: anyDistanceValue,
      radiusUnit: 'M',
      price: '',
      priceUnit: unit.HEAD,
      date: dayjs().format('DD-MM-YYYY'),
      ...existingListing.movement
    },
    movementTypes: [movementType.EX_FARM],
    paymentTerms: 0,
    portalRefs: [],
    premiumListing: 0,
    price: {
      price: '',
      min: '',
      max: '',
      reserve: '',
      unit: unit.HEAD,
      status: '',
      currency: 'GBP',
      type: contractType.FIXED,
      ...existingListing.price
    },
    quantity: '',
    quantityUnit: unit.HEAD,
    status: listingStatus.DRAFT,
    termsAgreed: 0,
    tradeMin: 0,
    tradeMax: 0,
    type: listingType.CLASSIFIED,
    user: undefined,
    videos: [],
    viewable: 1,
    title: '',
    viewableTimetable: [],
    wantedRef: '',
    similarListing: false,
    ...existingListing
  }
}
