import algoliasearch from 'algoliasearch/lite'
import algoliasearchHelper from 'algoliasearch-helper'
import Vue from 'vue'
import log from '@/plugins/log'
import router from '@/router'
import { xor } from 'lodash'

const STORAGE_KEY_HITS_PER_PAGE = 'sml-shop-hits-per-page'

const defaultSearchIndex = `${process.env.VUE_APP_ALGOLIA_INDEX_PREFIX}products`

const existingHitsPerPagePreference = window.localStorage[STORAGE_KEY_HITS_PER_PAGE]
  ? parseInt(window.localStorage[STORAGE_KEY_HITS_PER_PAGE])
  : 20

// Toggles or dropdowns
export const facets = []

// Multiple choice
export const disjunctiveFacets = ['categories.lvl0', 'categories.lvl1', 'categories.lvl2', 'meta.breed']

let searchClient = null

try {
  searchClient = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_KEY)
} catch (error) {
  log.error('Error configuring algoliasearch', error)

  Vue.prototype.$notify.floatError('There was an error searching. Please reload and try again')
}

let searchHelper = algoliasearchHelper(searchClient, defaultSearchIndex, {
  facets,
  disjunctiveFacets
})

// Used for URL friendly facet name mapping
export const facetMapping = {
  category: 'categories.lvl0',
  subCategory: 'categories.lvl1',
  childCategory: 'categories.lvl2',
  breed: 'meta.breed'
}

const initialState = () => {
  return {
    helper: searchHelper,
    result: null,
    index: defaultSearchIndex,
    loading: true,
    page: 1, // we only convert to zero based when we perform the search
    hitsPerPage: existingHitsPerPagePreference,
    query: '',
    filters: '',
    url: '',
    facetRefinementList: {}
  }
}

const getters = {
  products(state) {
    if (!state.result) {
      return []
    }

    return state.result.hits
  },

  activeFilters(state) {
    let activeFilterCollection = {}

    if (state.page !== 1) {
      activeFilterCollection.page = state.page
    }

    if (state.query) {
      activeFilterCollection.query = state.query
    }

    let filteredFacets = []

    // Organise the active ones here as we use them in the UI for active tags
    Object.keys(state.facetRefinementList).forEach(key => {
      if (typeof state.facetRefinementList[key] === 'undefined') {
        return
      }

      if (disjunctiveFacets.includes(key)) {
        if (state.facetRefinementList[key].length > 0) {
          state.facetRefinementList[key].forEach(facetValue => {
            filteredFacets.push({ key, value: facetValue })
          })
        }
      } else {
        filteredFacets.push({ key, value: state.facetRefinementList[key] })
      }
    })

    if (filteredFacets.length) {
      activeFilterCollection.facets = filteredFacets
    }

    return activeFilterCollection
  },

  generateUrl: state => ({ page, returnString = true } = {}) => {
    let params = {}

    params.page = Number.isInteger(page) ? page : state.page

    if (params.page === 1) {
      delete params.page
    }

    params.query = state.query || undefined

    params.orderBy = state.index === defaultSearchIndex ? undefined : state.index

    // Add our custom facets
    Object.keys(facetMapping).forEach(facetCustomKey => {
      if (disjunctiveFacets.includes(facetMapping[facetCustomKey])) {
        params[facetCustomKey] =
          (state.facetRefinementList[facetMapping[facetCustomKey]] &&
            state.facetRefinementList[facetMapping[facetCustomKey]].join('~')) ||
          undefined
      } else {
        params[facetCustomKey] = state.facetRefinementList[facetMapping[facetCustomKey]]
      }
    })

    if (returnString) {
      let paramStringArray = []

      Object.keys(params).forEach(key => {
        if (typeof params[key] !== 'undefined') {
          paramStringArray.push(`${key}=${params[key]}`)
        }
      })

      return paramStringArray.join('&')
    } else {
      return params
    }
  }
}

const actions = {
  fetchProducts({ state, commit }) {
    commit('mutate', { key: 'loading', value: true })

    state.helper.clearRefinements()

    state.helper.setQueryParameter('hitsPerPage', parseInt(state.hitsPerPage))
    state.helper.setQuery(state.query)
    state.helper.setIndex(state.index)

    // Apply our custom facets
    Object.keys(state.facetRefinementList).forEach(facetKey => {
      if (disjunctiveFacets.includes(facetKey)) {
        state.facetRefinementList[facetKey].forEach(facetValue => {
          state.helper.addDisjunctiveFacetRefinement(facetKey, facetValue)
        })
      } else if (typeof state.facetRefinementList[facetKey] !== 'undefined') {
        state.helper.addFacetRefinement(facetKey, state.facetRefinementList[facetKey])
      }
    })

    // Page needs to be zero based for algolia
    // THIS CALL MUST ALWAYS BE LAST BEFORE SEARCH, as other search functions tend to reset page to zero
    state.helper.setPage(state.page - 1)

    try {
      state.helper.search()
    } catch (error) {
      log.error('Error searching', error)
    }
  },

  applyFilters({ dispatch, getters }) {
    let generatedUrlObject = getters.generateUrl({ returnString: false })

    dispatch('saveCurrentUrl')

    router.push({ query: generatedUrlObject }).catch(() => {})
  },

  saveCurrentUrl({ commit }) {
    commit('mutate', { key: 'url', value: router.currentRoute.fullPath })
  },

  toggleFacet({ state, commit, dispatch }, { key, value }) {
    let facetRefinementList = { ...state.facetRefinementList }

    if (disjunctiveFacets.includes(key)) {
      facetRefinementList[key] = xor(facetRefinementList[key], [value])
    } else {
      // Remove facet if already enabled
      if (facetRefinementList[key] === value) {
        facetRefinementList[key] = undefined
      } else {
        facetRefinementList[key] = value
      }
    }

    commit('mutate', { key: 'facetRefinementList', value: facetRefinementList })

    // Reset the page since we are searching for new facets
    commit('mutate', { key: 'page', value: 1 })

    dispatch('applyFilters')
  },

  query({ commit, dispatch }, query) {
    commit('mutateBatch', [
      { key: 'query', value: query },
      { key: 'page', value: 1 }
    ])

    dispatch('applyFilters')
  },

  changePage({ commit, dispatch }, pageNumber) {
    commit('mutate', { key: 'page', value: pageNumber })

    dispatch('applyFilters')
  },

  mutateAndApply({ commit, dispatch }, { key, value }) {
    commit('mutate', { key, value })
    dispatch('applyFilters')
  },

  changeHitsPerPage({ commit, dispatch }, hitsPerPage) {
    commit('setHitsPerPage', hitsPerPage)

    dispatch('fetchProducts')
  }
}

const mutations = {
  mutate(state, { key, value }) {
    state[key] = value
  },

  mutateBatch(state, changes = []) {
    changes.forEach(change => {
      state[change.key] = change.value
    })
  },

  setHitsPerPage(state, hitsPerPage) {
    state.hitsPerPage = parseInt(hitsPerPage)

    window.localStorage.setItem(STORAGE_KEY_HITS_PER_PAGE, hitsPerPage)
  },

  reset(state) {
    state.page = 1
    state.query = ''
    state.facetRefinementList = {}
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
}
