const shopRoutes = [
  {
    path: '/genetics',
    redirect: '/'
  },
  {
    path: '/shop/:category?/:subCategory?',
    redirect: '/'
    // name: 'shop',
    // component: () => import(/* webpackChunkName: "shop" */ '@/views/Shop.vue')
  },
  {
    path: '/view/product/:productRef',
    redirect: '/'
    // name: 'product',
    // component: () => import(/* webpackChunkName: "product" */ '@/views/Product.vue')
  }
]

export default shopRoutes
