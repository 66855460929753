import Vue from 'vue'
import i18n from '@/plugins/i18n'

import '@/plugins/log'
import '@/plugins/head-meta'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import '@/plugins/font-awesome'
import '@/plugins/computed-mixins'
import '@/plugins/analytics'
import '@/plugins/sentry'
import '@/plugins/form-validation'
import '@/plugins/native-app'
import '@/plugins/breakpoints'
import '@/plugins/lazy-load'
import '@/plugins/date-format'
import '@/plugins/number-format'
import '@/plugins/notifications'
import '@/plugins/scroll'
import '@/plugins/open-window'
import '@/plugins/listing-title'
import '@/plugins/portal-vue'
import '@/plugins/modal'
import '@/plugins/slack'
import '@/plugins/currency-component'
import '@/plugins/sleep'
import '@/plugins/hover-intent'
import '@/plugins/google-maps'
import '@/plugins/video-autoplay'
import '@/plugins/floating-vue'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
