import LegacyApi from '@/lib/api/legacy'

const initialState = () => {
  return {
    api: new LegacyApi()
  }
}

const addToUniqueArray = (array, value) => {
  if (!array.includes(value)) {
    array.push(value)
  }
}

const getters = {
  getInsight: (state, getters, rootState, rootGetters) => {
    let app = rootGetters['app/getApp']
    let insight = {}

    if (state.api.status === 'success') {
      insight.algolia = {}
      insight.algolia.aroundLatLng = undefined

      if (state.api.data.location) {
        if (state.api.data.location.latitude) {
          insight.algolia.aroundLatLng = `${state.api.data.location.latitude}, ${state.api.data.location.longitude}`
        }
      }

      insight.algolia.disjunctiveFacets = {
        'categories.lvl0': [],
        'categories.lvl1': [],
        'meta.sexes': [],
        'meta.types': []
      }

      insight.algolia.facetsExcludes = [
        {
          facetKey: 'owner.userRef',
          value: app.user.userRef
        }
      ]

      let buying = state.api.data.buying
      let selling = state.api.data.selling

      let evidence = buying.enquiries.concat(buying.listings)
      insight.isEngager = evidence.length > 0

      let indicative = buying.views
      let indicativeNumbers = {
        average: 0,
        total: 0,
        count: indicative.length
      }

      indicative.forEach(i => {
        indicativeNumbers.total = indicativeNumbers.total + i.count
      })

      // TODO: Think we should probably look into truncated/trimmed averages to discount outliers in calculation
      indicativeNumbers.average = Math.floor(indicativeNumbers.total / indicativeNumbers.count)

      evidence = evidence.concat(indicative.filter(i => i.count >= indicativeNumbers.average))

      evidence.forEach(i => {
        if (i.field === 'Category') {
          addToUniqueArray(insight.algolia.disjunctiveFacets['categories.lvl1'], i.value)
        } else if (i.field === 'Meta.Sexes') {
          addToUniqueArray(insight.algolia.disjunctiveFacets['meta.sexes'], i.value)
        } else if (i.field === 'Meta.Types') {
          addToUniqueArray(insight.algolia.disjunctiveFacets['meta.types'], i.value)
        }
      })

      insight.isViewer = indicative.length > 0
      insight.isPoster = selling.listings.length > 0

      return insight
    } else {
      return false
    }
  }
}

const actions = {
  async get({ state }) {
    await state.api.post('users.recommendations', state.api.model)
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions
}
