<template>
  <div class="p-24 flex flex-col items-center justify-center h-full text-center">
    <div>
      <img
        class="max-w-xs mx-auto"
        src="https://hctr-assets.s3.eu-west-2.amazonaws.com/images/logos/sellmylivestock-dark.png"
      />

      <h1 class="font-medium text-3xl mt-4">
        Maintenance In Progress
      </h1>

      <p class="mt-6">
        We are performing maintenance on our trading platforms at this time.
        <br />
        Thank you for your patience and please check back soon.
      </p>

      <p class="mt-6 text-sm">
        If you are having persistant issues accessing our website please email
        <a href="mailto:support@sellmylivestock.co.uk" class="text-brand underline">
          support@sellmylivestock.co.uk
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaintenanceMode'
}
</script>
