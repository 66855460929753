<template>
  <div class="">
    <a v-if="activeParentItem" @click="clearActive()" class="menu-back">
      <FontAwesomeIcon :icon="faChevronLeft" class="mr-2" />
      <span>{{ $t('nav.back') }}</span>
    </a>

    <div class="mobile-items-section overflow-y-auto scrolling-touch">
      <template v-if="!compIsStacked || (compIsStacked && !activeParentItem)">
        <UtilMenuItem
          v-for="item in menuItems"
          :key="`parent-${item.label}`"
          :label="item.label"
          :to="item.path"
          :hasChildren="item.children && item.children.length > 0"
          @click="
            () => {
              if (item.click) {
                item.click()
                return
              }

              if (item.path) {
                $emit('close')
              } else {
                setActiveItem(item.label, 'parent')
              }
            }
          "
        >
          <span
            v-if="item.children && !compIsStacked"
            class="menu-subnav overflow-y-auto scrolling-touch"
            :class="[activeParentItem === item.label ? 'block' : 'hidden']"
          >
            <UtilMenuItem
              v-for="subItem in item.children"
              :key="`child-${subItem.label}`"
              :label="subItem.label"
              :to="subItem.path"
              @click="$emit('close')"
            />
          </span>
        </UtilMenuItem>

        <div class="p-2">
          <UtilButton @click="$emit('close')" to="/post" color="primary" class="mt-4 mr-2">
            {{ $t('nav.post') }}
          </UtilButton>

          <UtilButton v-if="!this.appIs.native" @click="$emit('close')" to="/app-download" color="secondary" class="mt-4">
            {{ $t('nav.downloadMobileApp') }}
          </UtilButton>
        </div>
      </template>

      <!-- Mobile stacked view -->
      <div v-if="compIsStacked && activeParentItem && compActiveSubMenuItems.length">
        <UtilMenuItem
          v-for="subItem in compActiveSubMenuItems"
          :key="`mobile-child-${subItem.label}`"
          :label="subItem.label"
          :to="subItem.children ? null : subItem.path"
          :hasChildren="subItem.children && subItem.children.length > 0"
          :logoFileRef="subItem.logoFileRef || ''"
          @click="
            () => {
              if (subItem.click) {
                subItem.click()
                return
              }

              if (subItem.path  && !subItem.children) {
                $emit('close')
              } else {
                setActiveItem(subItem.label, activeChildItem ? 'grandchild' : 'child')
              }
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import UtilMenuItem from '@/components/utils/UtilMenuItem'
import UtilButton from '@/components/utils/UtilButton'

export default {
  name: 'UtilMenu',
  components: {
    UtilMenuItem,
    UtilButton
  },
  props: {
    menuItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeParentItem: '',
      activeChildItem: '',
      activeGrandChildItem: '',
      faChevronLeft
    }
  },
  computed: {
    compIsStacked() {
      return this.$breakpoint.smAndDown || this.appIs.mobileOrNative
    },

    compActiveSubMenuItems() {
      let activeParent = this.menuItems.find(item => item.label === this.activeParentItem)

      if (this.activeChildItem) {
        let activeItem = activeParent.children.find(item => item.label === this.activeChildItem)

        if (this.activeGrandChildItem) {
          activeItem = activeItem.children.find(item => item.label === this.activeGrandChildItem)
        }

        return activeItem.children
      }

      return activeParent && activeParent.children ? activeParent.children : []
    }
  },
  methods: {
    clearActive() {
      if (this.activeGrandChildItem) {
        this.activeGrandChildItem = null
      } else if (this.activeChildItem) {
        this.activeChildItem = null
      } else if (this.activeParentItem) {
        this.activeParentItem = null
      }
    },

    setActiveItem(label, level) {
      switch (level) {
        case 'grandchild':
          this.activeGrandChildItem = label
          break

        case 'child':
          this.activeChildItem = label
          break

        case 'parent':
          this.activeParentItem = label
          break
      }

      this.$analytics.addEvent({
        category: 'navigation',
        action: 'Clicked mobile menu item',
        label: label
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-back {
  @apply px-5 py-3 border-b border-gray-lighter bg-gray-lightest block;
  @apply text-left cursor-pointer;
}

.menu-subnav {
  position: absolute;
  white-space: nowrap;
  left: 0;
  right: 0;
  bottom: 0;
  top: 104px;
  background: #fff;
  min-width: 340px;
  z-index: 11;
}

.menu-title {
  @apply block py-1 px-5 mb-0;
  @apply border-t border-b border-gray bg-gray-lightest;
  @apply text-gray font-medium text-sm uppercase;
}

.mobile-items-section {
  height: 71vh;
}

@media screen and (min-device-height: 800px) {
  .mobile-items-section {
    height: 94vh;
  }
}
</style>
