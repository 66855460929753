import { faAward, faCow, faGavel, faHistory, faPaw, faStar, faWheat } from '@fortawesome/pro-solid-svg-icons'

export const topLevelNavigationItems = [
  {
    label: 'Browse Listings',
    shortLabel: 'Listings',
    key: 'listings',
    children: [],
    hasDropdown: true,
    isHiddenFromMobileSearchNav: true
  },
  // {
  //   label: 'Haulage',
  //   key: 'haulage',
  //   path: '/haulage',
  //   isHiddenFromMobileSearchNav: true
  // },
  {
    label: 'Memberships',
    shortLabel: 'Memberships',
    key: 'memberships',
    path: '/membership-plans',
    isHiddenFromMobileSearchNav: true
  },
  {
    label: 'Marts & Partners',
    key: 'martsAndPartners',
    children: [],
    hasDropdown: true,
    isHiddenFromMobileSearchNav: true
  },
  {
    label: 'Livestock',
    key: 'livestock',
    icon: faCow,
    children: [],
    isHiddenFromDesktopNav: true
  },
  {
    label: 'Working Dogs',
    key: 'working-dogs',
    path: '/search/working-dogs',
    icon: faPaw,
    isHiddenFromDesktopNav: true
  },
  {
    label: 'Feed & Bedding',
    shortLabel: 'Feed/Bedding',
    key: 'feed-bedding',
    path: '/search/feed-bedding',
    icon: faWheat,
    isHiddenFromDesktopNav: true
  },
  // {
  //   label: 'Shop',
  //   key: 'shop',
  //   path: '/shop',
  //   icon: faShoppingCart,
  //   isHiddenFromDesktopNav: true
  // },
  {
    label: 'Live Auctions & Events',
    key: 'events',
    path: '/events',
    icon: faGavel,
    isHiddenFromDesktopNav: true
  },
  {
    label: 'Our Partners',
    key: 'partners',
    children: [],
    hasDynamicChildren: true,
    icon: faAward,
    isHiddenFromDesktopNav: true
  }
]

export const topLevelAltNavigationItems = [
  // {
  //   label: 'My Interests',
  //   shortLabel: 'Interests',
  //   key: 'interests',
  //   path: '/account/settings/interests',
  //   icon: faBookmark
  // },
  {
    label: 'My Watchlist',
    shortLabel: 'Watchlist',
    key: 'watchlist',
    path: '/account/listings/saved/watchlist',
    icon: faStar
  },
  {
    label: 'Recently Viewed',
    shortLabel: 'Recently Viewed',
    key: 'recently-viewed',
    path: '/account/listings/saved/viewed',
    icon: faHistory,
    isHiddenFromDesktopNav: true
  }
]

export const megaMenuItems = {
  listings: [
    {
      label: 'Cattle',
      key: 'cattle',
      children: [
        {
          label: 'Calves',
          key: 'calves',
          path: '/calves',
          children: [
            {
              label: 'Beef',
              key: 'calves-beef',
              path: '/livestock/popular/beef-calves'
            },
            {
              label: 'Dairy Cross',
              key: 'calves-dairy-cross',
              path: '/livestock/popular/dairy-cross-calves'
            },
            {
              label: 'Dairy',
              key: 'calves-dairy',
              path: '/livestock/popular/dairy-calves'
            },
            {
              label: 'View all Calves',
              key: 'calves-all',
              path: '/calves'
            }
          ]
        },
        {
          label: 'Breeding',
          key: 'breeding-cattle',
          path: '/breeding-cattle',
          children: [
            {
              label: 'Bulls',
              key: 'breeding-cattle-bulls',
              path: '/search/breeding-cattle?sexes=Bulls'
            },
            {
              label: 'Heifers',
              key: 'breeding-cattle-heifers',
              path: '/search/breeding-cattle?sexes=Heifers'
            },
            {
              label: 'Cows',
              key: 'breeding-cattle-cows',
              path: '/search/breeding-cattle?sexes=Cows'
            },
            {
              label: 'View all Breeding Cattle',
              key: 'breeding-cattle-all',
              path: '/breeding-cattle'
            }
          ]
        },
        {
          label: 'Store',
          key: 'store-cattle',
          path: '/store-cattle',
          children: [
            {
              label: 'Steers',
              key: 'store-cattle-steers',
              path: '/search/store-cattle?sexes=Steers'
            },
            {
              label: 'Heifers',
              key: 'store-cattle-heifers',
              path: '/search/store-cattle?sexes=Heifers'
            },
            {
              label: 'Bulls',
              key: 'store-cattle-bulls',
              path: '/search/store-cattle?sexes=Bulls'
            },
            {
              label: 'View all Store Cattle',
              key: 'store-cattle-all',
              path: '/store-cattle'
            }
          ]
        },
        {
          label: 'Dairy',
          key: 'dairy-cattle',
          path: '/dairy-cattle',
          children: [
            {
              label: 'Cows',
              key: 'dairy-cattle-cows',
              path: '/search/dairy-cattle?sexes=Cows'
            },
            {
              label: 'In-Calf Heifers',
              key: 'dairy-cattle-in-calf-heifers',
              path: '/search/dairy-cattle?sexes=Heifers&pregnant=true'
            },
            {
              label: 'Heifers',
              key: 'dairy-cattle-heifers',
              path: '/search/dairy-cattle?sexes=Heifers'
            },
            {
              label: 'View all Dairy Cattle',
              key: 'dairy-cattle-all',
              path: '/dairy-cattle'
            }
          ]
        },
        {
          label: 'Breed Societies',
          key: 'breed-societies-cattle',
          path: '/livestock?species=Cattle',
          children: [
            {
              label: 'British White',
              key: 'breed-societies-cattle-british-white',
              path: '/portal/britishwhite'
            },
            {
              label: 'British Bazadaise',
              key: 'breed-societies-cattle-british-bazadaise',
              path: '/portal/bazadaise'
            },
            {
              label: 'Hereford',
              key: 'breed-societies-cattle-hereford',
              path: '/portal/hereford'
            },
            {
              label: 'Stabiliser',
              key: 'breed-societies-cattle-stabiliser',
              path: '/portal/stabiliser'
            }
          ]
        },
        {
          label: 'View all cattle',
          key: 'all-cattle',
          path: '/livestock?species=Cattle',
          isViewAllLink: true
        }
      ]
    },
    {
      label: 'Sheep',
      key: 'sheep',
      path: '/sheep',
      children: [
        {
          label: 'Breeding',
          key: 'breeding-sheep',
          path: '/sheep?types=Breeding',
          children: [
            {
              label: 'Rams',
              key: 'breeding-sheep-rams',
              path: '/sheep?sexes=Rams&types=Breeding'
            },
            {
              label: 'Ewes',
              key: 'breeding-sheep-ewes',
              path: '/sheep?sexes=Ewes&types=Breeding'
            },
            {
              label: 'Shearlings/Gimmers',
              key: 'breeding-sheep-shearlings-gimmers',
              path: '/sheep?sexes=Shearlings~Gimmers&types=Breeding'
            },
            {
              label: 'Lambs',
              key: 'breeding-sheep-lambs',
              path: '/sheep?sexes=Lambs&types=Breeding'
            },
            {
              label: 'View all Breeding Sheep',
              key: 'breeding-sheep-all',
              path: '/sheep?types=Breeding'
            }
          ]
        },
        {
          label: 'Store',
          key: 'store-sheep',
          path: '/sheep?types=Store',
          children: [
            {
              label: 'Rams',
              key: 'store-sheep-rams',
              path: '/sheep?sexes=Rams&types=Store'
            },
            {
              label: 'Ewes',
              key: 'store-sheep-ewes',
              path: '/sheep?sexes=Ewes&types=Store'
            },
            {
              label: 'Hoggs',
              key: 'store-sheep-hoggs',
              path: '/sheep?sexes=Hoggs&types=Store'
            },
            {
              label: 'Lambs',
              key: 'store-sheep-lambs',
              path: '/sheep?sexes=Wethers&types=Store'
            },
            {
              label: 'View all Store Sheep',
              key: 'store-sheep-all',
              path: '/sheep?types=Store'
            }
          ]
        },
        {
          label: 'Breed Societies',
          key: 'breed-societies-sheep',
          path: '/sheep',
          children: [
            {
              label: 'Innovis',
              key: 'breed-societies-sheep-innovis',
              path: '/portal/innovis'
            },
            {
              label: 'Lleyn',
              key: 'breed-societies-sheep-lleyn',
              path: '/portal/lleyn'
            },
            {
              label: 'Maternal Sheep Group',
              key: 'breed-societies-sheep-maternal-sheep-group',
              path: '/portal/maternalsheepgroup'
            },
            {
              label: 'Meatlinc',
              key: 'breed-societies-sheep-meatlinc',
              path: '/portal/meatlinc'
            },
            {
              label: 'Prohill',
              key: 'breed-societies-sheep-prohill',
              path: '/portal/prohill'
            },
            {
              label: 'Shropshire',
              key: 'breed-societies-sheep-shropshire',
              path: '/portal/shropshire'
            },
            {
              label: 'Texel',
              key: 'breed-societies-sheep-texel',
              path: '/portal/texel'
            },
            {
              label: 'Valais Blacknose',
              key: 'breed-societies-sheep-valaisblacknose',
              path: '/portal/valaisblacknose'
            }
          ]
        },
        {
          label: 'View all sheep',
          key: 'all-sheep',
          path: '/sheep',
          isViewAllLink: true
        }
      ]
    },
    {
      label: 'Pigs',
      key: 'pigs',
      path: '/pigs'
    },
    {
      label: 'Goats',
      key: 'goats',
      path: '/goats',
      children: [
        {
          label: 'Breeding',
          key: 'breeding-goats',
          path: '/goats?types=Breeding',
          children: [
            {
              label: 'Nannies/Does',
              key: 'breeding-goats-nannies-and-does',
              path: '/goats?sexes=Does&types=Breeding'
            },
            {
              label: 'Yearlings',
              key: 'breeding-goats-yearlings',
              path: '/goats?sexes=Yearlings&types=Breeding'
            },
            {
              label: 'Kids',
              key: 'breeding-goats-kids',
              path: '/goats?sexes=Kids&types=Breeding'
            },
            {
              label: 'View all Breeding Goats',
              key: 'breeding-goats-all',
              path: '/goats?types=Breeding'
            }
          ]
        },
        {
          label: 'Meat',
          key: 'meat-goats',
          path: '/goats?types=Meat',
          children: [
            {
              label: 'Wethers',
              key: 'meat-goats-wethers',
              path: '/goats?sexes=Wethers&types=Meat'
            },
            {
              label: 'Bucks',
              key: 'meat-goats-bucks',
              path: '/goats?sexes=Bucks&types=Meat'
            },
            {
              label: 'Yearlings',
              key: 'meat-goats-yearlings',
              path: '/goats?sexes=Yearlings&types=Meat'
            },
            {
              label: 'Kids',
              key: 'meat-goats-kids',
              path: '/goats?sexes=Kids&types=Meat'
            },
            {
              label: 'View all Meat Goats',
              key: 'meat-goats-all',
              path: '/goats?types=Meat'
            }
          ]
        },
        {
          label: 'Dairy',
          key: 'dairy-goats',
          path: '/goats?types=Dairy',
          children: [
            {
              label: 'Nannies/Does',
              key: 'dairy-goats-nannies-and-does',
              path: '/goats?sexes=Does&types=Dairy'
            },
            {
              label: 'Yearlings',
              key: 'dairy-goats-yearlings',
              path: '/goats?sexes=Yearlings&types=Dairy'
            },
            {
              label: 'Kids',
              key: 'dairy-goats-kids',
              path: '/goats?sexes=Kids&types=Dairy'
            },
            {
              label: 'Bucks',
              key: 'dairy-goats-bucks',
              path: '/goats?sexes=Bucks&types=Dairy'
            },
            {
              label: 'View all Dairy Goats',
              key: 'dairy-goats-all',
              path: '/goats?types=Dairy'
            }
          ]
        },
        {
          label: 'View all goats',
          key: 'all-goats',
          path: '/goats',
          isViewAllLink: true
        }
      ]
    },
    {
      label: 'Breed Societies',
      key: 'breed-societies',
      children: [],
      hasDynamicChildren: true,
      isHiddenFromMobileSearchNav: true
    },
    {
      label: 'Feed & Bedding',
      key: 'feed-bedding',
      path: '/search/feed-bedding'
    },
    {
      label: 'Working Dogs',
      key: 'working-dogs',
      path: '/search/working-dogs'
    }
    // {
    //   label: 'Semen & Embryos',
    //   key: 'semen',
    //   path: '/shop',
    //   children: [
    //     {
    //       label: 'Semen',
    //       key: 'semen',
    //       path: '/shop?subCategory=Genetics%20>%20Semen',
    //       children: [
    //         {
    //           label: 'Limousin',
    //           key: 'semen-limousin',
    //           path: '/shop?breed=Limousin'
    //         },
    //         {
    //           label: 'Angus',
    //           key: 'semen-angus',
    //           path: '/shop?breed=Aberdeen%20Angus'
    //         },
    //         {
    //           label: 'Beef Shorthorn',
    //           key: 'semen-beef-shorthorn',
    //           path: '/shop?breed=Beef%20Shorthorn'
    //         },
    //         {
    //           label: 'British Blue',
    //           key: 'semen-british-blue',
    //           path: '/shop?breed=British%20Blue'
    //         },
    //         {
    //           label: 'Charolais',
    //           key: 'semen-charolais',
    //           path: '/shop?breed=Charolais'
    //         },
    //         {
    //           label: 'Hereford',
    //           key: 'semen-hereford',
    //           path: '/shop?breed=Hereford'
    //         },
    //         {
    //           label: 'Simmental',
    //           key: 'semen-simmental',
    //           path: '/shop?breed=Simmental'
    //         },
    //         {
    //           label: 'Dexter',
    //           key: 'semen-dexter',
    //           path: '/shop?breed=Dexter'
    //         }
    //       ]
    //     },
    //     {
    //       label: 'View all Semen & Embryos',
    //       key: 'all-semen-embryos',
    //       path: '/shop?subCategory=Genetics%20>%20Semen~Genetics%20>%20Embryo',
    //       isViewAllLink: true
    //     }
    //   ]
    // }
    // {
    //   label: 'Shop',
    //   key: 'shop',
    //   path: '/shop'
    // }
  ],
  martsAndPartners: [
    {
      label: 'Breed Societies',
      key: 'breed-societies',
      children: [],
      hasDynamicChildren: true,
      isHiddenFromMobileSearchNav: true
    },
    {
      label: 'Supply Chain & Agents',
      key: 'agents',
      children: [],
      hasDynamicChildren: true,
      isHiddenFromMobileSearchNav: true
    },
    {
      label: 'Marts',
      key: 'marts',
      children: [],
      hasDynamicChildren: true,
      isHiddenFromMobileSearchNav: true
    },
    {
      label: 'Sales & Events',
      key: 'events',
      path: '/events',
      children: [
        {
          label: 'Upcoming Events',
          key: 'upcoming-events',
          path: 'events',
          children: []
        },
        {
          label: 'View all events',
          key: 'all-events',
          path: '/events',
          isViewAllLink: true
        }
      ],
      hasDynamicChildren: true
    }
  ]
}
