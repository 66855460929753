export default {
  REGISTERED: 'registered',
  INVALID: 'invalid',
  VALID: 'valid',
  REQUESTED: 'requested',
  INITIATED: 'initiated',
  APPROVED: 'approved',
  FINISHED: 'finished',
  OWNED: 'owned',
  DOWNLOADING: 'downloading',
  DOWNLOADED: 'downloaded'
}
