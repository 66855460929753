import Vue from 'vue'

// The reason we use our own logging function is to perform additional
// calls like logging to Sentry and in the future logging to our analytics db
export class Log {
  error(message, error) {
    console.error(message, error)

    if (process.env.VUE_APP_REPORT_ERRORS === 'true') {
      const Sentry = require('@sentry/browser')
      Sentry.captureException(error)
    }
  }

  debug(message, data) {
    if (typeof data === 'undefined') {
      console.log(message)
    } else {
      console.log(message, data)
    }
  }

  warn(message, data) {
    if (typeof data === 'undefined') {
      console.warn(message)
    } else {
      console.warn(message, data)
    }
  }
}

const logPlugin = {
  install(vm) {
    vm.prototype.$log = new Log()
  }
}

Vue.use(logPlugin)

export default new Log()
