import store from '@/store'

const brochurewareRoutes = [
  {
    path: '/',
    alias: '/index.html', // Handles PWA start page
    // Do not rename this route
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),

    beforeEnter(to, from, next) {
      // While we want to redirect people immediately to dashboard if they are logged in
      // Let's allow them to see the homepage if they click the logo
      if (typeof to.query.logo !== 'undefined') {
        return next()
      }

      // Was this user previously authenticated? Let's redirect to dashboard.
      // We can't afford to wait for verification however
      if (store.state.auth.token) {
        next('/account/dashboard')
      } else {
        next()
      }
    }
  },
  {
    path: '/view/listing/:listingCode',
    name: 'listing', // Do not change this, used in LayoutNavMobile.vue
    component: () => import(/* webpackChunkName: "listing" */ '@/views/Listing.vue')
  },
  {
    // Legacy URL support
    path: '/livestock/for-sale/:listingCode',
    alias: '/livestock/wanted/:listingCode',
    name: 'listing-legacy', // Do not change this, used in LayoutNavMobile.vue
    component: () => import(/* webpackChunkName: "listing" */ '@/views/Listing.vue')
  },
  {
    path: '/secure-payments',
    redirect: '/',
    alias: '/payments',
    name: 'secure-payments',
    component: () => import(/* webpackChunkName: "listing" */ '@/views/SecurePayments.vue'),
    meta: {
      title: 'Secure Payments',
      description:
        'Secure Payments are a safe and secure solution for buying and selling your assets. We verify and protect all our users to prevent fraud. So you can trade how you want, with who you want and with total confidence.'
    }
  },
  {
    path: '/blog',
    name: 'blog',
    meta: {
      title: 'Blog',
      description:
        "Read about what's happening at SellMyLivestock. Not just about our news but guest posts from our a wide range of farm businesses."
    },
    component: () => import(/* webpackChunkName: "blog" */ '@/views/Blog.vue'),
    children: [
      {
        path: 'category/:categorySlug',
        name: 'blog-category',
        component: () => import(/* webpackChunkName: "blog" */ '@/views/Blog.vue')
      }
    ]
  },
  {
    path: '/blog/:postSlug',
    name: 'blog-post',
    component: () => import(/* webpackChunkName: "blog-post" */ '@/views/BlogPost.vue')
  },
  {
    path: '/help',
    alias: '/contact',
    name: 'help',
    meta: {
      title: 'Help & Information',
      description:
        'Get Help & Information for our all our services in including FarmPay, Secure Payments, Haulage and Finance'
    },
    component: () => import(/* webpackChunkName: "help" */ '@/views/Help.vue'),
    children: [
      {
        path: 'how-it-works',
        name: 'help-how-it-works',
        component: () => import(/* webpackChunkName: "help" */ '@/views/Help.vue'),
        meta: {
          title: 'How It Works',
          description:
            "Find out how SellMyLivestock works. We'll run you through step-by-step how you buy and sell using our service."
        }
      },
      {
        path: 'faqs/:questionSlug?',
        name: 'help-faqs',
        component: () => import(/* webpackChunkName: "help" */ '@/views/Help.vue'),
        meta: {
          title: 'Frequently Asked Questions',
          titleShort: 'FAQs',
          description: 'Get answers to frequently asked questions about SellMyLivestock and our service'
        }
      },
      {
        path: 'guides',
        name: 'help-guides',
        component: () => import(/* webpackChunkName: "help" */ '@/views/Help.vue'),
        meta: {
          title: 'Guides & How-To',
          description:
            'View more indepth guides and videos on how to post a listing on SellMyLivestock and much more'
        }
      },
      {
        path: 'farmpay',
        name: 'help-farmpay',
        component: () => import(/* webpackChunkName: "help" */ '@/views/Help.vue'),
        meta: {
          title: 'FarmPay Services',
          description: 'Help & Information for our FarmPay services including Secure Payments, Haulage and Finance'
        }
      }
    ]
  },
  {
    path: '/events',
    alias: '/auctions',
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '@/views/Events.vue'),
    meta: {
      title: 'Live Auctions & Events',
      description: "Online Livestock Auctions & Events by Europe's largest online livestock marketplace"
    }
  },
  {
    path: '/events/:eventRef',
    name: 'event-detail',
    component: () => import(/* webpackChunkName: "event-detail" */ '@/views/EventDetail.vue')
  },
  {
    path: '/portal',
    alias: '/portals',
    name: 'portal-landing',
    component: () => import(/* webpackChunkName: "portal-landing" */ '@/views/PortalLanding.vue')
  },
  {
    path: '/portal/:portalSlug/:portalTab?',
    name: 'portal',
    component: () => import(/* webpackChunkName: "portal" */ '@/views/Portal.vue')
  },
  {
    path: '/profile/:userFullName?/:userRef?',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
  },
  {
    path: '/info/:pageSlug',
    name: 'landing-page',
    component: () => import(/* webpackChunkName: "landing-page" */ '@/views/LandingPage.vue')
  },
  {
    path: '/upgrade/:context?',
    name: 'upgrade',
    component: () => import(/* webpackChunkName: "upgrade" */ '@/views/Upgrade.vue')
  },
  {
    path: '/app-download',
    name: 'app-download',
    component: () => import(/* webpackChunkName: "app-download" */ '@/views/AppDownload.vue'),
    meta: {
      title: 'Download our mobile apps',
      description: 'Use SellMyLivestock on the go with our dedicated iOS and Android mobile apps.'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
    meta: {
      description: 'Find out all about SellMyLivestock. All about how it came to be and the team behind it.'
    }
  },
  {
    path: '/matchmaking',
    redirect: '/'
  },
  {
    path: '/haulage',
    redirect: '/',
    name: 'haulage',
    component: () => import(/* webpackChunkName: "haulage" */ '@/views/Haulage.vue'),
    meta: {
      title: 'Haulage'
    }
  },
  {
    path: '/membership-plans',
    name: 'membership-plans',
    component: () => import(/* webpackChunkName: "premium-membership" */ '@/views/PremiumMembership.vue'),
    meta: {
      title: 'Membership Plans'
    }
  },
  {
    path: '/premium-membership',
    redirect: '/membership-plans'
  },
  {
    path: '/manage-cookies',
    name: 'manage-cookies',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ManageCookies.vue'),
    meta: {
      title: 'Manage Cookies',
      description: 'Manage your cookie preferences on SellMyLivestock.com'
    }
  },
  {
    path: '/terms/:type?',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/Terms.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/Policy.vue')
  },
  {
    path: '/sitemap/:commodity?',
    name: 'sitemap',
    component: () => import(/* webpackChunkName: "sitemap" */ '@/views/Sitemap.vue')
  }
]

export default brochurewareRoutes
