<template>
  <!-- Main top nav which shows on mobile -->
  <div class="bg-brand-darker">
    <nav class="flex items-center navbar px-4 py-2 border-b border-brand-lighter">
      <div class="flex justify-between items-center w-full">
        <UtilTextLink
          v-if="appIs.auth && !appIs.firstLoad"
          @click="goBack()"
          class="text-white no-underline flex items-center cursor-pointer"
        >
          <FontAwesomeIcon :icon="faChevronLeft" fixed-width class="text-primary-button mr-1" />
          <span>{{ $t('nav.back') }}</span>
        </UtilTextLink>

        <RouterLink v-else to="/?logo=header" class="logo-link select-none flex">
          <img
            @load="logoLoaded = true"
            alt="SML logo"
            src="@/assets/images/cached/cached-logo-green-icon.svg"
            class="logo block"
          />
        </RouterLink>

        <UtilTextLink
          v-if="appIs.auth"
          @click="$scroll.toTop()"
          textLink
          color="white"
          class="title truncate p-0 text-white no-underline cursor-pointer text-center capitalize"
          style="width: 45%"
        >
          <span>{{ pageTitle }}</span>
        </UtilTextLink>

        <div class="flex">
          <div v-if="!appIs.auth" class="flex">
            <UtilTextLink
              @click="$modal.open('ModalAuthRegister')"
              class="text-white no-underline flex items-center mr-4 cursor-pointer"
            >
              <FontAwesomeIcon :icon="faUser" class="text-primary-button mr-1" />
              {{ $t('auth.register') }}
            </UtilTextLink>

            <UtilTextLink
              @click="
                $store.dispatch('modal/showModal', {
                  modalName: 'ModalAuthLogin',
                  modalData: { redirectToDashboard: false }
                })
              "
              class="text-white no-underline flex items-center mr-4 cursor-pointer"
            >
              <FontAwesomeIcon :icon="faUser" class="text-primary-button mr-1" />
              {{ $t('auth.login') }}
            </UtilTextLink>
          </div>

          <UtilTextLink
            @click="$refs.mobileMenu.openMenu()"
            class="text-white no-underline flex items-center cursor-pointer"
          >
            <FontAwesomeIcon :icon="faBars" fixed-width class="text-primary-button mr-1" />
            <span>{{ $t('nav.menu') }}</span>
          </UtilTextLink>
        </div>
      </div>

      <LayoutMobileMenu ref="mobileMenu" :isOpen.sync="compMobileMenuOpen" />
    </nav>
  </div>
</template>

<script>
  import {faBars, faChevronLeft} from '@fortawesome/pro-regular-svg-icons'
  import {faUser} from '@fortawesome/pro-solid-svg-icons'
  import LayoutMobileMenu from '@/components/layout/navigation/LayoutMobileMenu'
  import UtilTextLink from '@/components/utils/UtilTextLink'

  export default {
  name: 'LayoutNavMobile',
  components: {
    LayoutMobileMenu,
    UtilTextLink
  },
  data() {
    return {
      logoLoaded: false,
      flagLoaded: false,
      pageTitle: '',
      faChevronLeft,
      faBars,
      faUser
    }
  },
  computed: {
    compMobileMenuOpen() {
      return this.$store.state.app.mobileMenuOpen
    }
  },
  watch: {
    $route() {
      // We don't have a proper way of accessing vue-head so let's wait for a route
      // change, delay a bit for dynamic compTitles to kick in, then get the page title
      setTimeout(() => this.setPageTitle(), 100)
    }
  },
  methods: {
    setPageTitle() {
      // We don't want long listing names trying to appear in header
      if (this.$route.name === 'listing' || this.$route.name === 'listing-legacy') {
        this.pageTitle = this.$t('titles.viewListing')
        return
      }

      if (this.$route.name === 'home') {
        this.pageTitle = 'SellMyLivestock'
        return
      }

      if (this.$route.meta.title) {
        this.pageTitle =
          this.$breakpoint.sm && this.$route.meta.titleShort ? this.$route.meta.titleShort : this.$route.meta.title
      } else if (this.$route.meta.childCommodity) {
        this.pageTitle = this.$route.meta.childCommodity.replace('-', ' ')
      } else {
        let title = document.title.split('|')
        this.pageTitle = title.length > 1 ? title.shift() : ''
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style>
.dev-banner {
  @apply text-white tracking-wide absolute rounded text-xs font-bold flex items-center;
  bottom: -4px;
  right: 0;
  z-index: 2;
}

.logo-link:focus {
  box-shadow: none;
}

.logo {
  height: 36px;
}
</style>
