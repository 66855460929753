// Return initials of a name. If only word provided then use first 2 letters.

const getInitialsFromName = name => {
  return name
    .trim()
    .split(' ')
    .reduce(
      (acc, cur, index, arr) =>
        acc +
        (arr.length > 1
          ? index == 0 || index == arr.length - 1
            ? cur.substring(0, 1)
            : ''
          : cur.substring(0, 2)),
      ''
    )
    .toUpperCase()
}

export default getInitialsFromName
