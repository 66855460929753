export default function () {
  return {
    estimate: '', // Complete
    product: '',
    quantity: '1',
    weight: '', // Complete
    notes: '', // Complete
    points: [
      {
        contact: null,
        geo: {
          address: null,
          building: null,
          line1: null,
          line2: null,
          locality: null,
          region: null,
          code: null,
          country: null,
          coordinates: [null, null]
        },
        notes: null,
        distance: 0,
        flags: { weighbridge: 0 }, // weighbridge always set to 0 for collection, bedding is not set
        date: [null, null]
      },
      {
        contact: null,
        geo: {
          address: null,
          building: null,
          line1: null,
          line2: null,
          locality: null,
          region: null,
          code: null,
          country: null,
          coordinates: [null, null]
        },
        notes: '',
        distance: 0,
        flags: { weighbridge: 0, bedding: 0 }, // weighbridge deafult is 0, bedding not set, but lets set it here by default to 0. If true it's set as Bool not number
        date: [null, null]
      }
    ],
    flags: { carbonOffset: 0 }, // Complete
    rejected: 0,
    rejectedReason: ''
  }
}
