<template>
  <div class="tag" @click="$emit('click')" :class="{ medium }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UtilTag',
  props: {
    medium: Boolean
  }
}
</script>

<style lang="scss" scoped>
.tag {
  @apply rounded px-1 text-xs font-medium uppercase select-none;

  & + .tag {
    @apply ml-2;
  }
}

.tag.medium {
  @apply px-4 py-1;
}
</style>
