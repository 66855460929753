<template>
  <RouterLink v-if="to" :to="to" @click.native="$emit('click')" class="text-link cursor-pointer">
    <slot />
  </RouterLink>

  <a v-else @click="$emit('click')" class="text-link cursor-pointer">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'UtilTextLink',
  props: {
    to: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.text-link {
  transition: all 0.2s ease-in;
}

.text-link:not(.no-underline) {
  text-decoration: underline;
}

.text-link:hover {
  opacity: 0.8;
}
</style>
