import Vue from 'vue'

const mixins = {
  computed: {
    app() {
      return this.$store.getters['app/getApp']
    },

    appIs() {
      return this.$store.getters['app/appIs']
    },

    assetBaseUrl() {
      let s3BucketPostfix = ''

      if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
        s3BucketPostfix = '-staging'
      }

      if (['development', 'local'].includes(process.env.VUE_APP_ENVIRONMENT)) {
        s3BucketPostfix = '-dev'
      }

      return {
        file: `${process.env.VUE_APP_ASSETS_BASE_URL}/hec-files${s3BucketPostfix}/`,
        image: `${process.env.VUE_APP_ASSETS_BASE_URL}/hec-images${s3BucketPostfix}/`,
        imageThumbnail: `${process.env.VUE_APP_ASSETS_BASE_URL}/hec-images${s3BucketPostfix}/hc_`,
        imageTransform: `${process.env.VUE_APP_IMGIX_BASE_URL}/`,
        video: `${process.env.VUE_APP_S3_BASE_URL}/hec-videos${s3BucketPostfix}/`,
        advert: `${process.env.VUE_APP_ASSETS_BASE_URL}/hec-adverts${s3BucketPostfix}/`,
        hostedPage: process.env.VUE_APP_HOSTED_PAGES_BASE_URL
      }
    }
  }
}

Vue.mixin(mixins)
