<template>
  <div class="divider">
    <span v-if="title" class="title" :class="titleClass">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'UtilDivider',
  props: {
    title: [String, Number],
    titleClass: {
      type: String,
      default: 'bg-white uppercase px-1'
    }
  }
}
</script>

<style lang="scss" scoped>
.divider {
  @apply border-t border-gray-lighter;
  height: 1px;
  position: relative;
}

[class='divider'] {
  @apply my-2;
}

.title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
