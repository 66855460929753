<template>
  <div class="select-none" :class="{ 'flex flex-col h-full': fullHeight }">
    <RouterLink
      v-for="(item, i) in compNav"
      :to="item.to"
      @click.native="
        () => {
          if (item.click) {
            item.click()
          }

          $emit('click')
        }
      "
      :key="`item-${i}`"
      class="account-nav-link"
      :exact="item.exactUrlMatch"
      :class="[
        linkClass,
        `account-${item.text.toLowerCase()}`,
        { 'flex flex-col justify-center items-start flex-grow text-lg border-b border-gray-lighter': fullHeight }
      ]"
    >
      <span v-if="showActiveIndicator" class="account-nav-indicator"></span>

      <span class="account-nav-text" :class="{ 'h-full self-start': fullHeight }">
        <FontAwesomeIcon :icon="item.icon" fixed-width class="account-nav-icon" />

        <span class="relative">
          {{ item.text }}
        </span>

        <span v-if="item.new" class="new-tag ml-2">NEW</span>
      </span>

      <UtilBadgeCount
        v-if="item.badge"
        :count="item.badge < 10 ? item.badge : '9+'"
        unread
        class="account-nav-badge"
      />
    </RouterLink>
  </div>
</template>

<script>
  import {
    faCog,
    faCow,
    faEnvelope,
    faHandSparkles,
    faList,
    faMedal,
    faSignOutAlt,
    faStar,
    faStore,
    faThumbsUp,
    faUsersClass
  } from '@fortawesome/pro-regular-svg-icons'
  import {mapGetters} from 'vuex'
  import UtilBadgeCount from '@/components/utils/UtilBadgeCount'

  export default {
  name: 'LayoutAccountNav',
  components: {
    UtilBadgeCount
  },
  props: {
    showActiveIndicator: {
      type: Boolean,
      default: true
    },
    linkClass: {
      type: String,
      default: 'px-10 py-3'
    },
    fullHeight: Boolean
  },
  computed: {
    ...mapGetters({
      dismissedMessages: 'storage/dismissedMessages'
    }),

    compNav() {
      let compNav = [
        {
          text: 'Dashboard',
          to: '/account/dashboard',
          icon: faUsersClass,
          badge: 0,
          active: true
        },
        {
          text: 'Membership',
          to: '/account/settings/membership',
          icon: faMedal,
          badge: 0,
          active: true
        },
        {
          text: 'Messages',
          to: '/account/messages',
          icon: faEnvelope,
          badge: this.app.unread.messages,
          active: true
        },
        {
          text: 'Listings',
          to: '/account/listings',
          icon: faList,
          badge: this.app.unread.listings,
          exactUrlMatch: true,
          active: true
        },
        { text: 'My Herd', to: '/account/herd', icon: faCow, badge: 0, active: false },
        {
          text: 'Offers',
          to: '/account/offers',
          icon: faHandSparkles,
          badge: this.app.unread.bids,
          active: false
        },
        // {
        //   text: 'Payments',
        //   to: '/account/payments',
        //   icon: faHandshake,
        //   badge: this.app.unread.payments,
        //   active: true,
        //   // new: !this.dismissedMessages.includes('account-nav-new-payments'),
        //   click: !this.dismissedMessages.includes('account-nav-new-payments')
        //     ? () => this.$store.dispatch('storage/dismissMessage', 'account-nav-new-payments')
        //     : undefined
        // },
        // {
        //   text: 'Haulage',
        //   to: '/account/haulage',
        //   icon: faTruck,
        //   badge: this.app.unread.haulage,
        //   active: true,
        //   // new: !this.dismissedMessages.includes('account-nav-new-haulage'),
        //   click: !this.dismissedMessages.includes('account-nav-new-haulage')
        //     ? () => this.$store.dispatch('storage/dismissMessage', 'account-nav-new-haulage')
        //     : undefined
        // },
        // {
        //   text: 'Finance',
        //   to: '/account/finance',
        //   icon: faSeedling,
        //   badge: this.app.unread.finance,
        //   active: true
        // },
        {
          text: 'Reviews',
          to: '/account/reviews',
          icon: faThumbsUp,
          badge: this.app.unread.reviews,
          active: true
        },
        {
          text: 'Watchlist',
          to: '/account/listings/saved',
          icon: faStar,
          badge: 0,
          active: true
        },
        // { text: 'Products', to: '/account/products', icon: faGift, badge: this.app.unread.products, active: true },
        { text: 'Portals', to: '/account/portals', icon: faStore, badge: 0, active: this.appIs.portalOwner },
        { text: 'Settings', to: '/account/settings', icon: faCog, badge: this.app.unread.settings, active: true },
        { text: 'Logout', to: '/logout', icon: faSignOutAlt, badge: 0, active: true }
      ]

      return compNav.filter(o => o.active)
    }
  }
}
</script>

<style lang="scss" scoped>
.account-nav {
  @apply mr-3;
  width: 280px;
}

.account-nav-link {
  @apply flex relative;
  padding-right: 35px;

  &:hover {
    color: #000;
  }

  &.router-link-active {
    @apply text-brand font-semibold;

    .account-nav-indicator {
      @apply visible;
    }
  }
}

.account-nav-indicator {
  width: 0.35rem;
  @apply absolute invisible bg-brand;
  left: -15px;
  top: 5px;
  bottom: 5px;
}

.account-nav-text {
  @apply flex items-center;
}

.account-nav-badge {
  @apply absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#app.mdAndDown .account-nav-badge {
  right: 60px;
}

.account-nav-icon {
  @apply mr-4 text-primary-lighter text-lg;
}

.account-nav-link.router-link-active .account-nav-icon {
  @apply text-primary-darker;
}
</style>
