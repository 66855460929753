import { platform } from '@/plugins/native-app/capacitor'
import { Keyboard } from '@capacitor/keyboard'
import store from '@/store'

if (platform === 'ios') {
  // Don't hide the "done/close" keyboard bar on iOS
  Keyboard.setAccessoryBarVisible({ isVisible: true })

  Keyboard.addListener('keyboardWillShow', () => {
    store.commit('device/setNativeKeyboardVisible', true)
  })

  Keyboard.addListener('keyboardWillHide', () => {
    store.commit('device/setNativeKeyboardVisible', false)
  })
}
