import LegacyApi from '@/lib/api/legacy'

const STORAGE_KEY = 'sml-stats'
const existingData = window.localStorage[STORAGE_KEY] || null

const initialState = () => {
  return new LegacyApi().hydrate(JSON.parse(existingData))
}

const getters = {
  getLivestockTotal: state => {
    if (!state.data.quantityTotals) {
      return 0
    }

    let livestockStat = state.data.quantityTotals.find(stat => stat.commodity === 'Livestock')

    return livestockStat.total
  },

  getUserTotal: state => {
    if (!state.data.usersTotals) {
      return 0
    }

    return state.data.usersTotals.total
  }
}

const actions = {
  async getStats({ state }) {
    await state.post('app.stats', state.model)
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state.data))
  }
}

const mutations = {
  reset(state) {
    console.log('Resetting', STORAGE_KEY)
    window.localStorage.removeItem(STORAGE_KEY)
    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
}
