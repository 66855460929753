export default function (user = {}) {
  if (user.emailAddresses) {
    if (user.emailAddresses.length) {
      user.emailAddress = user.emailAddresses[0].emailAddress
    }
  }
  if (user.landlines) {
    if (user.landlines.length) {
      user.landline = user.landlines[0].number
    }
  }
  if (user.mobiles) {
    if (user.mobiles.length) {
      user.mobile = user.mobiles[0].number
    }
  }

  return {
    userRef: user.userRef || undefined,
    countryId: user.countryId,
    title: user.title || 'Mr',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    emailAddress: user.emailAddress || undefined,
    landline: user.landline || undefined,
    mobile: user.mobile || undefined,
    picFileRef: user.picFileRef || '',
    dateOfBirth: user.dateOfBirth || '',
    subscriptionLevel: user.subscriptionLevel || undefined,
    subscriptionPlan: user.subscriptionPlan || undefined,
    verified: user.verified || 0
  }
}
