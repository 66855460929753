import Vue from 'vue'
import cookie from 'js-cookie'
import log from '@/plugins/log'

export const STORAGE_KEY = 'sml-locale'
const COUNTRY_KEY = 'sml-country-code'
const defaultLocale = 'en-GB'
const availableLocales = ['en-GB', 'cy-GB']

const initialState = () => {
  return {
    language: window.localStorage[STORAGE_KEY] || null,
    countryCode: cookie.get(COUNTRY_KEY) || 'GB'
  }
}

const actions = {
  setLanguage({ commit, dispatch }, language) {
    // log.debug('Trying to set language', language)

    if (!availableLocales.includes(language)) {
      log.debug('Setting default locale, requested not available', language)

      language = defaultLocale
    }

    dispatch('tagLanguage')

    Vue.prototype.$date.setLocale(language)

    commit('setLanguage', language)
  },

  tagLanguage({ state }) {
    Vue.prototype.$analytics.setDimension('chosenLocale', state.language)

    if (process.env.VUE_APP_REPORT_ERRORS === 'true') {
      const Sentry = require('@sentry/browser')

      Sentry.configureScope(scope => {
        scope.setTag('app-locale', state.language)
      })
    }
  }
}

const mutations = {
  setLanguage(state, language) {
    state.language = language
    window.localStorage.setItem(STORAGE_KEY, language)
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations
}
