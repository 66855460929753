<template>
  <div class="avatar" :class="{ 'extra-large': extraLarge, large, medium, premium }" @click="$emit('click')">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UtilAvatar',
  props: {
    extraLarge: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    premium: Boolean
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  @apply w-10 h-10 text-2xl;
  background-color: #b6b5cc;
  border-radius: 50%;
  display: inline-block;
  font-weight: 300;
  position: relative;
  vertical-align: middle;
}

.avatar.medium {
  @apply w-16 h-16 text-3xl;
}

.avatar.large {
  @apply w-24 h-24 text-5xl;
}

.avatar.extra-large {
  @apply w-48 h-48 text-5xl;
}

.avatar.premium {
  @apply border-blue-700;
}

.avatar::v-deep img {
  border-radius: 50%;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;
}

.avatar-icon,
.avatar-presence {
  background: #fff;
  top: -25%;
  height: 50%;
  padding: 0.2rem;
  position: absolute;
  right: 14.64%;
  transform: translate(50%, 50%);
  width: 50%;
  z-index: 1;
}

.avatar-presence {
  @apply bg-gray;
  box-shadow: 0 0 0 0.2rem #fff;
  border-radius: 50%;
  height: 0.5em;
  width: 0.5em;

  &.online {
    @apply bg-success;
  }

  &.busy {
    @apply bg-danger;
  }

  &.away {
    @apply bg-warning;
  }
}

.avatar[data-initial]::before {
  color: currentColor;
  content: attr(data-initial);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
</style>
