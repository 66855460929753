export const anyDistanceValue = 25000

const distanceOptions = [
  { text: 'Within Any Distance', value: anyDistanceValue },
  { text: 'Within 200 Miles', value: 200 },
  { text: 'Within 100 Miles', value: 100 },
  { text: 'Within 90 Miles', value: 90 },
  { text: 'Within 80 Miles', value: 80 },
  { text: 'Within 70 Miles', value: 70 },
  { text: 'Within 60 Miles', value: 60 },
  { text: 'Within 50 Miles', value: 50 },
  { text: 'Within 40 Miles', value: 40 },
  { text: 'Within 30 Miles', value: 30 },
  { text: 'Within 20 Miles', value: 20 },
  { text: 'Within 10 Miles', value: 10 }
]

export const distanceToHuman = distance => {
  let foundOption = distanceOptions.find(option => option.value === parseInt(distance))

  return foundOption.text || ''
}

export default distanceOptions
