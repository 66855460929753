import Vue from 'vue'
import LegacyApi from '@/lib/api/legacy'

const initialState = () => {
  return {
    fetchApi: new LegacyApi(),
    impressionApi: new LegacyApi(),
    impressionSpaceRefCache: [],
    clickApi: new LegacyApi(),
    available: {
      banner: [],
      square: [],
      leaderboard: [],
      billboard: [],
      skyscraper: []
    }
  }
}

const actions = {
  async get({ state }, { commoditySlug, listingRef, categories, displayLocation, meta = {} }) {
    let filteredCategories = categories ? categories.filter(category => category !== '') : undefined

    await state.fetchApi.post('spaces.get', {
      commodity: commoditySlug,
      listing: listingRef,
      categories: filteredCategories,
      displayLocation,
      all: 1,
      meta: meta
    })

    state.fetchApi.data.forEach(advert => {
      if (!advert.spaceRef) {
        return
      }

      if (advert.desktopFileRef) {
        state.available.banner.push(advert)
      }

      if (advert.squareFileRef) {
        state.available.square.push(advert)
      }

      if (advert.leaderboardFileRef) {
        state.available.leaderboard.push(advert)
      }

      if (advert.billboardFileRef) {
        state.available.billboard.push(advert)
      }

      if (advert.skyscraperFileRef) {
        state.available.skyscraper.push(advert)
      }
    })
  },

  logImpression({ state, commit }, { advert, listingRef }) {
    if (listingRef) {
      state.impressionApi.post('spaces.filled', {
        spaceRefs: [advert.spaceRef],
        listing: listingRef
      })
    } else {
      commit('addToImpressionCache', advert.spaceRef)
    }
  },

  sendImpressionsCache({ state, commit }) {
    if (state.impressionSpaceRefCache.length === 0) {
      return false
    }

    // Take a copy of the cache and clear it to avoid accidently deleting new
    // items added while API call in progress
    let spaceRefs = [...state.impressionSpaceRefCache]

    commit('clearImpressionCache')

    state.impressionApi.post('spaces.filled', {
      spaceRefs
    })
  },

  logClick({ state }, { advert, listingRef, spaceCode, linkUrl = '' }) {
    state.clickApi.post('spaces.click', {
      spaceRef: advert.spaceRef,
      listing: listingRef,
      space: spaceCode
    })

    Vue.prototype.$analytics.addEvent({
      category: 'advert',
      action: 'Click',
      label: `${advert.name} - ${linkUrl}`
    })
  }
}

const mutations = {
  addToImpressionCache(state, spaceRef) {
    state.impressionSpaceRefCache.push(spaceRef)
  },

  clearImpressionCache(state) {
    state.impressionSpaceRefCache = []
  },

  reset(state) {
    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations
}
