export default function (location = {}) {
  const types = []

  if (location.types) {
    if (location.types.length) {
      location.types.forEach(o => {
        types.push(o.typeRef)
      })
    }
  }

  return {
    locationRef: location.locationRef || undefined,
    name: location.name || '',
    types: types || [],
    buildingName: location.buildingName || '',
    buildingNo: location.buildingNo || '',
    flatNo: location.flatNo || '',
    line1: location.line1 || '',
    line2: location.line2 || '',
    locality: location.locality || '',
    region: location.region || '',
    code: location.code || '',
    main: location.main || undefined,
    countryId: location.countryId || '',
    loadingTime: location.loadingTime || '',
    loadingMethod: location.loadingMethod || '',
    holdingNos: location.holdingNos || [{ holdingNo: '' }]
  }
}
