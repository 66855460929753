<template>
  <img
    src="@/assets/images/user/shield.svg"
    :class="{ 'verified-animate': showPendingVerification }"
    alt="Verified"
  />
</template>

<script>
export default {
  name: 'UtilVerifiedBadge',
  props: {
    showPendingVerification: Boolean
  }
}
</script>

<style lang="scss" scoped>
.verified-animate {
  animation-duration: 2s;
  animation-name: fade-opacity;
  animation-iteration-count: infinite;
}
</style>
