<template>
  <component
    :is="to ? 'router-link' : 'div'"
    :to="to"
    @click="$emit('click')"
    :class="[{ 'tile-border': bordered, vertical }, borderClass]"
  >
    <div class="tile" :class="{ 'tile-centered': centered }">
      <div class="tile-icon">
        <slot name="icon">
          <FontAwesomeIcon v-if="icon" :icon="icon" fixed-width class="text-2xl" />
        </slot>
      </div>

      <div class="tile-content" :class="{ 'tile-content-auto-width': autoWidth }">
        <slot name="body"></slot>
      </div>

      <div class="tile-action">
        <slot name="action"></slot>
      </div>
    </div>
  </component>
</template>

<script>
export default {
  name: 'UtilTile',
  props: {
    bordered: {
      type: Boolean,
      default: true
    },
    borderClass: {
      type: String,
      default: 'border-gray'
    },
    centered: Boolean,
    autoWidth: Boolean,
    vertical: Boolean,
    icon: Object,
    to: String
  }
}
</script>

<style lang="scss" scoped>
.tile-border {
  @apply rounded;
  @apply p-2;
  display: flex;
  flex-direction: column;
}

.tile {
  align-content: space-between;
  display: flex;

  .tile-icon,
  .tile-action {
    flex: 0 0 auto;
  }
  .tile-content {
    flex: 1 1 auto;
    overflow: hidden;

    &:not(:first-child) {
      @apply pl-1;
    }
    &:not(:last-child) {
      @apply pr-1;
    }
  }

  &.tile-centered {
    align-items: center;

    .tile-icon {
      @apply flex flex-col items-center;
    }

    .tile-content {
      overflow: hidden;
    }

    .tile-title,
    .tile-subtitle {
      @apply truncate;
      margin-bottom: 0;
    }
  }
}

.vertical .tile {
  @apply flex-wrap text-center;

  .tile-icon,
  .tile-action,
  .tile-content {
    width: 100%;
  }
}

.tile .tile-content.tile-content-auto-width {
  flex: 0 1 auto;
}

.tile-content *:not(.company-logo) {
  position: relative;
}
</style>
