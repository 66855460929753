import Vue from 'vue'

const eventDateDisplay = (event, compact = false) => {
  if (!event || !event.startDate) {
    return false
  }

  let $date = Vue.prototype.$date

  let startDate = $date(event.startDate, $date.legacyFormat)
  let endDate = $date(event.endDate, $date.legacyFormat)

  // Same date and time or no end date
  if (event.startDate === event.endDate || !event.endDate) {
    return startDate.format(compact ? 'dddd, Do MMMM' : 'dddd, Do MMMM HH:mm')
  }

  // Same date, time range
  if (startDate.format('DDMMYYYY') === endDate.format('DDMMYYYY')) {
    return compact
      ? startDate.format('dddd, Do MMMM')
      : `${startDate.format('dddd, Do MMMM HH:mm')} - ${endDate.format('HH:mm')}`
  }

  return compact
    ? `${startDate.format('Do MMMM')} - ${endDate.format('Do MMMM')}`
    : `${startDate.format('ddd, Do MMM HH:mm')} - ${endDate.format('ddd, Do MMM HH:mm')}`
}

export default eventDateDisplay
