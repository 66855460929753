<template>
  <div class="offline-warning">
    <FontAwesomeIcon :icon="faExclamationTriangle" class="mr-1" />
    <span>{{ $t('feedback.unableConnectInternet') }}</span>
  </div>
</template>

<script>
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'UtilWarningOffline',
  data() {
    return {
      faExclamationTriangle
    }
  }
}
</script>

<style lang="scss" scoped>
.offline-warning {
  @apply bg-red-400 text-primary py-1 flex items-center justify-center;
}
</style>
