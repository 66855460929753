const authRoutes = [
  {
    path: '/register',
    alias: '/register/user',
    name: 'register',
    component: () => import(/* webpackChunkName: "register-user" */ '@/views/RegisterUser.vue'),
    meta: {
      breadcrumb: [
        {
          label: 'Register',
          disableInteraction: true
        },
        {
          label: 'About you',
          current: true
        },
        {
          label: 'Your business',
          to: '/register/company',
          disableInteraction: true
        }
      ]
    }
  },
  {
    path: '/register/company',
    name: 'register-company',
    component: () => import(/* webpackChunkName: "register-company" */ '@/views/RegisterCompany.vue'),
    meta: {
      breadcrumb: [
        {
          label: 'Register',
          disableInteraction: true
        },
        {
          label: 'About you',
          to: '/register/user'
        },
        {
          label: 'Your business',
          current: true
        }
      ]
    }
  },
  {
    path: '/reset/:resetCode',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '@/views/Logout.vue')
  },
  {
    // Handle auto login redirects from .co.uk site
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  }
]

export default authRoutes
