const fileTypes = {
  spreadsheet: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'
  ],

  document: [
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],

  image: ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/webp'],

  video: [
    'video/mp4',
    'application/x-mpegURL',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/webm',
    'video/x-msvideo',
    'video/mpeg'
  ],

  csv: ['text/csv'],

  pdf: ['application/pdf']
}

export const fileExtensions = {
  spreadsheet: ['xls'],

  document: ['doc', 'docx'],

  image: ['jpg', 'jpeg', 'png', 'gif', 'webp'],

  csv: ['csv'],

  pdf: ['pdf'],

  video: ['mp4', 'mov', 'webm', 'mpeg', 'm4v', 'qt', 'flv', 'ogg', 'avi', 'wmv']
}

export default fileTypes
