<template>
  <div
    @click="$emit('click')"
    class="image relative"
    :class="[
      targetClass,
      aspectRatio,
      { cover, rounded, 'rounded-top': roundedTop, background, 'full-height': fullHeight }
    ]"
    :style="minWidth && cover ? `min-width: ${minWidth}px;` : ''"
  >
    <template v-if="compImageSrc">
      <img
        v-if="isLazyLoaded"
        :key="compImageSrc"
        @load="imageLoaded"
        @error="loadingFailed = true"
        v-lazy="compImageSrc"
        :class="[targetClass, { hidden: !loaded }]"
        :alt="alt"
      />

      <img
        v-else
        :key="compImageSrc"
        @load="imageLoaded"
        @error="loadingFailed = true"
        :src="compImageSrc"
        :class="[targetClass, { hidden: !loaded }]"
        :alt="alt"
      />
    </template>

    <slot v-if="!compImageSrc || loadingFailed" name="missingImage">
      <img v-lazy="require('@/assets/images/placeholders/image-video.png')" alt="No Image" />
    </slot>

    <slot v-if="!loaded && !loadingFailed && compImageSrc" name="loading" :class="targetClass">
      <UtilLoader v-if="compImageSrc" :isLoading="true" />
    </slot>

    <span v-if="canFullScreen" class="fullscreen">
      <FontAwesomeIcon :icon="faExpand" class="mr-1" />
      Fullscreen
    </span>
  </div>
</template>

<script>
import { faExpand } from '@fortawesome/pro-solid-svg-icons'
import UtilLoader from '@/components/utils/UtilLoader'

export default {
  name: 'UtilImage',
  components: {
    UtilLoader
  },
  props: {
    fileRef: String,
    src: String,
    width: Number,
    minWidth: Number,
    height: Number,
    targetClass: String,
    aspectRatio: String,
    alt: {
      type: String,
      default: ''
    },
    cover: Boolean,
    fullHeight: Boolean,
    background: Boolean,
    crop: Boolean,
    fill: Boolean,
    rounded: Boolean,
    roundedTop: Boolean,
    imageCacheBustNumber: {
      type: Number,
      default: 0
    },
    isLazyLoaded: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'listing'
    },
    showOriginal: Boolean,
    canFullScreen: Boolean
  },
  data() {
    return {
      loadTimer: null,
      loaded: false,
      loadingFailed: false,
      faExpand
    }
  },
  computed: {
    compImageSrc() {
      if (this.src) {
        return this.src
      }

      if (this.fileRef) {
        let src = `${this.assetBaseUrl.imageTransform}${this.showOriginal ? '' : 'hc_'}${this.fileRef}?auto=format`

        if (this.width) {
          src += `&w=${this.width}`
        }

        if (this.height) {
          src += `&h=${this.height}`
        }

        if (this.crop) {
          src += '&fit=crop'
        }

        if (this.fill) {
          src += '&fit=fill&fill=blur'
        }

        if (this.imageCacheBustNumber) {
          src += `&cache=${this.imageCacheBustNumber}`
        }

        return src
      } else {
        return false
      }
    }
  },
  methods: {
    imageLoaded() {
      this.loaded = true
      this.$emit('loaded')
    }
  }
}
</script>

<style lang="scss" scoped>
.image.cover > img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.image.full-height > img {
  height: 100%;
}

.image.rounded > img {
  @apply rounded;
}

.image.rounded-top > img {
  @apply rounded-t;
}

.image.aspect-3-2 {
  padding-top: 66.66%;

  & > img,
  & > .placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.background {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.fullscreen {
  @apply py-1 px-2 rounded text-xs cursor-pointer;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;

  &:hover {
    background: rgba(0, 0, 0, 1);
  }
}
</style>
