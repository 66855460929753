import Vue from 'vue'
import unit from '@/lib/mapping/unit'
import i18n from '@/plugins/i18n'

const listingTitle = {
  install(vm) {
    vm.prototype.$listingTitle = listingData => {
      let title = ''

      let listing = { ...listingData }

      if (!listing.quantityStart) {
        listing.quantityStart = listing.quantity
      }

      if (!listing.quantity) {
        title += listing.quantityStart
      } else {
        title += listing.quantity
      }

      if (listing.quantityUnit === unit.TONNE) {
        title += 't'
      } else if (listing.quantityUnit === unit.ACRE) {
        title += ` ${i18n.tc('units.acre', listing.quantityStart)}`
      } else if (listing.quantityUnit === unit.BALE) {
        title += ` ${i18n.tc('units.bale', listing.quantityStart)}`
      }

      let listingTitle = listing.title ? listing.title.trim() : ''

      title += ` ${listingTitle}`

      return title
    }
  }
}

Vue.use(listingTitle)
