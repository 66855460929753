import { register } from 'register-service-worker'
import store from '@/store'

let serviceWorkerHandler
const bootTime = Date.now()

const registerServiceWorker = () => {
  return new Promise((resolve, reject) => {
    navigator.serviceWorker.addEventListener('message', event => {
      if (event.data && event.data === 'reload-window') {
        window.location.reload()
      }
    })

    if (process.env.NODE_ENV === 'production') {
      register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
          console.log('App is being served from cache by a service worker.')
        },

        registered(registration) {
          serviceWorkerHandler = registration
          console.log('Service worker has been registered.')
          resolve(serviceWorkerHandler)
        },

        cached() {
          console.log('Content has been cached for offline use.')
        },

        updatefound() {
          console.log('New content is downloading.')
        },

        updated(registration) {
          console.log('New content is available, we need a reload')

          // If it takes too long to download the latest service worker assets
          // let's not interupt the user with a reload. Router can handle it on next route change
          const timeSinceBoot = Math.abs((Date.now() - bootTime) / 1000)

          if (timeSinceBoot <= 3) {
            console.log('Auto refreshing...')
            let worker = registration.waiting
            worker.postMessage({ action: 'skipWaiting' })
          } else {
            store.commit('device/setNeedsFullRefresh', true)
            console.log('Service worker did not update fast enough, waiting for route change...')
          }
        },

        offline() {
          console.log('No internet connection found. App is running in offline mode.')
        },

        error(error) {
          console.log('Error during service worker registration:', error)
          reject(error)
        }
      })
    }
  })
}

export { registerServiceWorker, serviceWorkerHandler }
