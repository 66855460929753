import LegacyApi from '@/lib/api/legacy'
import { uniq } from 'lodash'

const initialState = () => {
  return {
    dataApi: new LegacyApi(),
    connectionsApi: new LegacyApi(),
    syncApi: new LegacyApi()
  }
}

const getters = {
  connections: state => {
    return state.connectionsApi.data
  },

  hasLivestock: state => {
    return state.dataApi.data.length > 0
  },

  livestock: state => {
    if (state.dataApi.data.length === 0) {
      return []
    }

    return state.dataApi.data.map(animal => ({
      ...animal,
      ageInMonths: animal.age
    }))
  },

  livestockDataFromRefs: (state, getters) => livestockRefs => {
    let matches = []

    livestockRefs.forEach(livestockRef => {
      let livestockData = getters.livestock.find(animal => animal.livestockRef === livestockRef)

      if (livestockData) {
        matches.push(livestockData)
      }
    })

    return matches
  },

  livestockCategoriesFromRefs: (state, getters) => livestockRefs => {
    let data = getters.livestockDataFromRefs(livestockRefs)

    if (!data.length) {
      return ''
    }

    let categories = data.map(animal => animal.category.replace('Cattle, ', ''))

    return uniq(categories).join(', ')
  }
}

const actions = {
  async getConnections({ state }) {
    await state.connectionsApi.get('connections.get')
  },

  async getLivestock({ state }, maxAgeInMonths = 120) {
    await state.dataApi.get('livestock.get', {
      maxAge: maxAgeInMonths
    })
  },

  async sync({ state, getters }, connectionRef) {
    if (!getters.connections.length) {
      return false
    }

    if (!connectionRef) {
      connectionRef = getters.connections[0].connectionRef
    }

    await state.syncApi.post('connections.sync', {
      connectionRef
    })
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
}
