import LegacyApi from '@/lib/api/legacy'

const STORAGE_KEY = 'sml-watchlist'
const existingData = window.localStorage[STORAGE_KEY] || null

const initialState = () => {
  return new LegacyApi().hydrate(JSON.parse(existingData))
}

const getters = {
  listings: state => {
    return state.data || []
  }
}

const actions = {
  async getWatchlist({ state }) {
    await state.post('watchlist.user', undefined, { throttleMs: 1000 })

    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state.data))
  }
}

const mutations = {
  reset(state) {
    console.log('Resetting', STORAGE_KEY)
    window.localStorage.removeItem(STORAGE_KEY)
    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters
}
