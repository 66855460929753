import { uniq } from 'lodash'

const commodities = [
  {
    name: 'Livestock',
    slug: 'livestock',
    algolia: 'commodity:livestock',
    childCommodity: false,
    topLevelCommodity: true
  },
  {
    name: 'Calves',
    slug: 'calves',
    algolia: 'commodity:livestock AND categories.lvl0:cattle AND meta.ageMax <= 6 AND meta.sexes:Calves',
    childCommodity: true,
    listing: {
      commodity: 'livestock',
      species: 'cattle',
      categories: ['cattle'],
      meta: {
        types: ['Breeding', 'Store', 'Dairy'],
        sexes: ['Calves']
      }
    }
  },
  {
    name: 'Store Cattle',
    slug: 'store-cattle',
    algolia: 'commodity:livestock AND categories.lvl0:cattle AND meta.types:Store',
    childCommodity: true,
    listing: {
      commodity: 'livestock',
      species: 'cattle',
      categories: ['cattle'],
      meta: {
        types: ['Store']
      },
      maternalLabels: {
        pregnant: 'In Calf',
        children: 'With Calf at Foot'
      }
    }
  },
  {
    name: 'Breeding Cattle',
    slug: 'breeding-cattle',
    algolia: 'commodity:livestock AND categories.lvl0:cattle AND meta.types:Breeding',
    childCommodity: true,
    listing: {
      commodity: 'livestock',
      species: 'cattle',
      categories: ['cattle'],
      meta: {
        types: ['Breeding']
      },
      maternalLabels: {
        pregnant: 'In Calf',
        children: 'With Calf at Foot'
      }
    }
  },
  {
    name: 'Dairy Cattle',
    slug: 'dairy-cattle',
    algolia:
      'commodity:livestock AND categories.lvl0:cattle AND meta.types:Dairy AND meta.types:Breeding AND NOT meta.sexes:Calves',
    childCommodity: true,
    listing: {
      commodity: 'livestock',
      species: 'cattle',
      categories: ['cattle'],
      meta: {
        types: ['Dairy', 'Breeding']
      },
      maternalLabels: {
        pregnant: 'In Calf',
        children: 'With Calf at Foot'
      }
    }
  },
  {
    name: 'Sheep',
    slug: 'sheep',
    algolia: 'commodity:livestock AND categories.lvl0:sheep',
    childCommodity: true,
    listing: {
      commodity: 'livestock',
      species: 'sheep',
      categories: ['sheep'],
      meta: {
        types: ['Store', 'Breeding', 'Teaser']
      },
      maternalLabels: {
        pregnant: 'In Lamb',
        children: 'With Lamb at Foot'
      }
    }
  },
  {
    name: 'Pigs',
    slug: 'pigs',
    algolia: 'commodity:livestock AND categories.lvl0:pigs',
    childCommodity: true,
    listing: {
      commodity: 'livestock',
      species: 'pigs',
      categories: ['pigs'],
      meta: {
        types: ['Growing', 'Breeding']
      },
      maternalLabels: {
        pregnant: 'In Pig',
        children: 'With Piglet at foot'
      }
    }
  },
  {
    name: 'Goats',
    slug: 'goats',
    algolia: 'commodity:livestock AND categories.lvl0:goats',
    childCommodity: true,
    listing: {
      commodity: 'livestock',
      species: 'goats',
      categories: ['goats'],
      meta: {
        types: ['Dairy', 'Meat', 'Breeding']
      },
      maternalLabels: {
        pregnant: 'In Kid',
        children: 'With Kid at foot'
      }
    }
  },
  {
    name: 'Feed & Bedding',
    slug: 'feed-bedding',
    algolia: 'commodity:feed-bedding',
    childCommodity: true,
    topLevelCommodity: true,
    listing: {
      commodity: 'feed-bedding',
      species: 'feed-bedding'
    }
  },
  {
    name: 'Working Dogs',
    slug: 'working-dogs',
    algolia: 'commodity:working-dogs',
    childCommodity: true,
    topLevelCommodity: true,
    listing: {
      commodity: 'working-dogs',
      species: 'working-dogs'
    }
  }
]

export const childCommodities = commodities.filter(c => c.childCommodity)
export const topLevelCommodities = commodities.filter(c => c.topLevelCommodity)
export const topLevelCommoditySlugs = topLevelCommodities.map(c => c.slug)
export const allSpecies = uniq(childCommodities.map(c => c.listing.species))

export const findCommodityBySlug = slug => {
  let commodity = commodities.find(c => c.slug === slug)

  return {
    name: '',
    ...commodity
  }
}

export const findCommodityBySpecies = species => {
  let commodity = commodities.find(c => c.listing && c.listing.species === species)

  return {
    name: '',
    ...commodity
  }
}

export default commodities
