import reviewDirection from '@/lib/mapping/review-direction'

const initialState = () => {
  return {
    reviews: {
      lastUsedDirectionFilter: reviewDirection.RECEIVED
    },
    offers: {
      lastUsedDirectionFilter: 'sent'
    },
    securePayments: {
      lastUsedFilter: 'all'
    },
    listings: {
      lastUsedStatusFilter: null,
      lastUsedSearchFilter: null
    }
  }
}

const mutations = {
  setListingLastUsedStatusFilter(state, filter) {
    state.listings.lastUsedStatusFilter = filter
  },

  setListingLastUsedSearchFilter(state, searchQuery) {
    state.listings.lastUsedSearchFilter = searchQuery
  },

  setReviewLastUsedDirectionFilter(state, direction) {
    state.reviews.lastUsedDirectionFilter = direction
  },

  setOffersLastUsedDirectionFilter(state, direction) {
    state.offers.lastUsedDirectionFilter = direction
  },

  setSecurePaymentsLastUsedFilter(state, filter) {
    state.securePayments.lastUsedFilter = filter
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations
}
