import appVersion from '@/lib/app-version'
import store from '@/store'
import log from '@/plugins/log'
import { isNativeApp } from '@/plugins/native-app/capacitor'

const logRocket = {
  identity: {},

  init() {
    const uniqueScriptId = 'log-rocket-js'
    const existingScriptElement = document.getElementById(uniqueScriptId)
    const isBotVisit = document.cookie.indexOf('sml-bot=true') !== -1

    const isUKUser = ['GB', 'IE'].includes(store.state.locale.countryCode)

    let appIs = store.getters['app/appIs']
    let app = store.getters['app/getApp']

    // We only pay for a very limited number of sessions, we need to prioritise logging :(
    if (
      existingScriptElement ||
      process.env.VUE_APP_ENVIRONMENT !== 'production' ||
      !appIs.auth ||
      (appIs.auth &&
        (app.userFlat.emailAddress.includes('@hectare.farm') ||
          app.userFlat.emailAddress.includes('@sellmylivestock.co.uk'))) ||
      isBotVisit ||
      appIs.loggedInAsAnotherUser ||
      appIs.loggedInAsHelpdesk ||
      appIs.god ||
      (!isUKUser && !isNativeApp)
    ) {
      log.debug('Not eligable for session recording')
      return false
    }

    const script = document.createElement('script')
    script.src = 'https://cdn.lr-ingest.io/LogRocket.min.js'
    script.id = uniqueScriptId
    script.async = true
    script.crossOrigin = 'anonymous'

    script.addEventListener(
      'load',
      () => {
        const Sentry = require('@sentry/browser')

        window.LogRocket.init(`6vjvbp/marketplaces`, {
          release: appVersion,
          mergeIframes: true
        })

        // Add video replays to Sentry errors!
        window.LogRocket.getSessionURL(sessionURL => {
          Sentry.configureScope(scope => {
            scope.setExtra('sessionURL', sessionURL)
          })
        })

        // Did we previously identify before the script was loaded?
        if (this.identity.name) {
          window.LogRocket.identify(this.identity.userRef, {
            name: this.identity.name,
            email: this.identity.email
          })
        }
      },
      {
        once: true
      }
    )

    // Only load script when the page has fully loaded
    if (document.readyState === 'complete') {
      document.head.appendChild(script)
    } else {
      window.addEventListener('load', () => {
        document.head.appendChild(script)
      })
    }
  },

  identify({ userRef, name, email }) {
    if (window.LogRocket) {
      window.LogRocket.identify(userRef, {
        name,
        email
      })
    } else {
      // LogRocket not loaded yet, save for later
      this.identity = {
        userRef,
        name,
        email
      }
    }
  }
}

export default logRocket
