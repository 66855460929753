<template>
  <div class="ml-2 mr-2 sm:ml-4 sm:mr-4 floating-container">
    <UtilMessage
      v-for="notification in $store.state.notifications.floatingList"
      :key="notification.id"
      :type="notification.type"
      class="floating-message"
      @click.native="$store.commit('notifications/delete', notification.id)"
    >
      {{ notification.message }}
    </UtilMessage>
  </div>
</template>

<script>
import UtilMessage from '@/components/utils/UtilMessage'

export default {
  name: 'LayoutFloatingNotifications',
  components: {
    UtilMessage
  }
}
</script>

<style lang="scss" scoped>
.floating-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 45;
}

#app.xs .floating-container {
  margin-top: 45px;
  right: 0;
}

#app.ios .floating-container {
  top: calc(constant(safe-area-inset-top)); /* Status bar height on iOS 11.0 */
  top: calc(env(safe-area-inset-top)); /* Status bar height on iOS 11+ */
}

.floating-message {
  @apply mt-2 block cursor-pointer py-2;
  left: 0;
  position: relative;
  animation-name: slideIn;
  animation-duration: 0.5s;
}

@keyframes slideIn {
  from {
    left: -100%;
  }

  to {
    left: 0%;
  }
}
</style>
