import Vue from 'vue'
import log from '@/plugins/log'
import { find, cloneDeep } from 'lodash'

const initialState = () => {
  return {
    list: [],
    lastOpened: null,
    lastClosed: null,
    // Allow modals to save data before they close
    dataReturn: {
      // Use modalName to validate you are pulling data from the right modal
      modalName: '',
      data: {}
    }
  }
}

const actions = {
  showModal({ commit, state, dispatch }, payload) {
    let modalName
    let modalData = {}

    // If payload is a string then that's the modal name, otherwise
    // it means we are passing data as well as a name
    if (typeof payload === 'object') {
      modalName = payload.modalName
      modalData = payload.modalData
    } else {
      modalName = payload
    }

    if (find(state.list, { modalName })) {
      log.debug('Duplicate model found, not showing', modalName)
      return
    }

    log.debug('Showing modal', modalName)

    commit('setLastOpened', modalName)
    commit('addModal', { modalName, modalData })

    dispatch('handleModalOpen', modalName)
  },

  closeModals({ commit, state, dispatch }) {
    if (state.list.length === 0) {
      dispatch('handleModalClose', null)
      return false
    }

    const currentModalName = state.list[0].modalName

    dispatch('handleModalClose', currentModalName)
    commit('setLastClosed', currentModalName)
    commit('deleteAllModals')
  },

  closeModalByName({ commit, state, dispatch }, modalName) {
    // If the modal we want to close isn't open, then return
    if (!find(state.list, { modalName })) {
      return false
    }

    dispatch('handleModalClose', modalName)
    commit('setLastClosed', modalName)
    commit('deleteModalByName', modalName)
  },

  async handleModalClose(context, modalName) {
    Vue.prototype.$scroll.unlock()

    if (modalName) {
      Vue.prototype.$analytics.addEvent({
        category: 'modal',
        action: 'Close',
        label: modalName
      })
    }
  },

  handleModalOpen(context, modalName) {
    Vue.prototype.$analytics.addEvent(
      {
        category: 'modal',
        action: 'Open',
        label: modalName
      },
      false
    )

    Vue.prototype.$scroll.lock()
  },

  showTerms({ dispatch }, type = 'general') {
    dispatch('showModal', {
      modalName: 'ModalTerms',
      modalData: {
        type
      }
    })
  },

  readModalData({ commit, state }, modalName) {
    if (state.dataReturn.modalName !== modalName) {
      return null
    }

    let data = cloneDeep(state.dataReturn.data)

    // Clear the data now it has been used to prevent accidental retrieval in the future
    commit('resetDataReturn')

    return data
  }
}

const mutations = {
  addModal(state, { modalName, modalData = {} }) {
    state.list.unshift({
      component: () => import(/* webpackChunkName: "[request]" */ `@/components/modals/${modalName}`),
      modalName,
      modalData
    })
  },

  deleteModalByName(state, modalName) {
    state.list = state.list.filter(modal => modal.modalName != modalName)
  },

  deleteAllModals(state) {
    state.list = []
  },

  setLastOpened(state, modalName) {
    state.lastOpened = modalName

    // We set this to null because if the same model get's opened then closed
    // Our watchers won't see the lastClosed value change
    state.lastClosed = null
  },

  setLastClosed(state, modalName) {
    state.lastClosed = modalName
  },

  setDataReturn(state, { data, modalName }) {
    if (!modalName) {
      log.error('You must specify modalName')
      return
    }

    state.dataReturn = { data, modalName }
  },

  resetDataReturn(state) {
    state.dataReturn = initialState().dataReturn
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations
}
