import Vue from 'vue'
import store from '@/store'

const modal = {
  install(vm) {
    vm.prototype.$modal = {
      open: (modalName, modalData = {}) => {
        store.dispatch('modal/showModal', {
          modalName,
          modalData
        })
      },

      close: (modalName = null) => {
        if (modalName) {
          store.dispatch('modal/closeModalByName', modalName)
        } else {
          store.dispatch('modal/closeModals')
        }
      },

      closeAll: () => {
        store.dispatch('modal/closeModals')
      }
    }
  }
}

Vue.use(modal)
