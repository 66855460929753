import LegacyApi from '@/lib/api/legacy'

const initialState = () => {
  return {
    api: new LegacyApi({ fullDetails: 1 }),
    add: [],
    remove: [],
    update: {}
  }
}

const getters = {
  totalProductsInCart: state => {
    let count = 0

    if (state.api.data.sellers && state.api.data.sellers.length) {
      state.api.data.sellers.forEach(seller =>
        seller.products.forEach(product => {
          count += product.quantity
        })
      )
    }

    return count
  },

  subTotalPrice: state => {
    return state.api.data.subTotal || 0
  },

  hasProductsInCart: (state, getters) => {
    return getters.totalProductsInCart > 0
  },

  // Return a flat array of productRefs which are in the cart
  productRefsInCart: state => {
    let productRefs = []

    if (state.api.data.sellers && state.api.data.sellers.length) {
      state.api.data.sellers.forEach(seller =>
        seller.products.forEach(product => {
          productRefs.push(product.productRef)
        })
      )
    }

    return productRefs
  },

  // Return a flat array of productRefs which are currently being added to the cart
  productRefsLoading: state => {
    return state.add.reduce((loadingProductRefs, api) => {
      if (api.loading) {
        loadingProductRefs.push(api.model.productRef)
      }

      return loadingProductRefs
    }, [])
  }
}

const actions = {
  get({ state, rootGetters }, force = false) {
    let appIs = rootGetters['app/appIs']

    if (!appIs.auth) {
      return false
    }

    let options = force ? {} : { throttleMs: 3000 }

    state.api.get('cart.get', undefined, options)
  },

  // We do it like this so the user can rapid fire listing upgrades into the cart and we can track which are in the process of being added
  async addProduct({ state, dispatch }, { productRef, quantity = 1 }) {
    let addProductApi = new LegacyApi({
      productRef,
      quantity
    })

    state.add.push(addProductApi)
    await addProductApi.post('cart.add')

    dispatch('get', true)

    return addProductApi
  },

  async removeProduct({ state }, payload = {}) {
    let item = new LegacyApi(payload)

    state.remove.push(item)
    await item.post('cart.remove')

    return item
  },

  async updateProduct({ state }, payload = {}) {
    let item = new LegacyApi(payload)
    state.update[payload.productRef] = item

    await state.update[payload.productRef].post('cart.update')

    return item
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations
}
