<template>
  <div class="admin-warning">
    <FontAwesomeIcon :icon="faExclamationTriangle" class="mr-1" />
    <span>
      You are logged in as
      <span class="name">{{ `${app.user.firstName} ${app.user.lastName}` }}</span>
    </span>
  </div>
</template>

<script>
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'UtilWarningOffline',
  data() {
    return {
      faExclamationTriangle
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-warning {
  @apply bg-red-400 text-primary py-1 flex items-center justify-center;
}

.name {
  @apply font-semibold;
  animation: blinkingText 5s infinite;
}

@keyframes blinkingText {
  0% {
    @apply text-primary;
  }
  10% {
    @apply text-primary;
  }
  12% {
    @apply text-transparent;
  }
  30% {
    @apply text-transparent;
  }
  40% {
    @apply text-primary;
  }
  100% {
    @apply text-primary;
  }
}
</style>
