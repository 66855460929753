import Vue from 'vue'
import store from '@/store'

const numberFormatter = {
  install(vm) {
    vm.prototype.$numberFormat = (number, config = {}) => {
      return new Intl.NumberFormat(store.state.locale.language, config).format(number)
    }
  }
}

Vue.use(numberFormatter)
