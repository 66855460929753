import Vue from 'vue'
import router from '@/router'
import { camelCase, cloneDeep, get } from 'lodash'
import LegacyApi from '@/lib/api/legacy'
import ListingModel from '@/lib/models/listing'
import { topLevelCommoditySlugs } from '@/lib/helpers/commodities'
import { buyNowStatus, listingStatus, listingType, pedigreeStatus, priceType } from '@/lib/mapping'
import { listingStatusCodeToHuman } from '@/lib/mapping/listing-status'
import { unitCodeToHuman } from '@/lib/mapping/unit'
import assuranceCode from '@/lib/mapping/assurance-code'

const initialState = () => {
  return {
    post: new LegacyApi(new ListingModel()),
    // Our props expect an object for get.data, the default is an emtpy array
    get: new LegacyApi().hydrate({}),
    drafts: new LegacyApi(),
    recentlyViewed: new LegacyApi(),
    // TODO: All the above need converting to [name]Api to match the other modules:
    myRecentlyActiveApi: new LegacyApi(),
    myRecentLiveApi: new LegacyApi().hydrate({}),
    deleteDraftListingApi: new LegacyApi(),
    // Add a fileRef in here to open it in the fullscreen gallery
    // It must already exist in the main ListingGallery
    openGalleryFileRef: '',
    feedbackRecommendations: []
  }
}

const getters = {
  recentlyViewed: state => {
    return state.recentlyViewed.data || []
  },

  getRecentlyActive: state => {
    if (!state.myRecentlyActiveApi.data.listings) {
      return []
    }

    // Filter out listings that are older than 3 months
    return state.myRecentlyActiveApi.data.listings.filter(listing => {
      const monthsSinceCreation = Vue.prototype
        .$date()
        .diff(Vue.prototype.$date.unix(listing.startTimeStamp), 'month')

      return monthsSinceCreation <= 2 && listing.tradingPermission == 1
    })
  },

  getRecentlyActiveForSaleOnly: (state, getters) => {
    return getters.getRecentlyActive.filter(listing => {
      return listing.type !== listingType.WANTED
    })
  },

  viewCount: () => (listing, listingIs) => {
    if (!listingIs.approved || !listing.stats) {
      return 0
    }

    // Add 40 * days since listing went live to the actual view count (Jamie's request)
    const today = Vue.prototype.$date()
    const daysSinceStart = today.diff(Vue.prototype.$date(listing.start, Vue.prototype.$date.legacyFormat), 'day')
    const baseViews = 40 * daysSinceStart

    return listing.stats.viewCount + baseViews
  },

  postIs(state, getters) {
    let post = state.post.model
    let postIs = {}

    let listingIs = getters.listingIs(post, true)

    return {
      ...listingIs,
      ...postIs
    }
  },

  listingIs: (state, getters, rootState, rootGetters) => (
    listingData = null,
    isPost = false,
    isAlgoliaResult = false
  ) => {
    let listing = listingData || state.get.data

    if (!listing.title && !isPost) {
      return {}
    }

    let listingIs = {}
    let app = rootGetters['app/getApp']
    let appIs = rootGetters['app/appIs']
    let portalAuctionPartnerAccountRefs = rootGetters['portals/getAuctionPartnerAccountRefs']

    let commodity = listing.commoditySlug ? listing.commoditySlug : listing.commodity

    let startTimestamp = listing.startTimeStamp

    // listings.raw doesn't have startTimestamp available...
    if (!startTimestamp && listing.startDate) {
      startTimestamp = Vue.prototype
        .$date(`${listing.startDate} ${listing.startTime}`, Vue.prototype.$date.legacyFormat)
        .unix()
    }

    const startDate = Vue.prototype.$date.unix(startTimestamp)

    Object.keys(listingStatusCodeToHuman).forEach(statusCode => {
      if (listing.status === statusCode) {
        listingIs[camelCase(listingStatusCodeToHuman[statusCode])] = true
      }
    })

    listingIs.dead = listingIs.sold || listingIs.finished || listingIs.withdrawn
    listingIs.legacy = app.legacyEndDate >= startDate
    listingIs.owner = listing.user && listing.user[0].userRef === app.user.userRef
    listingIs.approved = typeof listing.tradingPermission === 'undefined' || listing.tradingPermission === 1
    listingIs.forSale = listing.type !== listingType.WANTED && !listingIs.dead
    listingIs.wanted = listing.type === listingType.WANTED && !listingIs.dead
    listingIs.multiple = listing.quantity > 1
    listingIs.premium = listing.premiumListing === 1
    listingIs.poa = listing.price && listing.price.type === priceType.POA
    listingIs.cross = listing.meta && listing.meta.cross === 1
    listingIs.pregnant = listing.meta && listing.meta.pregnant === 1
    listingIs.children = listing.meta && listing.meta.children === 1
    listingIs.event = listing.eventRef
    listingIs.pedigree = listing.meta && listing.meta.pedigreeStatus === pedigreeStatus.REGISTERED
    listingIs.namedSires = listing.meta && listing.meta.pedigreeStatus === pedigreeStatus.NAMED_SIRES
    listingIs.vetChecked =
      listing.meta && listing.meta.vetCheck && listing.meta.vetCheck !== '' && listing.meta.vetCheck !== 'pending'
    listingIs.usingBcms =
      listing.meta && listing.meta.bcmsLivestockRefs && listing.meta.bcmsLivestockRefs.length > 0

    listingIs.buyNow = listing.meta && listing.meta.buyNow && listing.meta.buyNow !== ''
    listingIs.buyNowPendingSale = listing.meta && listing.meta.buyNow === buyNowStatus.PENDING_SALE

    listingIs.inPortal = listing.portals && listing.portals.length > 0
    listingIs.portalAuctionPartner =
      listing.account && portalAuctionPartnerAccountRefs.includes(listing.account[0].accountRef)

    // TODO - This doesn't work for tags on the account listing list
    listingIs.matchmaking = listing.meta && listing.meta.matchmaking

    if (get(listing, 'location[0].nation')) {
      listingIs[`in${listing.location[0].nation}`] = true
    }

    // If a listing is older than 7 days bypass any new logic
    if (Vue.prototype.$date().diff(startDate, 'day') < 7) {
      if (appIs.auth && app.lastVisitedTimestamp > 0) {
        // If the listing has been created since last login then it's new
        listingIs.new = startDate.isAfter(app.lastVisitedDate)
      } else {
        // Guests see new if started in past 48 hours
        listingIs.new = Vue.prototype.$date().diff(startDate, 'hour') < 48
      }
    }

    // Handle legacy listings that don't have startTimeStamp
    if (listing.startTimeStamp) {
      const now = Vue.prototype.$date()
      const liveForHours = now.diff(startDate, 'hour')

      listingIs.timeRestrictedHoursLeft = app.featureRestrictions.limits.viewListingDelay - liveForHours

      if (
        listingIs.owner ||
        app.user.fullAccess === 1 ||
        app.featureRestrictions.limits.viewListingDelay === 0 ||
        liveForHours >= app.featureRestrictions.limits.viewListingDelay
      ) {
        listingIs.timeRestricted = false
      } else {
        listingIs.timeRestricted = true
      }
    } else {
      listingIs.timeRestricted = false
    }

    // Either their membership allows them to view detail or they've already made contact
    listingIs.detailsVisible =
      listingIs.owner ||
      app.featureRestrictions.canViewListingDetail ||
      app.featureRestrictions.limits.enquireListing.existingListingEnquiryRefs.includes(listing.listingRef)

    if (commodity) {
      listingIs[camelCase(commodity)] = true
    }

    listingIs.calves = listingIs.cattle && listing.meta && listing.meta.ageMax !== '' && listing.meta.ageMax <= 6

    if (listing.categories && listing.categories.length && !isAlgoliaResult) {
      listing.categories.forEach(category => {
        let categoryKey = isPost ? category : category.categorySlug
        listingIs[categoryKey] = true

        if (commodity === 'feed-bedding') {
          let alogliaPath = rootGetters['categories/getAlgoliaFromCategorySlug'](commodity, categoryKey)

          if (alogliaPath) {
            let alogliaPathSplit = alogliaPath.split(' > ')

            alogliaPathSplit.forEach(algoliaCategory => (listingIs[camelCase(algoliaCategory)] = true))
          }
        }
      })
    }

    if (listing.quantityUnit) {
      listingIs[unitCodeToHuman[listing.quantityUnit].toLowerCase()] = true
    }

    if (commodity === 'livestock' && listing.meta) {
      if (listing.meta.types) {
        listing.meta.types.forEach(type => {
          listingIs[type.toLowerCase()] = true
        })
      }

      if (listing.meta.sexes) {
        listing.meta.sexes.forEach(sex => {
          listingIs[sex.toLowerCase()] = true
        })
      }

      // Does our sexes array contain any female options from below?
      listingIs.female =
        listing.meta.sexes &&
        listing.meta.sexes.some(sex =>
          ['Gimmers', 'Gilt', 'Yearlings', 'Cows', 'Heifers', 'Ewes', 'Sows', 'Does'].includes(sex)
        )
    }

    // Check RedTractor status
    listingIs.redTractorVerified =
      get(listing, 'assurances[0].body') === assuranceCode.RED_TRACTOR &&
      get(listing, 'assurances[0].raw[0].certificatestatus') === 'VALID'

    return listingIs
  },

  score: state => (listingData = null, isPost = false) => {
    let listing = listingData || state.get.data

    if (!listing.commodity) {
      return {
        scorePercentage: 0,
        recommendations: []
      }
    }

    // Every listing starts with 20%
    let scorePercentage = 10
    let recommendations = []

    let scoringConfig = {
      livestock: {
        image: {
          priority: 1,
          min: 5,
          scorePerItem: 6
        },
        video: {
          priority: 2,
          min: 1,
          scorePerItem: 15
        },
        health: {
          priority: 3,
          scorePerItem: 25
        },
        description: {
          priority: 4,
          scorePerItem: 15
        },
        file: {
          priority: 5,
          min: 1,
          scorePerItem: 5
        }
      },
      'feed-bedding': {
        file: {
          priority: 1,
          min: 1,
          scorePerItem: 20
        },
        image: {
          priority: 2,
          min: 1,
          scorePerItem: 30
        },
        specification: {
          priority: 3,
          scorePerItem: 20
        },
        description: {
          priority: 4,
          scorePerItem: 20
        }
      },
      'working-dogs': {
        video: {
          priority: 1,
          min: 1,
          scorePerItem: 40
        },
        description: {
          priority: 2,
          scorePerItem: 20
        },
        age: {
          priority: 3,
          scorePerItem: 20
        },
        image: {
          priority: 4,
          min: 1,
          scorePerItem: 10
        }
      }
    }

    let commodity = isPost ? listing.commodity : listing.commoditySlug
    let config = scoringConfig[commodity]

    // Post model is different to listing model in Legacy API :'(
    const imageKey = isPost ? 'imageRefs' : 'images'
    const videoKey = isPost ? 'videoRefs' : 'videos'

    // Images worth 6% up to 5 images (total 30%)
    const totalImages = listing[imageKey].length <= config.image.min ? listing[imageKey].length : config.image.min
    scorePercentage += totalImages * config.image.scorePerItem

    if (listing[imageKey].length < config.image.min) {
      recommendations.push({
        text: `Add at least ${config.image.min} image(s)`,
        percentageWorth: (config.image.min - totalImages) * config.image.scorePerItem,
        section: 'images',
        priority: config.image.priority,
        key: 'image'
      })
    }

    // Video worth 20% up to 1 video
    if (config.video) {
      if ((listing[videoKey] && listing[videoKey].length) || listing.meta.youTubeVideoUrl) {
        scorePercentage += config.video.scorePerItem
      } else {
        recommendations.push({
          text: 'Add a video of the animal',
          percentageWorth: config.video.scorePerItem,
          section: 'images',
          priority: config.video.priority,
          key: 'video'
        })
      }
    }

    // Description
    if (config.description) {
      if (listing.description.length > 10) {
        scorePercentage += config.description.scorePerItem
      } else {
        recommendations.push({
          text: 'Add a description',
          percentageWorth: config.description.scorePerItem,
          section: 'description',
          priority: config.description.priority,
          key: 'description'
        })
      }
    }

    // Health
    if (config.health) {
      let hasTouchedVaccinations = false

      Object.keys(listing.meta.health).forEach(key => {
        if (listing.meta.health[key].vaccinated !== '' || listing.meta.health[key].accredited !== '') {
          hasTouchedVaccinations = true
        }
      })

      if (hasTouchedVaccinations || listing.categories.includes('pigs')) {
        scorePercentage += config.health.scorePerItem
      } else {
        recommendations.push({
          text: 'Complete Health Data',
          percentageWorth: config.health.scorePerItem,
          section: 'vaccinations',
          priority: config.health.priority,
          key: 'vaccinations'
        })
      }
    }

    // F&B Specifications
    if (config.specification) {
      if (listing.meta.dryMatter !== '' || listing.meta.cut !== '' || listing.meta.averageWeight !== '') {
        scorePercentage += config.specification.scorePerItem
      } else {
        recommendations.push({
          text: 'Provide Specification',
          percentageWorth: config.specification.scorePerItem,
          section: 'specification',
          priority: config.specification.priority,
          key: 'specification'
        })
      }
    }

    // Working Dogs Age
    if (config.age) {
      if (listing.meta.dob !== '') {
        scorePercentage += config.age.scorePerItem
      } else {
        recommendations.push({
          text: 'Provide Date of Birth',
          percentageWorth: config.age.scorePerItem,
          section: 'age',
          priority: config.age.priority,
          key: 'age'
        })
      }
    }

    // Data files
    if (config.file) {
      // Post model is different to listing model in Legacy API :'(
      const fileKey = isPost ? 'fileRefs' : 'files'

      if (!Array.isArray(listing[fileKey])) {
        listing[fileKey] = []
      }

      const totalFiles = listing[fileKey].length <= config.file.min ? listing[fileKey].length : config.file.min
      scorePercentage += totalFiles * config.file.scorePerItem

      if (listing[fileKey].length < config.file.min) {
        recommendations.push({
          text: `Add at least ${config.file.min} data file`,
          percentageWorth: (config.file.min - totalFiles) * config.file.scorePerItem,
          section: 'data-files',
          priority: config.file.priority,
          key: 'file'
        })
      }
    }

    return {
      scorePercentage,
      recommendations
    }
  },

  overviewMetaTags: state => (listingData = null) => {
    let listing = listingData || state.get.data

    const tagMeta = [
      {
        label: 'Organic',
        key: 'organicAssured'
      },
      {
        label: 'Treated',
        key: 'treated'
      },
      {
        label: 'TB Restricted',
        key: 'tbRestricted'
      },
      {
        label: 'Homebred',
        key: 'homebred'
      },
      {
        label: 'Exportable',
        key: 'export'
      },
      {
        label: 'Pedigree',
        key: 'pedigreeStatus',
        value: 'R'
      },
      {
        label: 'Named Sire(s)',
        key: 'pedigreeStatus',
        value: 'N'
      },
      {
        label: 'Registered',
        key: 'registered'
      },
      {
        label: 'Eye Tested',
        key: 'eyesTested'
      },
      {
        label: 'Spayed/Neutered',
        key: 'castrated'
      },
      {
        // Legacy working dogs parent
        label: 'Whelped',
        key: 'parent'
      }
    ]

    let tags = []

    // Loop through our required meta tags and return a list whose values match
    tagMeta.forEach(meta => {
      const value = meta.value || 1

      // If we are defining a value then it's probably not a number
      if ('value' in meta && listing.meta[meta.key] === value) {
        tags.push(meta.label)
      } else if (parseInt(listing.meta[meta.key]) === value) {
        tags.push(meta.label)
      }
    })

    if (parseInt(listing.meta.litter) > 0) {
      tags.push('Whelped')
    }

    return tags
  }
}

const actions = {
  async post({ state }) {
    await state.post.post('listings.add', state.post.model)

    state.post.model.title = state.post.data.title
  },

  async edit({ state }) {
    await state.post.post('listings.edit', state.post.model)
  },

  async view({ state }, { listingRef, listingCode }) {
    state.get.model = { listingRef, listingCode }

    await state.get.post('listings.get', state.get.model)
  },

  async get({ state }, listingRef) {
    state.get.model = { listingRef }

    await state.get.post('listings.raw').catch(() => {})

    state.get.model = new ListingModel(cloneDeep(state.get.data))
  },

  async getDrafts({ state }) {
    await state.drafts.post('listings.summary', {
      commodities: topLevelCommoditySlugs,
      statuses: [listingStatus.DRAFT]
    })

    state.drafts.data =
      state.drafts.data.listings && state.drafts.data.listings.length ? state.drafts.data.listings.slice(0, 5) : []
  },

  async getRecentlyViewed({ state }) {
    await state.recentlyViewed.post('listings.recent', undefined, { throttleMs: 5000 }).catch(() => {})
  },

  async getMyRecentlyActiveListings({ state }) {
    await state.myRecentlyActiveApi.post('listings.summary', {
      commodities: topLevelCommoditySlugs,
      statuses: [listingStatus.LIVE, listingStatus.SOLD, listingStatus.SOLD_ALT]
    })
  },

  async getMyRecentLiveListings({ state }) {
    await state.myRecentLiveApi.post('listings.summary', {
      commodities: topLevelCommoditySlugs,
      statuses: [listingStatus.LIVE]
    })
  },

  async deleteDraftListing({ state, dispatch }, listingRef) {
    await state.deleteDraftListingApi.post('listings.withdrawn', {
      listingRef
    })

    Vue.prototype.$analytics.addEvent({
      category: 'listing',
      action: 'Deleted draft listing',
      label: router.currentRoute.fullPath
    })

    await dispatch('getDrafts')
  },

  openListingSold({ dispatch }, listing) {
    dispatch(
      'modal/showModal',
      {
        modalName: 'ModalListingSold',
        modalData: {
          listing: listing,
          title: Vue.prototype.$listingTitle(listing)
        }
      },
      { root: true }
    )
  }
}

const mutations = {
  async resetGet(state) {
    state.get = new LegacyApi().hydrate({})
  },

  async resetPost(state) {
    state.post = new LegacyApi(new ListingModel())

    state.post.model.company = this.state.app.api.data.company
    state.post.model.user = this.state.app.api.data.user
  },

  changeOpenGalleryFileRef(state, imageFileRef) {
    state.openGalleryFileRef = imageFileRef
  },

  setFeedbackRecommendations(state, feedbackRecommendations) {
    state.feedbackRecommendations = feedbackRecommendations
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters
}
