import Vue from 'vue'
import VueMq from 'vue-mq'
import { keys, pickBy } from 'lodash'

Vue.use(VueMq, {
  breakpoints: {
    xs: 640,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1536
  }
})

const mixin = {
  computed: {
    $breakpoint() {
      let $mq = this.$mq

      let breakpointsDesktopFirst = {
        lgAndDown: ['xs', 'sm', 'md', 'lg'].includes($mq),
        mdAndDown: ['xs', 'sm', 'md'].includes($mq),
        smAndDown: ['xs', 'sm'].includes($mq)
      }

      let breakpointsMobileFirst = {
        xsAndUp: ['xs', 'sm', 'md', 'lg', 'xl'].includes($mq),
        smAndUp: ['sm', 'md', 'lg', 'xl'].includes($mq),
        mdAndUp: ['md', 'lg', 'xl'].includes($mq),
        lgAndUp: ['lg', 'xl'].includes($mq)
      }

      let breakpoints = { ...breakpointsDesktopFirst, ...breakpointsMobileFirst }

      breakpoints[$mq] = true
      breakpoints.classList = keys(pickBy(breakpoints)).join(' ')

      return breakpoints
    }
  }
}

Vue.prototype.$screen = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight
})

window.addEventListener('resize', () => {
  Vue.prototype.$screen.width = window.innerWidth
  Vue.prototype.$screen.height = window.innerHeight
})

Vue.mixin(mixin)
