var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"click":function($event){return _vm.handleClick()}}},[_c(_vm.linkToProfile ? 'router-link' : 'div',{tag:"component",staticClass:"block",attrs:{"to":_vm.compProfileUrl}},[_c('UtilTileBase',{staticClass:"overflow-hidden",attrs:{"bordered":_vm.bordered,"autoWidth":_vm.autoWidth,"borderClass":_vm.bordered ? 'border border-gray' : undefined,"centered":"","vertical":_vm.vertical},scopedSlots:_vm._u([(_vm.showAvatar)?{key:"icon",fn:function(){return [_c('UtilAvatar',{staticClass:"text-white",attrs:{"extraLarge":_vm.extraLarge,"large":!_vm.compact || _vm.vertical,"data-initial":_vm.compInitials}},[(_vm.locked || !_vm.appIs.auth)?[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('@/assets/images/user/avatar-locked.png')),expression:"require('@/assets/images/user/avatar-locked.png')"}]})]:[(_vm.lazyLoad)?[(_vm.compAvatarUrl)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.compAvatarUrl),expression:"compAvatarUrl"}],attrs:{"alt":"..."}}):_vm._e()]:[(_vm.compAvatarUrl)?_c('img',{attrs:{"src":_vm.compAvatarUrl,"alt":"..."}}):_vm._e()],_vm._v(" "),(
                _vm.showVerifiedBadge &&
                ((_vm.showPendingVerification && _vm.pendingVerification) ||
                  _vm.user.verified === 1 ||
                  _vm.user.verified === true)
              )?_c('span',{staticClass:"avatar-icon verified-icon tooltip cursor-help",attrs:{"data-tooltip":_vm.compVerifiedToolTip}},[_c('UtilVerifiedBadge',{attrs:{"showPendingVerification":_vm.showPendingVerification && _vm.pendingVerification}})],1):_vm._e(),_vm._v(" "),(_vm.showMembershipStatus)?_c('UtilMembershipStatus',{staticClass:"member-status",attrs:{"memberStatus":_vm.compMembershipStatus}}):_vm._e()]],2)]},proxy:true}:null,{key:"body",fn:function(){return [_vm._t("body",[(_vm.showUser || _vm.showCompany)?_c('div',{staticClass:"uac-body overflow-hidden",class:{
              'mt-1': _vm.vertical && !_vm.compact,
              'ml-2': !_vm.compact && _vm.showAvatar,
              'ml-1': _vm.compact && _vm.showAvatar
            }},[(_vm.showUser)?_c('p',{staticClass:"tile-title truncate leading-tight capitalize"},[(_vm.locked || !_vm.appIs.auth)?_c('strong',[_vm._v("Members access")]):_c('strong',[_vm._v(_vm._s(_vm.compUserName))])]):_vm._e(),_vm._v(" "),(_vm.showCompany && _vm.compCompanyName)?_c('p',{staticClass:"tile-subtitle flex items-center leading-tight",class:{ 'mt-1': !_vm.compact, 'justify-center': _vm.vertical }},[(_vm.showFlag)?_c('img',{staticClass:"mr-1 h-5 leading-none",attrs:{"src":require("@/assets/images/flags/rounded/gb.svg"),"alt":"GB"}}):_vm._e(),_vm._v(" "),(_vm.compCompanyType && !_vm.locked)?_c('UtilTag',{staticClass:"mr-1 bg-information-lighter border border-information-darker leading-snug"},[_vm._v("\n                "+_vm._s(_vm.compCompanyType)+"\n              ")]):_vm._e(),_vm._v(" "),(!_vm.appIs.auth)?_c('span',{staticClass:"truncate"},[(!_vm.appIs.auth)?_c('UtilTextLink',{staticClass:"text-brand underline font-medium cursor-pointer",nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$store.dispatch('modal/showModal', {
                      modalName: 'ModalAuthLogin',
                      modalData: { redirectToDashboard: false }
                    })}}},[_vm._v("\n                  Login for more info\n                ")]):_vm._e()],1):(_vm.locked)?_c('span',{staticClass:"truncate"},[(_vm.showLockedButton)?_c('UtilButton',{staticClass:"mt-2 font-medium",attrs:{"color":"primary"},nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.restrictedAction()}}},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.faLockAlt}}),_vm._v(" "),_c('span',[_vm._v("Upgrade for full details")])],1):_c('UtilTextLink',{staticClass:"text-brand underline font-medium cursor-pointer",nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.restrictedAction()}}},[_vm._v("\n                  Upgrade to see owner details\n                ")])],1):_c('span',{staticClass:"truncate capitalize"},[_vm._v(_vm._s(_vm.compCompanyName))])],1):_vm._e(),_vm._v(" "),_vm._t("meta",[(_vm.showStats)?_c('p',{staticClass:"text-sm text-gray-darker"},[(_vm.statsApi.loading)?_c('span',{staticClass:"flex"},[_c('span',{staticClass:"invisible"},[_vm._v("Loading")])]):(_vm.statsApi.data.user && _vm.statsApi.data.user.listingsCount)?[_c('span',[_vm._v(_vm._s(_vm.$t('trust.numberListed', { count: _vm.statsApi.data.user.listingsCount })))]),_vm._v(" "),(_vm.statsApi.data.user.reviewsCount > 0)?[_c('span',[_c('span',[_vm._v(",")]),_vm._v(" "),_c('span',[_vm._v("\n                        "+_vm._s(_vm.$t('trust.positiveFeedback', { percentage: (_vm.compPercentagePositive + "%") }))+"\n                      ")])])]:[_c('span',[_vm._v(", "+_vm._s(_vm.$t('trust.memberSince', { date: _vm.compMemberSince })))])]]:_vm._e()],2):_vm._e()])],2):_vm._e()])]},proxy:true},{key:"action",fn:function(){return [_vm._t("action")]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }