import Api from '@/lib/api'
import { has } from 'lodash'
import { isNativeApp } from '@/plugins/native-app/capacitor'

const initialState = () => {
  return {
    stickyList: [],
    floatingList: [],
    unreadApi: new Api(),
    markReadApi: new Api()
  }
}

const getters = {
  unread: state => {
    return has(state.unreadApi, 'data.unread') ? state.unreadApi.data.unread : { total: 0 }
  },

  events: state => {
    return state.unreadApi.data.events || []
  }
}

const actions = {
  add({ commit }, { message, type = 'success', position = 'sticky', data = null, timeoutMs = 6000 }) {
    const id = Date.now()

    let messageData = {
      message,
      type,
      id,
      data,
      routeChangeCount: 0
    }

    const addMutation = position === 'sticky' ? 'addSticky' : 'addFloating'
    commit(addMutation, messageData)

    if (position === 'floating') {
      setTimeout(() => commit('delete', id), timeoutMs)
    }
  },

  async getUnreads({ state, rootGetters, getters }, force = false) {
    let appIs = rootGetters['app/appIs']

    if (!appIs.auth) {
      return false
    }

    try {
      await state.unreadApi.get(
        '/notify/v1/event?limit=10&skip=0&read=true&platform=farmpay,sellmylivestock',
        undefined,
        {
          throttleMs: force ? 0 : 5000
        }
      )

      // Set app icon unread count
      if (isNativeApp) {
        window.cordova.plugins.notification.badge.set(getters.unread.total)
      }
    } catch (error) {
      // Stub
    }
  },

  async markEventAsRead({ state, rootGetters }, eventId) {
    if (!state.unreadApi.links[eventId]) {
      return false
    }

    let appIs = rootGetters['app/appIs']

    if (appIs.loggedInAsAnotherUser) {
      console.log('Logged in as another user, not marking event as read')
      return false
    }

    await state.markReadApi.get(state.unreadApi.links[eventId].href)
  },

  // Empty topics array will mark ALL events as read
  async markTopicsAsRead({ state, rootGetters, dispatch }, topics = []) {
    let appIs = rootGetters['app/appIs']

    if (!appIs.auth) {
      return false
    }

    if (appIs.loggedInAsAnotherUser) {
      console.log('Logged in as another user, not marking top as read')
      return false
    }

    try {
      await state.markReadApi.put('/notify/v1/event/markAsRead', {
        topic: topics,
        platform: 'farmpay,sellmylivestock'
      })
    } catch (error) {
      // Stub
    }

    dispatch('getUnreads', true)
  }
}

const mutations = {
  addSticky(state, messageData) {
    state.stickyList.unshift(messageData)
  },

  addFloating(state, messageData) {
    state.floatingList.unshift(messageData)
  },

  // Delete notification by ID
  delete(state, id) {
    state.stickyList = state.stickyList.filter(n => n.id != id)
    state.floatingList = state.floatingList.filter(n => n.id != id)
  },

  // Every time the route changes let's work out if we need to remove old sticky messages
  routeChange(state) {
    state.stickyList = state.stickyList.map(n => {
      n.routeChangeCount++
      return n
    })

    state.stickyList = state.stickyList.filter(n => {
      return n.routeChangeCount < 2
    })
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters
}
