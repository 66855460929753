import Vue from 'vue'
import { App } from '@capacitor/app'
import { isNativeApp } from '@/plugins/native-app/capacitor'
import log from '@/plugins/log'
import Router from '@/router'

if (isNativeApp) {
  // Universal / Deep links
  App.addListener('appUrlOpen', data => {
    log.debug('Deep link launch: ', data)
    Vue.prototype.$analytics.addEvent({
      category: 'native-app',
      action: 'Universal link triggered',
      label: data.url
    })
    try {
      const url = new URL(data.url)
      Router.push({ path: url.pathname + url.search }).catch(() => {})
    } catch (error) {
      log.error(`Failed to launch deep link - ${data.url}`, error)
    }
  })
}
