export let Capacitor
export let Plugins
export let CameraResultType
export let CameraSource
export let platform = 'web'
export let isNativeApp = false

// This is an abstraction for IE11 / iOS 9 which do not support Proxy which
// Capacitor uses at it's core. A polyfill is too heavy for Proxy support
try {
  // Don't load for web. http://192 is for HMR native local dev. http://localhost without ssl is native app
  if (
    window.Proxy &&
    (window.origin.includes('capacitor://') ||
      window.origin.includes('http://192') ||
      window.origin.includes('http://localhost'))
  ) {
    const capacitor = require('@capacitor/core')

    Capacitor = capacitor.Capacitor
    Plugins = capacitor.Plugins
    CameraResultType = capacitor.CameraResultType
    CameraSource = capacitor.CameraSource
    platform = Capacitor.platform

    // Remember, many of the capacitor APIs work for web browsers too
    isNativeApp = platform !== 'web'
  } else {
    Capacitor = undefined
    Plugins = undefined
    CameraResultType = undefined
    CameraSource = undefined
  }
} catch (e) {
  // Catch the error IE11 throws about Proxy being undefined;
}
