const postRoutes = [
  {
    path: '/post',
    name: 'post',
    component: () => import(/* webpackChunkName: "post" */ '@/views/Post.vue')
  },
  {
    path: '/post/haulage',
    alias: '/haulage',
    name: 'post-haulage',
    component: () => import(/* webpackChunkName: "post" */ '@/views/Post.vue')
  },
  {
    path: '/post/finance',
    alias: '/finance',
    name: 'post-finance',
    component: () => import(/* webpackChunkName: "post" */ '@/views/Post.vue')
  },
  {
    path: '/post/listing',
    name: 'post-listing',
    component: () => import(/* webpackChunkName: "post-listing-drafts" */ '@/views/PostListingDrafts.vue'),
    meta: {
      breadcrumb: [
        {
          label: 'Post',
          to: '/post'
        },
        {
          label: 'Post Listing',
          current: true
        }
      ]
    }
  },
  {
    path: '/post/listing/new',
    name: 'post-listing-new',
    component: () => import(/* webpackChunkName: "post-listing" */ '@/views/PostListing.vue'),
    meta: {
      breadcrumb: [
        {
          label: 'Post',
          to: '/post'
        },
        {
          label: 'Post Listing',
          to: '/post/listing'
        },
        {
          label: 'New Listing',
          current: true
        }
      ]
    }
  },
  {
    path: '/post/listing/continue/:listingRef',
    name: 'post-listing-continue',
    component: () => import(/* webpackChunkName: "post-listing" */ '@/views/PostListing.vue'),
    meta: {
      breadcrumb: [
        {
          label: 'Post',
          to: '/post'
        },
        {
          label: 'Post Listing',
          to: '/post/listing'
        },
        {
          label: 'Continue Listing',
          current: true
        }
      ]
    }
  },
  {
    path: '/post/listing/similar/:listingRef',
    name: 'post-listing-similar',
    component: () => import(/* webpackChunkName: "post-listing" */ '@/views/PostListing.vue')
  },
  {
    path: '/post/listing/wanted/:listingRef/:userRef',
    name: 'post-listing-wanted',
    component: () => import(/* webpackChunkName: "post-listing" */ '@/views/PostListing.vue')
  },
  {
    path: '/edit/listing/:listingRef',
    name: 'edit-listing',
    component: () => import(/* webpackChunkName: "post-listing" */ '@/views/PostListing.vue')
  }
]

export default postRoutes
