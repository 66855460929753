import LegacyApi from '@/lib/api/legacy'

const STORAGE_KEY = 'sml-categories'

const existingCategoryData = () =>
  window.localStorage[STORAGE_KEY] ? JSON.parse(window.localStorage.getItem(STORAGE_KEY)) : {}

const initialState = () => {
  return {
    api: new LegacyApi({ commodity: '' }).hydrate(existingCategoryData())
  }
}

const getters = {
  getBreeds: state => species => {
    if (!state.api.data.livestock) {
      return []
    }

    return state.api.data.livestock.filter(o => {
      return o.parentSlug === species
    })
  },

  getGroups(state) {
    return state.api.data['feed-bedding'] || []
  },

  getWorkingDogBreeds(state) {
    return state.api.data['working-dogs'] || []
  },

  getCategorySlugFromAlgolia: state => (commodity, algoliaCategoryName) => {
    if (!state.api.data[commodity]) {
      return algoliaCategoryName
    }

    let matchingCategory = state.api.data[commodity].find(category => category.algolia === algoliaCategoryName)

    return matchingCategory ? matchingCategory.slug : algoliaCategoryName
  },

  getAlgoliaFromCategorySlug: state => (commodity, categorySlug) => {
    if (!state.api.data[commodity]) {
      return categorySlug
    }

    let matchingCategory = state.api.data[commodity].find(category => category.slug === categorySlug)

    return matchingCategory ? matchingCategory.algolia : categorySlug
  },

  getCategoryAbbreviationFromRef: state => (commodity = 'livestock', categoryRef) => {
    let matchingCategory = state.api.data[commodity].find(category => category.categoryRef === categoryRef)

    return matchingCategory ? matchingCategory.abbreviation : '--'
  },

  getCategoryNameFromRef: state => (commodity = 'livestock', categoryRef) => {
    let matchingCategory = state.api.data[commodity].find(category => category.categoryRef === categoryRef)

    return matchingCategory ? matchingCategory.name : '--'
  }
}

const actions = {
  async get({ state, commit }, commodity) {
    try {
      state.api.model.commodity = commodity
      await state.api.post('categories.flat', state.api.model)

      // Merge our categories with existing data and save back to localstorage
      let categories = existingCategoryData()
      categories[commodity] = state.api.data

      commit('save', categories)
    } catch (error) {
      // Failed to get categories
    }
  }
}

const mutations = {
  save(state, categories) {
    state.api.data = categories
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(categories))
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters
}
