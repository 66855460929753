import appVersion from '@/lib/app-version'
import { platform } from '@/plugins/native-app/capacitor'
import * as Sentry from '@sentry/browser'
import store from '@/store'
import log from '@/plugins/log'

// If Cloudflare has set the bot cookie, let's not use Sentry
const isBotVisit = document.cookie.indexOf('sml-bot=true') !== -1

if (process.env.VUE_APP_REPORT_ERRORS === 'true' && !isBotVisit) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENVIRONMENT || 'production',
    release: `sml-client@${appVersion}`,
    ignoreErrors: [
      'Something went wrong, please reload and try again',
      'Not logged in.',
      'Network Error',
      'The object is in an invalid state',
      'Error during service worker registration',
      'Failed to update a ServiceWorker for scope',
      'find variable: cordova',
      'AbortError: The operation was aborted',
      'QuotaExceededError: QuotaExceededError',
      "evaluating 'Ie", // Photoswipe
      "Cannot read property 'updateScrollOffset' of null", // Photoswipe
      'Non-Error promise rejection captured'
    ],
    beforeSend(event, hint) {
      // If we are in maintenance mode we should ignore all errors
      if (store.state.app.isMaintenanceModeEnabled) {
        return null
      }

      const error = hint ? hint.originalException : null

      if (
        error &&
        error.message &&
        error.message.match(
          /AbortError: The operation was aborted|Something went wrong, please reload and try again|evaluating 'Ie|Network Error|Not logged in|Error during service worker registration|The object is in an invalid state|find variable: cordova|QuotaExceededError: QuotaExceededError|Failed to update a ServiceWorker for scope|Cannot read property 'updateScrollOffset' of null/i
        )
      ) {
        log.debug('Error ignored by Sentry', error)
        return null
      }

      return event
    }
  })

  Sentry.configureScope(scope => {
    scope.setTag('app-version', appVersion)
    scope.setTag('app-environment', process.env.VUE_APP_ENVIRONMENT)
    scope.setTag('app-platform', platform)
  })
}
