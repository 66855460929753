import i18n from '@/plugins/i18n'
import { checkVAT, unitedKingdom, ireland, netherlands, sweden } from 'jsvat'

// JSVAT needs to be transpiled manually in vue.config!

const fieldName = 'vatNumber'
// Our translated field name
const translatedFieldName = { _field_: i18n.t(`formFields.${fieldName}`) }

const validateVAT = (value, countryValidator) => {
  if (!checkVAT(value, [countryValidator]).isValid) {
    return i18n.t('feedback.vatNotValid', translatedFieldName)
  }

  return true
}

export default {
  GB: { validate: value => validateVAT(value, unitedKingdom) },
  IE: { validate: value => validateVAT(value, ireland) },
  NL: { validate: value => validateVAT(value, netherlands) },
  SE: { validate: value => validateVAT(value, sweden) }
}
