import Vue from 'vue'

const mixin = {
  methods: {
    checkVideoScroll() {
      let videos = document.getElementsByTagName('video')
      let fraction = 0.8 // Play when 80% of the player is visible.

      for (let i = 0; i < videos.length; i++) {
        let isPlaying =
          videos[i].currentTime > 0 && !videos[i].paused && !videos[i].ended && videos[i].readyState > 2

        let x = videos[i].offsetLeft,
          y = videos[i].offsetTop,
          w = videos[i].offsetWidth,
          h = videos[i].offsetHeight,
          r = x + w, //right
          b = y + h, //bottom
          visibleX,
          visibleY,
          visible

        visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset))
        visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset))

        visible = (visibleX * visibleY) / (w * h)

        if (visible > fraction) {
          if (videos[i].paused && videos[i].currentTime > 0) {
            return
          } else {
            if (!isPlaying) videos[i].play()
          }
        } else {
          if (isPlaying) videos[i].pause()
        }
      }
    }
  }
}

Vue.mixin(mixin)
