<template>
  <div :class="{ 'has-error': hasError, 'has-success': hasSuccess }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormInputFeedBackWrapper',
  props: {
    hasError: {
      type: Boolean,
      default: false
    },
    hasSuccess: {
      type: Boolean,
      default: false
    }
  }
}
</script>
