const clearBrowserCache = () => {
  // If supported and serviceWorker.controller tells us if we one installed
  if ('serviceWorker' in window.navigator && window.navigator.serviceWorker.controller) {
    setTimeout(() => {
      console.log('Reloading to complete serviceWorker removal')
      // Reload otherwise service worker will still be in use
      window.location.reload()
    }, 2500)

    // Delete any existing caches
    if ('caches' in window) {
      caches.keys().then(keyList => {
        return Promise.all(
          keyList.map(key => {
            return caches.delete(key)
          })
        )
      })
    }

    window.navigator.serviceWorker.getRegistrations().then(registrations => {
      for (const registration of registrations) {
        console.log('serviceWorker unregistered')
        // Unregister service worker
        registration.unregister()
      }
    })
  } else {
    window.location.reload()
  }
}

export default clearBrowserCache
