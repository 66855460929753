<template>
  <div v-if="$store.state.device.browser.outOfDate" class="container">
    <UtilMessage type="warning">
      <strong>{{ $t('feedback.browserOutdated') }}</strong>
      {{ $t('feedback.experienceTechnicalIssues') }}
      <UtilTextLink href="https://browser-update.org/update.html">{{ $t('feedback.learnUpgrade') }}</UtilTextLink>
    </UtilMessage>
  </div>
</template>

<script>
import UtilMessage from '@/components/utils/UtilMessage'
import UtilTextLink from '@/components/utils/UtilTextLink'

export default {
  name: 'UtilWarningOutdatedBrowser',
  components: {
    UtilMessage,
    UtilTextLink
  }
}
</script>
