<template>
  <div class="bg-brand-darker text-white border-b border-gray-lighter">
    <div class="menu-header px-5 py-1 flex flex-wrap items-center justify-between">
      <h4 class="text-xl font-medium mb-0">{{ title }}</h4>

      <a
        class="btn btn-link btn-icon-only text-white hover:text-gray-lighter text-lg px-4 -mr-4"
        aria-label="Close"
        @click="$emit('close')"
      >
        <FontAwesomeIcon :icon="faTimes" />
      </a>
    </div>
  </div>
</template>

<script>
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'UtilMenuHeader',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      faTimes
    }
  }
}
</script>

<style lang="scss" scoped>
#app.ios .menu-header {
  padding-top: 0px;
}
</style>
