const accountRoutes = [
  {
    path: '/account',
    name: 'account',
    redirect: '/account/dashboard'
  },
  {
    path: '/account/dashboard',
    name: 'account-dashboard',
    component: () => import(/* webpackChunkName: "account-dashboard" */ '@/views/AccountDashboard.vue'),
    meta: {
      title: 'Dashboard',
      isAccountSection: true
    }
  },
  {
    path: '/account/listings',
    name: 'account-listings',
    component: () => import(/* webpackChunkName: "account-listings" */ '@/views/AccountListings.vue'),
    meta: {
      title: 'Listings',
      isAccountSection: true
    }
  },
  {
    path: '/account/listings/saved',
    name: 'account-listings-saved',
    component: () => import(/* webpackChunkName: "account-listings-saved" */ '@/views/AccountListingsSaved.vue'),
    meta: {
      title: 'Watchlist',
      isAccountSection: true
    },
    children: [
      {
        path: ':section',
        name: 'account-listings-saved-section',
        component: () =>
          import(/* webpackChunkName: "account-listings-saved" */ '@/views/AccountListingsSaved.vue')
      }
    ]
  },
  {
    path: '/account/herd',
    name: 'account-herd',
    component: () => import(/* webpackChunkName: "account-herd" */ '@/views/AccountHerd.vue'),
    meta: {
      title: 'Herd',
      isAccountSection: true
    }
  },
  {
    path: '/account/herd/:livestockRef',
    name: 'account-herd-animal',
    component: () => import(/* webpackChunkName: "account-herd" */ '@/views/AccountHerdAnimal.vue'),
    meta: {
      title: 'Herd',
      isAccountSection: true
    }
  },
  {
    path: '/account/offers',
    name: 'account-offers',
    component: () => import(/* webpackChunkName: "account-offers" */ '@/views/AccountOffers.vue'),
    meta: {
      title: 'Offers',
      isAccountSection: true
    }
  },
  {
    path: '/account/messages',
    name: 'account-messages',
    component: () => import(/* webpackChunkName: "account-messages" */ '@/views/AccountMessages.vue'),
    children: [
      {
        path: ':threadRef',
        name: 'account-messages-view-message',
        component: () => import(/* webpackChunkName: "account-messages" */ '@/views/AccountMessages.vue')
      },
      {
        path: 'listing/:listingRef',
        name: 'account-messages-filter-thread',
        component: () => import(/* webpackChunkName: "account-messages" */ '@/views/AccountMessages.vue')
      },
      {
        path: 'listing/:listingRef/:threadRef?',
        name: 'account-messages-filter-listing-thread',
        component: () => import(/* webpackChunkName: "account-messages" */ '@/views/AccountMessages.vue')
      }
    ],
    meta: {
      title: 'Messages',
      isAccountSection: true
    }
  },
  {
    path: '/account/haulage',
    alias: '/account/loads',
    name: 'account-haulage',
    component: () => import(/* webpackChunkName: "account-haulage" */ '@/views/AccountHaulage.vue'),
    meta: {
      title: 'Haulage',
      isAccountSection: true
    }
  },
  // {
  //   path: '/account/finance',
  //   name: 'account-finance',
  //   component: () => import(/* webpackChunkName: "account-finance" */ '@/views/AccountFinance.vue'),
  //   meta: {
  //     title: 'Finance',
  //     isAccountSection: true
  //   }
  // },
  {
    path: '/account/payments',
    alias: '/account/payment',
    name: 'account-payments',
    component: () => import(/* webpackChunkName: "account-payments" */ '@/views/AccountPayments.vue'),
    meta: {
      title: 'Secure Payments',
      isAccountSection: true
    },
    children: [
      {
        path: 'new',
        name: 'account-payments-new',
        component: () => import(/* webpackChunkName: "account-payments" */ '@/views/AccountPayments.vue'),
        meta: {
          title: 'Secure Payments',
          isAccountSection: true
        }
      }
    ]
  },
  {
    path: '/account/payments/verification',
    name: 'account-payments-edit-verification',
    component: () =>
      import(
        /* webpackChunkName: "account-payments-edit-verification" */ '@/views/AccountPaymentsEditVerification.vue'
      ),
    meta: {
      title: 'Verification Details'
    }
  },
  {
    path: '/account/payments/return',
    name: 'account-payments-return',
    component: () => import(/* webpackChunkName: "account-payments-return" */ '@/views/AccountPaymentsReturn.vue'),
    meta: {
      title: 'Loading Payment'
    }
  },
  {
    path: '/account/payments/:transactionId',
    // Old site URL
    alias: '/account/payment/:transactionId',
    name: 'account-payments-view',
    component: () => import(/* webpackChunkName: "account-payments-single" */ '@/views/AccountPaymentsView.vue'),
    meta: {
      title: 'Payment Details'
    }
  },
  {
    path: '/account/reviews/:reviewRef?',
    name: 'account-reviews',
    component: () => import(/* webpackChunkName: "account-reviews" */ '@/views/AccountReviews.vue'),
    meta: {
      title: 'Reviews',
      isAccountSection: true
    }
  },
  {
    path: '/account/delete/confirm/:id',
    name: 'account-delete-confirmation',
    component: () => import(/* webpackChunkName: "account-reviews" */ '@/views/AccountDeleteConfirm.vue'),
    meta: {
      title: 'Delete Account',
      isAccountSection: true
    }
  },
  {
    path: '/account/settings',
    name: 'account-settings',
    component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings.vue'),
    meta: {
      title: 'Settings',
      isAccountSection: true
    },
    children: [
      {
        path: 'profile',
        name: 'account-settings-profile',
        component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings.vue'),
        meta: {
          title: 'Update Profile',
          isAccountSection: true
        }
      },
      {
        path: 'business',
        name: 'account-settings-business',
        component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings.vue'),
        meta: {
          title: 'Update Business',
          isAccountSection: true
        }
      },
      {
        path: 'membership',
        name: 'account-settings-membership',
        component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings.vue'),
        meta: {
          title: 'Update Membership',
          isAccountSection: true
        }
      },
      {
        path: 'contact',
        name: 'account-settings-contact',
        component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings.vue'),
        meta: {
          title: 'Update Contacts',
          isAccountSection: true
        }
      },
      {
        path: 'locations',
        name: 'account-settings-locations',
        component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings.vue'),
        meta: {
          title: 'Update Locations',
          isAccountSection: true
        }
      },
      {
        path: 'cards',
        name: 'account-settings-cards',
        component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings.vue'),
        meta: {
          title: 'Update Payment Cards',
          isAccountSection: true
        }
      },
      {
        path: 'bcms',
        name: 'account-settings-bcms',
        component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings.vue'),
        meta: {
          title: 'Link BCMS Account',
          isAccountSection: true
        }
      },
      {
        path: 'preferences',
        name: 'account-settings-preferences',
        component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings.vue'),
        meta: {
          title: 'Update Preferences',
          isAccountSection: true
        }
      },
      {
        path: 'security',
        name: 'account-settings-security',
        component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings.vue'),
        meta: {
          title: 'Update Security',
          isAccountSection: true
        }
      }
    ]
  },
  {
    path: '/account/portals',
    name: 'account-portals',
    component: () => import(/* webpackChunkName: "account-portals" */ '@/views/AccountPortals.vue'),
    meta: {
      title: 'Portals',
      isAccountSection: true
    }
  },
  {
    path: '/account/adverts',
    name: 'account-adverts',
    component: () => import(/* webpackChunkName: "account-adverts" */ '@/views/AccountAdverts.vue'),
    meta: {
      title: 'Adverts',
      isAccountSection: true
    }
  }
]

export default accountRoutes
