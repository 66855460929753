import Vue from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendarFormat from 'dayjs/plugin/calendar'
import isBetween from 'dayjs/plugin/isBetween'

// Our supported locales, manually add here as needed
import 'dayjs/locale/en-gb'
import 'dayjs/locale/cy'

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#relativetime
dayjs.extend(relativeTime)

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#localizedFormat
dayjs.extend(LocalizedFormat)

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#customparseformat
dayjs.extend(customParseFormat)

// DOCS: https://day.js.org/docs/en/plugin/advanced-format
dayjs.extend(advancedFormat)

// DOCS: https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#calendar
dayjs.extend(calendarFormat)

// DOCS: https://day.js.org/docs/en/plugin/is-between
dayjs.extend(isBetween)

dayjs.locale('en-gb')

const dateFormatter = {
  install(vm) {
    vm.prototype.$date = dayjs

    vm.prototype.$date.legacyFormat = 'DD-MM-YYYY HH:mm:ss'
    vm.prototype.$date.legacyFormatDateOnly = 'DD-MM-YYYY'
    vm.prototype.$date.legacyFormatDateOnlyReverse = 'YYYY-MM-DD'

    vm.prototype.$date.setLocale = locale => {
      if (locale === 'cy-GB') {
        locale = 'cy'
      }

      locale = locale.toLowerCase()

      vm.prototype.$date.locale(locale)
    }
  }
}

Vue.use(dateFormatter)
