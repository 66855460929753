import Vue from 'vue'
import LegacyApi from '@/lib/api/legacy'
import { get, uniqBy } from 'lodash'

const STORAGE_KEY = 'sml-messages'
const existingData = window.localStorage[STORAGE_KEY] || null

const initialState = () => {
  return {
    threadsApi: new LegacyApi().hydrate(JSON.parse(existingData)),
    listingThreadApi: new LegacyApi(),
    recentEnquiriesApi: new LegacyApi()
  }
}

const getters = {
  threadRefForListing: state => {
    return get(state.listingThreadApi, 'data[0].threadRef') || undefined
  },

  threadsForListing: state => {
    return get(state.listingThreadApi, 'data') || []
  },

  recentEnquiries: state => {
    return get(state.recentEnquiriesApi, 'data') || []
  },

  recentListingOtherPartyEnquiries: (state, getters, rootState, rootGetters) => {
    let app = rootGetters['app/getApp']

    let enquiries = getters.recentEnquiries

    // Strip out current user and anything not related to a listing
    return enquiries.filter(
      enquiry =>
        enquiry.listing &&
        enquiry.user[0].userRef !== app.user.userRef &&
        enquiry.listing.userRef !== app.user.userRef
    )
  }
}

const actions = {
  async getMessages({ state }) {
    await state.threadsApi.get('messages.get', { ...state.threadsApi.model, markRead: 0 })

    // TODO: Until messages has pagination, let's only show 50
    state.threadsApi.data = state.threadsApi.data.slice(0, 50)

    // Only save latest 10 messages locally
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state.threadsApi.data.slice(0, 10)))
  },

  async getMessagesForListing({ state }, listingRef) {
    await state.listingThreadApi.get('messages.get', {
      listingRef,
      markRead: 0
    })
  },

  async getRecentEnquiries({ state }, listingRef) {
    await state.recentEnquiriesApi.get('enquiries.recent', {
      listingRef
    })

    // Strip out duplicate users
    Vue.set(
      state.recentEnquiriesApi,
      'data',
      uniqBy(state.recentEnquiriesApi.data, enquirer => {
        return enquirer.user[0].userRef
      })
    )
  }
}

const mutations = {
  reset(state) {
    window.localStorage.setItem(STORAGE_KEY, null)

    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
}
