import LegacyApi from '@/lib/api/legacy'
import portalType from '@/lib/mapping/portal-type'
import portalStatus from '@/lib/mapping/portal-status'

const STORAGE_KEY = 'sml-portals'
const existingData = window.localStorage[STORAGE_KEY] || null

const initialState = () => {
  return {
    api: new LegacyApi().hydrate(JSON.parse(existingData))
  }
}

const getters = {
  getPortalFromSlug: state => slug => {
    let blankPortal = {
      bannerFileRef: '',
      name: ''
    }

    if (!state.api.data.length) {
      return blankPortal
    }

    let portal = state.api.data.find(portal => {
      return portal.portalSlug === slug
    })

    return portal || blankPortal
  },

  ownedPortals: (state, getters, rootState, rootGetters) => {
    let app = rootGetters['app/getApp']

    return state.api.data.filter(portal => portal.accountRef === app.account.accountRef)
  },

  getAuctionPartners: state => {
    let partners = []
    let auctionPartners = state.api.data.filter(
      portal =>
        portal.portalType === portalType.ACCOUNT &&
        (portal.name.toLowerCase().includes('mart') || portal.name.toLowerCase().includes('market'))
    )

    auctionPartners.forEach(partner => {
      partners.push({
        portalRef: partner.portalRef,
        name: partner.name,
        logoFileRef: partner.logoFileRef,
        to: `/portal/${partner.portalSlug}`
      })
    })

    return partners.sort((a, b) => {
      let partnerA = a.name.toUpperCase()
      let partnerB = b.name.toUpperCase()
      if (partnerA < partnerB) return -1
      if (partnerA > partnerB) return 1
      return 0
    })
  },

  getAuctionPartnerAccountRefs: (state, getters) => {
    let partners = getters.getAuctionPartners

    return partners.map(partner => partner.accountRef)
  },

  getPaidBreedSocietyPortals: state => {
    let portals = []

    const livePortals = state.api.data.filter(
      portal => portal.status === 'L' && portal.portalType === portalType.BREED
    )

    livePortals.forEach(portal => {
      portals.push({
        portalRef: portal.portalRef,
        name: portal.name,
        slug: portal.portalSlug,
        description: portal.description,
        logoFileRef: portal.logoFileRef,
        bannerFileRef: portal.bannerFileRef,
        to: `/portal/${portal.portalSlug}`
      })
    })

    return portals
  },

  getAgentPortals(state) {
    const agentPortals = state.api.data.filter(portal => portal.portalType === portalType.SUPPLY_CHAIN)

    return agentPortals.map(portal => ({
      portalRef: portal.portalRef,
      name: portal.name,
      slug: portal.portalSlug,
      description: portal.description,
      logoFileRef: portal.logoFileRef,
      bannerFileRef: portal.bannerFileRef,
      to: `/portal/${portal.portalSlug}`
    }))
  },

  getAllPortalsInOrder(state, getters) {
    return getters['getPaidBreedSocietyPortals'].concat(getters['getAuctionPartners']).sort((a, b) => {
      let partnerA = a.name.toUpperCase()
      let partnerB = b.name.toUpperCase()

      if (partnerA < partnerB) return -1
      if (partnerA > partnerB) return 1

      return 0
    })
  }
}

const actions = {
  async get({ state }) {
    await state.api.get('portals.get')

    state.api.data = state.api.data.filter(portal => {
      return portal.status === portalStatus.LIVE
    })

    let portals = state.api.data.map(portal => {
      // eslint-disable-next-line no-unused-vars
      let { description, products, categories, features, socialMedia, ...portalSlim } = portal
      return portalSlim
    })

    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(portals))
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters
}
