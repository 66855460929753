import Vue from 'vue'

const sleep = {
  install(vm) {
    vm.prototype.$sleep = ms => {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  }
}

Vue.use(sleep)
