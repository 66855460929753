<template>
  <div
    :class="$store.state.search.isNavSearchOpen ? 'z-50' : 'z-30'"
    class="bg-brand-darker px-4 flex justify-between items-center relative z-30"
  >
    <!-- SML Logos -->
    <RouterLink
      to="/?logo=header"
      class="logo-link select-none flex relative"
      :class="$breakpoint.mdAndUp ? 'mr-12' : 'mr-6'"
    >
      <transition name="fade">
        <div
          v-if="$breakpoint.lgAndUp && !appIs.mobileOrNative && logoLoaded && app.countryCode === 'GB'"
          class="dev-banner"
        >
          <img
            @load="flagLoaded = true"
            :src="require('@/assets/images/flags/grayscale/gb.png')"
            class="h-4 mr-1"
            alt="GB Flag"
          />

          <span v-if="flagLoaded" class="leading-none">UK</span>
        </div>
      </transition>

      <img
        v-if="$breakpoint.lgAndUp && !appIs.mobileOrNative"
        @load="logoLoaded = true"
        alt="SML logo"
        src="@/assets/images/cached/cached-logo-white-text.svg"
        class="logo"
      />

      <img
        v-if="$breakpoint.mdAndDown || appIs.mobileOrNative"
        @load="logoLoaded = true"
        alt="SML logo"
        src="@/assets/images/cached/cached-logo-green-icon.svg"
        class="logo block"
      />
    </RouterLink>

    <div class="flex flex-grow items-stretch justify-end">
      <!-- Mega Menu -->
      <div v-if="!$store.state.search.isNavSearchOpen" class="flex items-stretch">
        <ul name="topLevelNavigationItems" class="flex items-stretch">
          <li
            v-for="item in compTopLevelNavigationItems"
            :key="item.key"
            v-hover-intent="changeParentKey.bind(null, item.key)"
            @mouseleave="removeParentKey()"
            class="text-white flex items-center flex-shrink-0 cursor-pointer relative py-4 font-medium"
            :class="$breakpoint.mdAndUp ? 'mr-6' : 'mr-5'"
          >
            <UtilTextLink :to="item.path" class="no-underline">
              <span class="mr-1">
                {{ $breakpoint.mdAndDown && item.shortLabel ? item.shortLabel : item.label }}
              </span>
              <FontAwesomeIcon
                v-if="item.hasDropdown"
                :icon="item.key === activeParentKey ? faChevronUp : faChevronDown"
                class="text-xs"
              />
            </UtilTextLink>
            <div
              v-if="item.key === activeParentKey && item.hasDropdown"
              class="bg-white left-0 right-0 h-1 bottom-0 absolute"
            />
          </li>
        </ul>

        <transition name="fade">
          <div v-if="activeParentKey && compMegaMenu.length > 0">
            <div @mouseenter="cancelHoverTimeout()" @mouseleave="removeParentKey()" class="mega-menu">
              <div class="container py-10 z-50">
                <div class="flex w-full">
                  <!-- Cattle, Sheep, Pigs etc -->
                  <div class="w-4/12 nav-link-container">
                    <UtilTextLink
                      v-for="item in compMegaMenu"
                      :key="item.key"
                      :to="item.path || undefined"
                      @click="() => (item.path ? itemClick(item.label, 'parent') : null)"
                      v-hover-intent="changeActiveMegaMenuChildKey.bind(null, item.key)"
                      class="nav-link"
                      :class="{ 'bg-gray-lighter': item.key === activeMegaMenuChildKey }"
                    >
                      {{ item.label }}

                      <FontAwesomeIcon v-if="item.children" :icon="faChevronRight" class="ml-1 text-sm" />
                    </UtilTextLink>

                    <UtilTextLink
                      v-if="activeParentKey === 'listings'"
                      to="/search/livestock"
                      @click="itemClick('View All Livestock', 'parent')"
                      class="nav-link"
                    >
                      View all livestock
                    </UtilTextLink>
                  </div>

                  <!-- Calves, Breeding, Store etc -->
                  <div :class="activeChildHasChildren ? 'w-4/12' : 'w-8/12'" class="nav-link-container">
                    <UtilTextLink
                      v-for="item in compMegaMenuChildren"
                      :key="item.key"
                      :to="item.path"
                      @click="itemClick(item.label, 'child')"
                      v-hover-intent="changeActiveMegaMenuGrandChildKey.bind(null, item.key)"
                      class="nav-link"
                      :class="{
                        'bg-gray-lighter': item.key === activeMegaMenuGrandChildKey && item.key !== undefined
                      }"
                    >
                      <UtilImage
                        v-if="item.logoFileRef"
                        :fileRef="item.logoFileRef"
                        fullHeight
                        showOriginal
                        :isLazyLoaded="false"
                        class="flex-shrink-0 mr-4 rounded-full h-10 w-10 overflow-hidden"
                      />

                      <span class="flex items-center justify-between w-full">
                        {{ item.label }}
                        <FontAwesomeIcon v-if="item.children" :icon="faChevronRight" class="ml-1 text-sm" />
                      </span>
                    </UtilTextLink>
                  </div>

                  <!-- Bulls, Heifers, Cows etc  -->
                  <div
                    v-if="activeChildHasChildren && compMegaMenuGrandChildren"
                    class="flex flex-col w-4/12 xl:w-3/12 px-6 nav-link-container"
                  >
                    <UtilTextLink
                      v-for="child in compMegaMenuGrandChildren"
                      :key="child.key"
                      :to="child.path"
                      @click="itemClick(child.label, 'grandchild')"
                      class="nav-link"
                    >
                      <UtilImage
                        v-if="child.logoFileRef"
                        :fileRef="child.logoFileRef"
                        fullHeight
                        cover
                        showOriginal
                        :isLazyLoaded="false"
                        class="flex-shrink-0 mr-4 rounded-full h-10 w-10 overflow-hidden"
                      />

                      <span class="block">
                        <span class="block">{{ child.label }}</span>

                        <span v-if="child.moreInfo" class="block text-gray text-sm">{{ child.moreInfo }}</span>
                      </span>
                    </UtilTextLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <!-- Search -->
      <UtilSearchBar
        v-if="$breakpoint.smAndUp && !appIs.mobileOrNative && !$store.state.search.isMobileSearchMenuOpen"
        class="flex-grow flex-shrink-999 py-4"
        :class="[!$store.state.search.isNavSearchOpen ? 'max-w-sm' : '', $breakpoint.mdAndUp ? 'mr-2' : 'mr-0']"
      />

      <!-- Post listing -->
      <div class="flex items-center">
        <UtilButton v-if="$breakpoint.mdAndUp" name="header-post" to="/post" color="primary" class="font-medium">
          <span>Post {{ $breakpoint.lgAndUp ? 'Listing' : '' }}</span>
        </UtilButton>
      </div>
    </div>
  </div>
</template>

<script>
  import {faChevronDown, faChevronUp} from '@fortawesome/pro-regular-svg-icons'
  import {faChevronRight, faEnvelope, faLongArrowRight, faStar, faUser} from '@fortawesome/pro-solid-svg-icons'
  import UtilButton from '@/components/utils/UtilButton'
  import UtilSearchBar from '@/components/utils/search/UtilSearchBar'
  import {mapGetters} from 'vuex'
  import {megaMenuItems, topLevelNavigationItems} from '@/lib/helpers/navigation-items'
  import eventStatus from '@/lib/mapping/event-status'
  import eventDateDisplay from '@/lib/event-date'
  import UtilTextLink from '@/components/utils/UtilTextLink'
  import UtilImage from '@/components/utils/UtilImage'
  import LegacyApi from '@/lib/api/legacy'

  export default {
  name: 'LayoutSubNav',
  components: {
    UtilButton,
    UtilSearchBar,
    UtilTextLink,
    UtilImage
  },
  data() {
    return {
      logoLoaded: false,
      flagLoaded: false,
      activeParentKey: null,
      activeMegaMenuChildKey: null,
      activeMegaMenuGrandChildKey: null,
      activeChildHasChildren: false,
      eventsApi: new LegacyApi(),
      hoverTimer: null,
      faLongArrowRight,
      faChevronRight,
      faUser,
      faStar,
      faEnvelope,
      faChevronDown,
      faChevronUp
    }
  },
  watch: {
    activeParentKey(newValue, oldValue) {
      // Set overlay if we have megamenu items
      if (this.compMegaMenu.length && newValue !== null) {
        this.$emit('setOverlay', true)
      } else {
        this.$emit('setOverlay', false)
      }

      if (newValue !== oldValue) {
        // Fetch events if we open martsAndPartners for the first time
        if (newValue === 'martsAndPartners' && !this.eventsApi.data.length) {
          this.getEvents()
        }
      }
    },
    '$route.path'() {
      this.closeMenu()
    }
  },
  computed: {
    ...mapGetters({
      auctionPartners: 'portals/getAuctionPartners',
      paidBreedSocietyPortals: 'portals/getPaidBreedSocietyPortals',
      agentPortals: 'portals/getAgentPortals'
    }),

    compTopLevelNavigationItems() {
      return topLevelNavigationItems.filter(item => !item.isHiddenFromDesktopNav)
    },

    compMegaMenu() {
      if (!megaMenuItems[this.activeParentKey]) {
        return []
      }

      let items = megaMenuItems[this.activeParentKey]

      // Set dynamic children
      if (this.activeParentKey === 'listings' || this.activeParentKey === 'martsAndPartners') {
        let breedSocieties = items.find(item => item.key === 'breed-societies')

        breedSocieties.children = this.paidBreedSocietyPortals.map(portal => ({
          label: portal.name,
          path: portal.to,
          logoFileRef: portal.logoFileRef
        }))
      }

      if (this.activeParentKey === 'martsAndPartners') {
        let marts = items.find(item => item.key === 'marts')

        marts.children = this.auctionPartners.map(portal => ({
          label: portal.name,
          path: portal.to,
          logoFileRef: portal.logoFileRef
        }))

        let events = items.find(item => item.key === 'events')

        events.children[0].children = this.eventsApi.data.slice(0, 5).map(event => ({
          label: event.name,
          moreInfo: eventDateDisplay(event, true),
          path: `/events/${event.eventRef}`,
          logoFileRef: event.avatarFileRef
        }))

        let agents = items.find(item => item.key === 'agents')

        agents.children = this.agentPortals.map(portal => ({
          label: portal.name,
          path: portal.to,
          logoFileRef: portal.logoFileRef
        }))
      }

      return items
    },

    compMegaMenuChildren() {
      let activeParent = this.compMegaMenu.find(item => item.key === this.activeMegaMenuChildKey)

      if (!activeParent || !activeParent.children) {
        return []
      }

      return activeParent.children
    },

    compMegaMenuGrandChildren() {
      // Parent i.e. livestock (Main nav link)
      // Child i.e. Sheep (First dropdown column)
      // Grandchild i.e. Breeding (Second column)

      if (!this.activeMegaMenuChildKey || !this.activeMegaMenuGrandChildKey) {
        return []
      }

      const grandChildren =
        this.compMegaMenu.find(child => child.key === this.activeMegaMenuChildKey).children || []

      return grandChildren.find(grandChild => grandChild.key === this.activeMegaMenuGrandChildKey).children || []
    }
  },
  methods: {
    async changeParentKey(key) {
      this.cancelHoverTimeout()

      this.activeParentKey = key

      await this.$nextTick()

      // Default select first item in megamenu
      this.activeMegaMenuChildKey = this.compMegaMenu.length ? this.compMegaMenu[0].key : null
      this.activeMegaMenuGrandChildKey = null

      if (this.activeMegaMenuChildKey) {
        this.checkActiveChildHasChildren()
      }
    },

    removeParentKey() {
      this.hoverTimer = setTimeout(() => {
        this.activeParentKey = null
      }, 300)
    },

    cancelHoverTimeout() {
      if (this.hoverTimer) {
        clearTimeout(this.hoverTimer)
      }
    },

    changeActiveMegaMenuChildKey(key) {
      this.activeMegaMenuChildKey = key
      // Reset grandChildKey if hovering over child
      this.activeMegaMenuGrandChildKey = null

      this.checkActiveChildHasChildren()
    },

    checkActiveChildHasChildren() {
      // See whether the child has children in order to set number of columns
      const grandChildren =
        this.compMegaMenu.find(child => child.key === this.activeMegaMenuChildKey).children || []

      if (grandChildren) {
        this.activeChildHasChildren = grandChildren.some(grandChild => grandChild.children) ? true : false
      }
    },

    changeActiveMegaMenuGrandChildKey(key) {
      this.activeMegaMenuGrandChildKey = key
    },

    closeMenu() {
      this.activeParentKey = null
      this.activeMegaMenuChildKey = null
      this.activeMegaMenuGrandChildKey = null
    },

    getEvents() {
      this.eventsApi.get('events.get', {
        includeListings: 0,
        rangeFrom: this.$date().subtract(24, 'hour').format('DD-MM-YYYY'),
        rangeTo: this.$date().add(4, 'week').format('DD-MM-YYYY'),
        statuses: [eventStatus.LIVE, eventStatus.PENDING]
      })
    },

    itemClick(label, level) {
      this.closeMenu()

      this.$analytics.addEvent({
        category: 'navigation',
        action: `Clicked desktop mega menu level - ${level}`,
        label
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mega-menu {
  @apply absolute left-0 right-0 bg-white shadow-2xl rounded-b flex justify-between;
  top: 73px;
  z-index: 100;
}

.nav-link-container {
  @apply flex flex-col px-6;

  .nav-link:last-of-type {
    @apply border-b-0;
  }
}

.nav-link.text-link {
  @apply flex items-center justify-between p-4 no-underline border-b border-gray-lightest rounded cursor-pointer w-full font-semibold;
  &:hover {
    @apply bg-gray-lighter opacity-100;
  }
}

@media (max-width: 800px) and (min-width: 768px) {
  .nav-icon {
    margin-right: 0px;
  }
}

.flex-shrink-999 {
  // todo - there is likely a better way to do this
  flex-shrink: 999;
}

.logo-link:focus {
  box-shadow: none;
}

.logo {
  height: 36px;
}

.dev-banner {
  @apply text-white tracking-wide absolute rounded text-xs font-bold flex items-center;
  bottom: -8px;
  right: 0;
  z-index: 2;
}
</style>
