<template>
  <!-- Fullscreen menu when clicking menu button from top mobile nav -->
  <div class="mobile-menu-wrapper" :class="{ open: isOpen, closed: !isOpen && !initialLoad }">
    <div @click="closeMenu()" class="mobile-menu-overlay"></div>

    <div class="mobile-menu">
      <div class="h-full z-20">
        <UtilMenuHeader :title="$t('titles.menu')" @close="closeMenu()" />
        <WidgetUac
          v-if="appIs.auth"
          @click="profileClick()"
          :user="app.user"
          :company="app.company"
          :bordered="false"
          :showFlag="false"
          linkToProfile
          compact
          class="px-5 py-2 border-b border-gray-lighter"
        />

        <UtilMenu @close="closeMenu()" :menuItems="compMenuItems" class="overflow-y-auto mobile-menu-inner" />
      </div>

      <div class="mobile-menu-footer">
        <div class="flex justify-between items-center py-2 px-2">
          <div class="flex items-center">
            <img
              alt="SML logo white"
              v-lazy="require('@/assets/images/cached/cached-logo-green-icon.svg')"
              class="h-8 mr-2"
            />
            <small :class="appIs.isOutOfDate ? 'text-red-400' : 'text-gray'">
              v{{ $store.state.device.version }}
            </small>
          </div>

          <!-- <UtilLocale /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {faMobileAndroidAlt} from '@fortawesome/pro-regular-svg-icons'
  import {faApple, faGooglePlay} from '@fortawesome/free-brands-svg-icons'
  import UtilMenu from '@/components/utils/UtilMenu'
  import UtilMenuHeader from '@/components/utils/UtilMenuHeader'
  import WidgetUac from '@/components/widgets/WidgetUac'
  import {megaMenuItems} from '@/lib/helpers/navigation-items'
  import {mapGetters} from 'vuex'

  export default {
  name: 'LayoutMobileMenu',
  components: {
    UtilMenu,
    UtilMenuHeader,
    WidgetUac
  },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      breedSocieties: {},
      marts: {},
      agents: {},
      salesAndEvents: {},
      initialLoad: true
    }
  },
  created() {
    this.breedSocieties = {
      label: 'Breed Societies',
      active: true,
      children: this.paidBreedSocietyPortals.map(portal => ({
        label: portal.name,
        path: portal.to,
        logoFileRef: portal.logoFileRef
      }))
    }

    this.marts = {
      label: 'Marts',
      active: true,
      children: this.auctionPartners.map(portal => ({
        label: portal.name,
        path: portal.to,
        logoFileRef: portal.logoFileRef
      }))
    }

    this.agents = {
      label: 'Supply Chain & Agents',
      active: true,
      children: this.agentPortals.map(portal => ({
        label: portal.name,
        path: portal.to,
        logoFileRef: portal.logoFileRef
      }))
    }

    this.salesAndEvents = {
      label: 'Sales & Events',
      path: '/events',
      active: true
    }

    this.events
  },
  mounted() {
    this.initialLoad = false
  },
  computed: {
    ...mapGetters({
      auctionPartners: 'portals/getAuctionPartners',
      paidBreedSocietyPortals: 'portals/getPaidBreedSocietyPortals',
      agentPortals: 'portals/getAgentPortals'
    }),

    compMenuItems() {
      let menuItems = [
        // {
        //   label: 'Haulage',
        //   path: '/haulage',
        //   active: true
        // },
        {
          label: 'Memberships',
          path: '/membership-plans',
          active: true
        },
        {
          label: 'Marts & Partners',
          active: true,
          children: [this.breedSocieties, this.marts, this.agents, this.salesAndEvents]
        },
        {
          label: this.$t('nav.home'),
          path: '/',
          active: !this.appIs.auth
        },
        {
          label: this.$t('nav.myAccount'),
          click: () => {
            this.$modal.open('ModalAuthLogin')
            this.closeMenu()
          },
          active: !this.appIs.auth
        },
        {
          label: this.$t('nav.myAccount'),
          active: this.appIs.auth,
          children: [
            { label: 'Dashboard', path: '/account/dashboard', active: true },
            { label: 'Memberships', path: '/account/settings/membership', active: true },
            { label: 'Listings', path: '/account/listings', active: true },
            { label: 'Messages', path: '/account/messages', active: true },
            { label: 'Reviews', path: '/account/reviews', active: true },
            { label: 'Settings', path: '/account/settings', active: true }
          ]
        },
        {
          label: this.$t('nav.company'),
          active: true,
          children: [
            { label: 'Blog & Podcasts', path: '/blog', active: true },
            { label: 'About', path: '/about', active: true },
            { label: 'Terms & Privacy', path: '/terms', active: true },
            { label: 'Manage Cookies', path: '/manage-cookies', active: true }
          ]
        },
        {
          label: this.$t('nav.helpInfo'),
          path: '/help',
          active: true
        },
        {
          label: this.$t('auth.logout'),
          path: '/logout',
          active: this.appIs.auth
        }
      ]

      // Remove breed societies
      let listingItems = megaMenuItems.listings.filter(item => {
        return !item.hasDynamicChildren
      })

      // Add back in with correct data
      listingItems.push(this.breedSocieties, {
        label: 'View all livestock',
        active: true,
        path: '/search/livestock'
      })

      // Add browse listing items to the menu
      menuItems.unshift({
        label: 'Browse Listings',
        active: true,
        children: listingItems
      })

      return menuItems.filter(parent => parent.active)
    },

    compOsIcon() {
      if (this.app.device.operatingSystem === 'android') {
        return faGooglePlay
      } else if (this.app.device.operatingSystem === 'ios') {
        return faApple
      } else {
        return faMobileAndroidAlt
      }
    }
  },
  methods: {
    openMenu() {
      this.$store.commit('app/toggleMenuOpen', true)
      this.$store.dispatch('modal/handleModalOpen', `Modal${this.$options.name}`)
    },

    closeMenu() {
      this.$store.commit('app/toggleMenuOpen', false)
      this.$store.dispatch('modal/handleModalClose', `Modal${this.$options.name}`)
    },

    profileClick() {
      this.$analytics.addEvent({
        category: 'navigation',
        action: 'Clicked profile in mobile navigation'
      })

      this.closeMenu()
      this.$router.push('/profile')
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-menu-overlay {
  @apply bg-background opacity-75;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
}

.mobile-menu-wrapper.open .mobile-menu-overlay {
  display: block;
}

.mobile-menu {
  @apply flex flex-col justify-between;
  position: fixed;
  z-index: 21;
  width: 100%;
  left: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.mobile-menu-inner {
  height: calc(100% - 55px);
}

.mobile-menu-wrapper.open .mobile-menu {
  transform: translateX(-100%);
}

.mobile-menu-wrapper.closed .mobile-menu {
  transform: translateX(100%);
}

#app.ios .mobile-menu {
  padding-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
}

.mobile-menu-footer {
  @apply bg-white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: constant(safe-area-inset-bottom); /* Status bar height on iOS 11.0 */
  padding-bottom: env(safe-area-inset-bottom); /* Status bar height on iOS 11+ */
  z-index: 22;
}

@media screen and (min-height: 667px) {
  .mobile-menu-footer {
    bottom: 40px;
  }
}

@media screen and (min-height: 736px) {
  .mobile-menu-footer {
    bottom: 50px;
  }
}
</style>
