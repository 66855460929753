import router from '@/router'
import openGraphImage from '@/assets/images/meta/open-graph.jpg'

export const metaTitle = 'SellMyLivestock - The Online Livestock Marketplace'
export const metaDescription =
  'Buy and sell livestock, cattle, sheep on the online marketplace with SellMyLivestock. Connect with over 70,000 users across the UK'

export const generateMetaTags = ({ title, description, image } = {}) => {
  let currentRoute = router.currentRoute

  let meta = [
    { property: 'fb:page_id', content: '754641551216518', id: 'fb:page_id' },
    { name: 'twitter:card', content: 'summary_large_image', id: 'twitter:card' },
    { name: 'twitter:site', content: '@sellmylivestock', id: 'twitter:site' },
    { name: 'twitter:creator', content: '@HectareAgritech', id: 'twitter:creator' }
  ]

  if (title) {
    meta.push({ property: 'og:title', content: title, id: 'og:title' })
    meta.push({ property: 'twitter:title', content: title, id: 'twitter:title' })
  } else {
    // Check if the route has the title set
    title = currentRoute.meta.title || metaTitle

    meta.push({ property: 'og:title', content: title, id: 'og:title' })
    meta.push({ property: 'twitter:title', content: title, id: 'twitter:title' })
  }

  if (description) {
    description = description.trim().slice(0, 150)
    meta.push({ name: 'description', content: description, id: 'description' })
    meta.push({ property: 'og:description', content: description, id: 'og:description' })
    meta.push({ property: 'twitter:description', content: description, id: 'twitter:description' })
  } else {
    description = currentRoute.meta.description || metaDescription

    description = description.trim().slice(0, 150)

    meta.push({ name: 'description', content: description, id: 'description' })
    meta.push({ property: 'og:description', content: description, id: 'og:description' })
    meta.push({ property: 'twitter:description', content: description, id: 'twitter:description' })
  }

  if (image) {
    if (!image.includes('https://')) {
      image = `${process.env.VUE_APP_SITE_URL}${image}`
    }

    meta.push({ property: 'og:image', content: image, id: 'og:image' })
    meta.push({ property: 'twitter:image', content: image, id: 'twitter:image' })
  } else {
    meta.push({
      property: 'og:image',
      content: `${process.env.VUE_APP_SITE_URL}${openGraphImage}`,
      id: 'og:image'
    })
    meta.push({
      property: 'twitter:image',
      content: `${process.env.VUE_APP_SITE_URL}${openGraphImage}`,
      id: 'twitter:image'
    })
  }

  return meta
}
