<template>
  <label class="block form-label">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'FormLabel'
}
</script>

<style lang="scss" scoped>
.form-label {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
</style>
