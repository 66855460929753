<template>
  <div
    class="status"
   :class="memberStatus"
  >
    <span class="status-text">{{ compTitle }}</span>
  </div>
</template>

<script>
  export default {
    name: 'UtilMembershipStatus',

    props: {
      memberStatus: {
        type: String
      }
    },

    computed: {
      compTitle() {
        return this.memberStatus
      }
    }
  }
</script>

<style lang="scss" scoped>
  .status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;
    border-radius: 4px;

    &.bronze {
      background: #BE7202;
      border: 1px solid #905702;
    }

    &.silver {
      background: #DADADA;
      border: 1px solid #9C9B9A;
      color: #36414F;
    }

    &.gold {
      background: #FFD748;
      border: 1px solid #DDAD04;
      color: #614C01;
    }

    &.platinum {
      border: 1px solid rgba(154, 153, 152, 0.2);
      background: rgba(154, 153, 152, 0.95);
    }
  }

  .status-text {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
</style>
