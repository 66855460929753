import Vue from 'vue'
import { get } from 'lodash'
import SecurePaymentsApi from '@/lib/api/secure-payments'
import SecurePaymentModel from '@/lib/models/secure-payment'
import log from '@/plugins/log'

const initialState = () => {
  return {
    existingApi: new SecurePaymentsApi(),
    profileApi: new SecurePaymentsApi(),
    createApi: new SecurePaymentsApi(new SecurePaymentModel()),
    updateApi: new SecurePaymentsApi(),
    supportedBanksApi: new SecurePaymentsApi()
  }
}

const getters = {
  isListing: state => {
    return state.createApi.model.listing && state.createApi.model.listing.listingRef !== undefined
  },

  isBuyer: state => {
    return state.createApi.model.initiator === 'buyer'
  },

  isVerified: state => {
    return get(state.profileApi, 'data.flags.verified')
  },

  supportedBanks: state => {
    let providers = get(state.supportedBanksApi, 'data.results')

    return providers || []
  }
}

const actions = {
  async getExisting({ state, rootGetters }) {
    let appIs = rootGetters['app/appIs']

    if (!appIs.auth) {
      return false
    }

    try {
      await state.existingApi.get(
        '/payments/v2/transaction',
        {
          sortBy: { updatedAt: -1 }
        },
        {
          throttleMs: 5000
        }
      )
    } catch (error) {
      log.debug('Error loading secure payment transactions', error)
    }
  },

  async getProfile({ state, rootGetters }) {
    let appIs = rootGetters['app/appIs']

    if (!appIs.auth) {
      return false
    }

    await state.profileApi.get('/payments/v2/transaction/customer', undefined, { throttleMs: 2000 })
  },

  async getTrueLayerSupportedBanks({ state }) {
    await state.supportedBanksApi.get('/payments/v2/transaction/tl/providers')
  },

  async changeStatus({ state }, { transactionId, statusId }) {
    await state.updateApi.put(`/payments/v2/transaction/sp/${transactionId}/status/${statusId}`)
  },

  startSecurePaymentFromMessages(context, { listing, listingIs, otherParty, quantity, amount }) {
    if (otherParty) {
      // SP requires "email" as a root key so we need to manually map legacy format it before passing in
      let email = otherParty.emailAddresses ? otherParty.emailAddresses[0] : otherParty.emailAddress

      otherParty = { ...otherParty, email }
    }

    Vue.prototype.$modal.open('ModalSecurePayment', {
      initiator: listingIs.owner ? 'seller' : 'buyer',
      listing,
      otherParty,
      quantity,
      amount
    })
  }
}

const mutations = {
  resetCreate(state) {
    Object.assign(state.createApi, initialState().createApi)
  },

  reset(state) {
    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters
}
