import Vue from 'vue'
import { isNativeApp, platform } from '@/plugins/native-app/capacitor'
import appVersion from '@/lib/app-version'
import log from '@/plugins/log'
import store from '@/store'
import facebookPixel from '@/plugins/third-party/facebook-pixel'

// TODO: send to our own analytics service
const gaTrackingId = 'G-W7X3NWFDH6'
// const adwordsTrackingId = process.env.VUE_APP_GOOGLE_ADWORDS_TRACKING_ID

// If Cloudflare has set the bot cookie, let's not use analytics
const isBotVisit = document.cookie.indexOf('sml-bot=true') !== -1

// To maintain consistancy in event categories, they must be defined
// here and will show a warning if a dev tries to send a new category
// without adding it here first (or finding the correct spelling of a duplicate one)

// Try keep it in alpha order to make it easier to other devs to find a suitable category
const eventCategories = [
  'app',
  'account',
  'address',
  'advert',
  'auth',
  'bcms',
  'cookies',
  'dashboard',
  'events',
  'haulage',
  'help',
  'listing',
  'matchmaker',
  'membership',
  'messages',
  'modal',
  'native-app',
  'navigation',
  'notifications',
  'payments',
  'push-notifications',
  'portals',
  'profiles',
  'products',
  'reviews',
  'setup',
  'search',
  'settings',
  'shop',
  'uploads',
  'user',
  'verification'
]

const analytics = {
  install(vm) {
    vm.prototype.$analytics = {
      dimensions: {
        platform,
        appVersion,
        chosenLocale: 'en-GB'
      },
      customMap: { dimension1: 'platform', dimension2: 'appVersion', dimension3: 'chosenLocale' }
    }

    if (!isBotVisit) {
      // GA doesn't handle page changes automatically in native apps so let's manually send page views
      // Dimensions allow us to "tag" users which might help with filtering analytics later
      // https://developers.google.com/analytics/devguides/collection/gtagjs/custom-dims-mets
      if (isNativeApp) {
        window.gtag('set', 'allow_google_signals', false)
      }

      window.gtag('config', gaTrackingId, {
        send_page_view: false,
        custom_map: vm.prototype.$analytics.customMap
      })
    } else {
      console.log('Bot detected, not configuring GA')
    }

    vm.prototype.$analytics.updateConsent = (allowAnalytics, allowAdvertising) => {
      window.gtag('consent', 'update', {
        analytics_storage: allowAnalytics ? 'granted' : 'denied',
        ad_storage: allowAdvertising ? 'granted' : 'denied'
      })
    }

    vm.prototype.$analytics.addEvent = (
      { action, category, label = undefined, value = undefined, data = undefined },
      sendToSlack = false
    ) => {
      if (isBotVisit) {
        return false
      }

      if (!eventCategories.includes(category)) {
        const errorMessage = `You must pre-define "${category}" as a new event category in /plugins/analytics.js`
        console.error(errorMessage)

        // Alerting here so dev can't miss it during development
        alert(errorMessage)
        return
      }

      let appIs = store.getters['app/appIs']

      // Don't let events from staff members muddy the waters
      if (appIs.loggedInAsAnotherUser || appIs.loggedInAsHelpdesk || appIs.god) {
        return false
      }

      const { platform, appVersion, chosenLocale } = vm.prototype.$analytics.dimensions

      let gaData = {
        event_category: category,
        event_label: label,
        value,
        platform,
        appVersion,
        chosenLocale
      }

      // Send to GA
      window.gtag('event', action, gaData)

      if (process.env.VUE_APP_ENVIRONMENT === 'local') {
        log.debug('Sending GA event', { ...gaData, action })
      }

      if (category !== 'modal') {
        const fbData = value
          ? {
              value,
              currency: 'GBP'
            }
          : undefined

        facebookPixel.trackCustom(`${category} - ${action}`, fbData)
      }

      // Send to Slack
      if (sendToSlack) {
        let message = `${category} - ${action} ${label ? '- ' + label : ''} ${
          value ? '- £' + Vue.prototype.$numberFormat(value) : ''
        }`

        if (data) {
          message += ` - ${data}`
        }

        Vue.prototype.$slack.sendMessage(message, ['sml-activity'])
      }
    }

    vm.prototype.$analytics.addPageView = (title, path) => {
      if (isBotVisit) {
        return false
      }

      let appIs = store.getters['app/appIs']

      // Don't let events from staff members muddy the waters
      if (appIs.loggedInAsAnotherUser || appIs.loggedInAsHelpdesk || appIs.god) {
        return false
      }

      const { platform, appVersion, chosenLocale } = vm.prototype.$analytics.dimensions

      let gaData = {
        page_title: title,
        page_path: path,
        platform,
        appVersion,
        chosenLocale
      }

      if (isNativeApp) {
        window.gtag('set', 'allow_google_signals', false)
      }

      // window.gtag('config', gaTrackingId, gaData)
      window.gtag('event', 'page_view', gaData)

      if (process.env.VUE_APP_ENVIRONMENT === 'local') {
        log.debug('Sending GA page view', gaData)
      }

      facebookPixel.pageView()
    }

    vm.prototype.$analytics.setDimension = (dimension, value) => {
      if (!Object.keys(vm.prototype.$analytics.dimensions).includes(dimension)) {
        const errorMessage = `You must pre-define "${dimension}" as a new dimension in /plugins/analytics.js`
        console.error(errorMessage)

        // Alerting here so dev can't miss it during development
        alert(errorMessage)
        return
      }

      vm.prototype.$analytics.dimensions[dimension] = value
    }

    vm.prototype.$analytics.setUser = userRef => {
      if (isBotVisit || !store.state.cookies.allowAnalytics) {
        return false
      }

      if (isNativeApp) {
        window.gtag('set', 'allow_google_signals', false)
      }

      window.gtag('config', gaTrackingId, {
        user_id: userRef
      })
    }
  }
}

Vue.use(analytics)
