<template>
  <!-- ios has no statusbar, we need to create one -->
  <div
    class="ios-status-bar"
    :class="
      !appIs.auth && $store.state.device.hasFinishedNativeSplashScreen
        ? 'white'
        : $store.state.device.statusBarColor
    "
  />
</template>

<script>
export default {
  name: 'LayoutIosStatusBar'
}
</script>

<style lang="scss" scoped>
.ios-status-bar {
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  right: 0px;
  height: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
  height: env(safe-area-inset-top);
}

.ios-status-bar.white {
  @apply bg-white;
}

.ios-status-bar.green {
  @apply bg-brand-darker;
}
</style>
