<template>
  <UtilTextLink :to="to" @click="$emit('click')" :class="!logoFileRef ? 'justify-between': ''" class="menu-item no-underline">
    <UtilImage
      v-if="logoFileRef"
      :fileRef="logoFileRef"
      fullHeight
      showOriginal
      :isLazyLoaded="false"
      class="flex-shrink-0 mr-4 rounded-full h-10 w-10 overflow-hidden"
    />
    
    <span class="pr-4 truncate text-left">
      <FontAwesomeIcon v-if="prefixIcon" :icon="prefixIcon" class="mr-2" fixed-width />
      <span class="pr-3">{{ label }}</span>
    </span>

    <FontAwesomeIcon v-if="hasChildren || suffixIcon" :icon="suffixIcon || faChevronRight" class="menu-icon" />

    <slot />
  </UtilTextLink>
</template>

<script>
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import UtilTextLink from '@/components/utils/UtilTextLink'
import UtilImage from '@/components/utils/UtilImage'

export default {
  name: 'UtilMenuItem',
  components: {
    UtilTextLink,
    UtilImage
  },
  props: {
    label: String,
    to: String,
    suffixIcon: Object,
    prefixIcon: Object,
    hasChildren: Boolean,
    logoFileRef: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      faChevronRight
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-icon {
  @apply text-gray;
}

.menu-item {
  @apply flex items-center;
  @apply px-5 py-3;
  @apply border-b border-gray-lighter;
  z-index: 10;

  &:hover {
    @apply bg-gray-lightest rounded-tl;
    cursor: pointer;
    opacity: 1;

    & > .menu-icon {
      @apply text-gray-darker;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}
</style>
