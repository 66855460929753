import axios from 'axios'
import ApiCore from './core'
import { platform } from '@/plugins/native-app/capacitor'
import {
  forEach,
  mapValues,
  isObject,
  isPlainObject,
  mapKeys,
  camelCase,
  upperFirst,
  isArrayLikeObject
} from 'lodash'

class LegacyApi extends ApiCore {
  constructor(model, timeout = 30000) {
    super(model, timeout)

    // Before the API call is made, uppercase the first letter our model keys
    // As this is what the legacy API expects
    this.axios.interceptors.request.use(config => {
      const store = require('@/store').default

      let dataKey = config.method === 'get' ? 'params' : 'data'

      config.baseURL = process.env.VUE_APP_LEGACY_API_BASE_URL
      // The legacy API rejects the request if the Accept header
      // Doesn't have */* so let's just remove it from the default...
      config.headers = {
        'Content-Type': 'application/json'
      }

      config[dataKey]['Token'] = store.state.auth.token
      config[dataKey]['HID'] = 'sellmylivestock'
      config[dataKey][`${platform}Version`] = store.state.device.version

      config[dataKey] = ucfirstKeys(config[dataKey])

      // Convert object to FormData
      if (config.isFormData) {
        let formData = new FormData()

        for (let fieldKey in config[dataKey]) {
          console.log('Form data conversion')
          console.log(fieldKey, config[dataKey][fieldKey])
          formData.append(fieldKey, config[dataKey][fieldKey])
        }

        config[dataKey] = formData
      } else if (dataKey === 'data') {
        config[dataKey] = JSON.stringify(config[dataKey])
      }

      return config
    })

    // The legacy API returns 200 even if there's an error. We need to catch that here
    // and throw and error to allow the normal error handling in core to continue
    this.axios.interceptors.response.use(response => {
      // Keep an eye on our API requests in dev mode
      // if (process.env.VUE_APP_ENVIRONMENT === 'local') {
      //   // Don't log files.add it will make the app in iTunes freeze up as it tries to log base64
      //   if (response.request.responseURL && !response.request.responseURL.includes('files.add')) {
      //     console.warn(response.request.responseURL, response)
      //   }
      // }

      if (typeof response.data.count !== 'undefined') {
        this.count = response.data.count
      }

      if (typeof response.data.count !== 'undefined') {
        this.totalAvailable = response.data.totalcount
      }

      if (typeof response.data.pagination !== 'undefined') {
        this.pagination = response.data.pagination
      }

      if (response.data.status === 'error') {
        // Let's move any validation errors into the main error message
        if (Array.isArray(response.data.data) && response.data.data.length) {
          let errorMessage = '<ul>'

          response.data.data.forEach(fieldError => {
            errorMessage += `<li>${parseFieldName(fieldError.fieldName)} - ${fieldError.message}</li>`
          })

          errorMessage += `</ul> ${response.data.message}`

          response.data.message = errorMessage
        }

        return Promise.reject(response)
      }

      return response
    })

    // We don't want to work with ucfirst keys so let's camelCase the APIs response
    this.hooks.transformResponse = axios.defaults.transformResponse.concat(data => {
      const store = require('@/store').default

      // If the response includes a token let's set it in the auth store for all other calls
      if (data.token) {
        store.commit('auth/setToken', data.token)
      }

      return camelCaseKeys(data)
    })
  }
}

// Some times field names come through wrapped in square brackets...
const parseFieldName = fieldName => {
  if (!fieldName.includes('[')) {
    return fieldName
  }

  return fieldName.substring(fieldName.lastIndexOf('[') + 1, fieldName.lastIndexOf(']'))
}

const ucfirstKeys = obj => {
  return deep(obj, x => {
    return mapKeys(x, (val, key) => {
      return upperFirst(key)
    })
  })
}

const camelCaseKeys = obj => {
  return deep(obj, x => {
    return mapKeys(x, (val, key) => {
      return camelCase(key)
    })
  })
}

const deep = (obj, mapper) => {
  return mapper(
    mapValues(obj, v => {
      let response = false
      let array = []

      if (isPlainObject(v)) {
        response = deep(v, mapper)
      } else if (isArrayLikeObject(v)) {
        forEach(v, value => {
          if (isObject(value)) {
            array.push(deep(value, mapper))
          } else {
            array.push(value)
          }
        })

        response = array
      } else {
        response = v
      }

      return response
    })
  )
}

export default LegacyApi
