import { platform, isNativeApp } from '@/plugins/native-app/capacitor'
import { StatusBar, Style } from '@capacitor/status-bar'

if (isNativeApp) {
  StatusBar.setStyle({
    style: Style.Dark
  })

  if (platform === 'ios') {
    // iOS statusbar tap, scroll to top
    window.addEventListener('statusTap', () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    })
  }

  // if (platform === 'android') {
  //   StatusBar.setBackgroundColor({
  //     color: '#FFFFFF'
  //   })
  // }
}
