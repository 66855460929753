import log from '@/plugins/log'

let initialized = !!window.fbq
let debug = false

const verifyInit = () => {
  if (!initialized && debug) {
    log.debug('Pixel not initialized')
  }

  return initialized
}

const defaultOptions = {
  autoConfig: false,
  debug: process.env.VUE_APP_ENVIRONMENT === 'local'
}

export default {
  init(pixelId, advancedMatching = {}, options = defaultOptions) {
    /* eslint-disable */
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = true
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
    /* eslint-enable */

    if (!pixelId) {
      log.warn('Please insert pixel id for initializing')
    } else {
      if (options.autoConfig === false) {
        window.fbq('set', 'autoConfig', false, pixelId)
      }

      window.fbq('init', pixelId, advancedMatching)

      initialized = true
      debug = options.debug

      this.pageView()
    }
  },

  pageView() {
    this.fbq('track', 'PageView')
  },

  track(title, data) {
    this.fbq('track', title, data)
  },

  trackCustom(event, data) {
    this.fbq('trackCustom', event, data)
  },

  grantConsent() {
    this.fbq('consent', 'grant')
  },

  revokeConsent() {
    this.fbq('consent', 'revoke')
  },

  fbq(...args) {
    if (!verifyInit()) {
      return
    }

    window.fbq(...args)

    if (debug) {
      log.debug(`fbq('${args.slice(0, 2).join("', '")}')`)

      if (args[2]) {
        log.debug('with data', args[2])
      }
    }
  }
}
