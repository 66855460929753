import { capitalize, startCase } from 'lodash'
import commodities, { topLevelCommodities } from '@/lib/helpers/commodities'
import LegacyApi from '@/lib/api/legacy'
import log from '@/plugins/log'

let searchRoutes = [
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
    meta: {
      showTicker: true,
      filters: '',
      childCommodity: '' // required for the category drop down to be "please select"
    }
  },
  {
    path: '/search/query/:searchQuery',
    name: 'search-query',
    component: () => import(/* webpackChunkName: "search-query" */ '@/views/SearchQuery.vue')
  }
]

let nations = [
  {
    name: 'scotland',
    algolia: "commodity:livestock AND location.country:'Scotland'"
  },
  {
    name: 'ireland',
    algolia: "commodity:livestock AND location.country:'Republic of Ireland' AND owner.business:'Grasstec UK'"
  },
  {
    name: 'wales',
    algolia: "commodity:livestock AND location.country:'Wales'"
  }
]

nations.forEach(nationData => {
  searchRoutes.push({
    path: `/${nationData.name}`,
    name: `search-${nationData.name}`,
    component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
    meta: {
      geoNation: nationData.name,
      filters: '', // _tags:angus
      childCommodity: '', // required for the category drop down to be "please select"
      algolia: nationData.algolia,
      title: `Livestock for sale in ${capitalize(nationData.name)}`,
      description: `View livestock for sale in ${capitalize(
        nationData.name
      )}. Register with SellMyLivestock to see breeders and farmers with livestock to sell. Arrange a viewing today.`
    }
  })
})

commodities.forEach(commodity => {
  let meta = {
    algolia: commodity.algolia,
    childCommodity: commodity.childCommodity ? commodity.slug : '',
    commodity: commodity.topLevelCommodity ? commodity.slug : commodity.listing.commodity,
    species: commodity.listing ? commodity.listing.species : ''
  }

  searchRoutes.push({
    name: `search-${commodity.name.toLowerCase()}`,
    path: `/${commodity.slug}`,
    alias: `/search/${commodity.slug}`,
    component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
    meta,
    children: [
      {
        name: `${commodity.name}-geo`,
        path: 'geo/:country/:region?',
        component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
        meta: {
          ...meta,
          geo: true
        }
      }
    ]
  })

  // For sale SEO routes
  searchRoutes.push({
    name: `search-${commodity.name.toLowerCase()}-for-sale`,
    path: `/${commodity.slug}/for-sale`,
    component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
    meta: {
      ...meta,
      title: `${commodity.name} For Sale`,
      description: `View ${commodity.name} for sale in the UK. Register with SellMyLivestock to see breeders and farmers with ${commodity.name} to sell. Arrange a viewing today.`,
      algolia: `${meta.algolia} AND tradeType:'For Sale'`
    }
  })

  // Wanted SEO routes
  searchRoutes.push({
    name: `search-${commodity.name.toLowerCase()}-wanted`,
    path: `/${commodity.slug}/wanted`,
    component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
    meta: {
      ...meta,
      title: `Wanted ${commodity.name}`,
      description: `View ${commodity.name} wanted in the UK. Register with SellMyLivestock to see breeders and farmers with ${commodity.name} to sell. Arrange a viewing today.`,
      algolia: `${meta.algolia} AND tradeType:Wanted`
    }
  })

  // Handle sitemap category pages
  searchRoutes.push({
    path: `/${commodity.slug}/category/:lvl0/:lvl1?`,
    name: `${commodity.slug}-category`,
    component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
    meta: {
      algolia: commodity.algolia,
      childCommodity: commodity.childCommodity ? commodity.slug : '',
      commodity: commodity.topLevelCommodity ? commodity.slug : commodity.listing.commodity
    },
    beforeEnter: async (to, from, next) => {
      let categoryLevel = to.params.lvl1 ? to.params.lvl1 : to.params.lvl0

      let categoryApi = new LegacyApi({
        commodity: commodity.slug,
        categories: [categoryLevel]
      })

      try {
        await categoryApi.get('categories.get')

        let category = categoryApi.data[0]

        to.meta.category = category

        if (category.parentSlug) {
          if (category.parentSlug === to.params.lvl0) {
            to.meta.algolia = `${commodity.algolia} AND categories.lvl1:'${category.algolia}'`
          } else {
            log.error(`Parent slug does not equal lvl0 - ${category.parentSlug} -> ${to.params.lvl0}`)
            window.location.href = '/not-found'
            return
          }

          to.meta.title = `${category.name} ${startCase(category.parentSlug)} For Sale`

          to.meta.description = `View ${category.name} ${startCase(
            category.parentSlug
          )} for sale in the UK. Register with SellMyLivestock to see breeders and farmers with ${
            category.name
          } ${startCase(category.parentSlug)} to sell. Arrange a viewing today.`
        } else {
          to.meta.algolia = `${commodity.algolia} AND categories.lvl0:'${category.algolia}'`

          to.meta.title = `${category.name} For Sale`
          to.meta.description = `View ${category.name} for sale in the UK. Register with SellMyLivestock to see breeders and farmers with ${category.name} to sell. Arrange a viewing today.`
        }

        next()
      } catch (error) {
        log.error(`Failed to render /${commodity.slug}/category/:lvl0/:lvl1?`, error)
        window.location.href = '/not-found'
      }
    }
  })
})

topLevelCommodities.forEach(commodity => {
  searchRoutes.push({
    name: `popular-${commodity.name}`,
    path: `/${commodity.slug}/popular/:tag`,
    component: () => import(/* webpackChunkName: "search" */ '@/views/Search.vue'),
    meta: {
      popular: true,
      commodity: commodity.slug
    }
  })
})

export default searchRoutes
