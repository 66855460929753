<template>
  <RouterLink
    v-if="to"
    :to="to"
    class="btn"
    :class="[
      compColorClass,
      compSizeClass,
      {
        disabled: loading || disabled,
        loading,
        block: block,
        'btn-icon-only': iconOnly,
        'btn-link': textLink
      }
    ]"
    :name="name"
    @click.native="$emit('click')"
  >
    <slot />
  </RouterLink>

  <button
    v-else
    class="btn"
    :type="type"
    @click="$emit('click')"
    :class="[
      compColorClass,
      compSizeClass,
      {
        disabled: loading || disabled,
        loading,
        block: block,
        'btn-icon-only': iconOnly,
        'btn-link': textLink
      }
    ]"
    :name="name"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'UtilButton',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    to: {
      type: String
    },
    color: {
      type: String
    },
    large: Boolean,
    small: Boolean,
    textLink: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    compColorClass() {
      switch (this.color) {
        case 'primary':
          return this.textLink
            ? 'text-primary-button'
            : 'bg-primary-button border-primary-button hover:bg-primary-button-lighter hover:border-primary-button-lighter text-primary'

        case 'secondary':
          return this.textLink
            ? 'text-secondary'
            : 'bg-secondary hover:bg-secondary-darker border-secondary text-white'

        case 'brand':
          return this.textLink
            ? 'text-brand'
            : 'bg-brand border-brand hover:bg-brand-darker hover:border-brand-darker text-white'

        case 'danger':
          return this.textLink ? 'text-danger' : 'bg-danger border-danger text-white'

        case 'warning':
          return this.textLink ? 'text-warning' : 'bg-warning border-warning'

        case 'gray':
          return this.textLink ? 'text-gray' : 'bg-gray'

        case 'white':
          return this.textLink
            ? 'text-white hover:opacity-75 hover:text-white focus:text-white'
            : 'bg-white border-white text-primary'

        case 'orange':
          return 'border border-orange-700 bg-orange-600 hover:bg-orange-700 text-white'

        case 'outline':
          return 'border border-secondary'

        // We don't want to enforce defaults to give parents more manual control if required
        default:
          return ''
      }
    },
    compSizeClass() {
      if (this.large) {
        return 'btn-large'
      }

      if (this.small) {
        return 'btn-small'
      }

      return ''
    }
  }
}
</script>
