import Vue from 'vue'
import FloatingVue from 'floating-vue'

Vue.use(FloatingVue, {
  themes: {
    'info-tooltip': {
      $extend: 'tooltip',
      $resetCss: true
    }
  }
})
