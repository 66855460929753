export default {
  POPULAR: {
    text: 'Popular Now',
    value: `${process.env.VUE_APP_ALGOLIA_INDEX_PREFIX}listings_ranking`
  },
  DISTANCE: {
    text: 'Distance',
    value: `${process.env.VUE_APP_ALGOLIA_INDEX_PREFIX}listings`
  },
  START_DATE: {
    text: 'Newest first',
    value: `${process.env.VUE_APP_ALGOLIA_INDEX_PREFIX}listings_startDate`
  },
  QUANTITY: {
    text: 'Quantity',
    value: `${process.env.VUE_APP_ALGOLIA_INDEX_PREFIX}listings_quantityStart`
  }
}
