<template>
  <ValidationObserver ref="observer" v-slot="{ errors }" tag="form" @submit.prevent="submit()">
    <slot :formErrors="errors" :formSubmissionAttempted="formSubmissionAttempted"></slot>
  </ValidationObserver>
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'Form',
  data() {
    return {
      formSubmissionAttempted: false
    }
  },
  methods: {
    async submit() {
      this.formSubmissionAttempted = true

      const isValid = await this.$refs.observer.validate()

      console.log('Form valid?', isValid)

      if (!isValid) {
        // If we have a form error report component active let's scroll it in to view
        if (document.querySelector('.form-error-report')) {
          this.$scroll.intoViewIfNeeded(document.querySelector('.form-error-report'))
        }

        // We don't often use the emitted event as we pass formValidation as a prop
        // for displaying inline errors, but we emit the errors here anyway in case it's useful
        // in future to trigger a method or something on the parent
        return this.$emit('validationFailed', get(this.$refs, 'observer.ctx.errors'))
      }

      this.$emit('submit')
    },

    clearErrors() {
      this.$refs.observer.reset()
    }
  }
}
</script>
