<template>
  <p v-if="hint || error || $slots.hint || $slots.default" class="form-input-hint inline-block text-sm mb-0 pt-1">
    <span v-if="error">{{ `${error} ` }}</span>

    <span v-if="hint" class="block hint">{{ hint }}</span>

    <slot name="hint"></slot>

    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'FormInputHint',
  props: {
    hint: {
      type: String
    },
    error: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.form-input-hint {
  .has-success &,
  .is-success + & {
    @apply text-success;
  }

  .has-error &,
  .is-error + & {
    @apply text-danger;
  }
}

.form-input-hint {
  @apply text-gray-darker;
}
</style>
