import Vue from 'vue'
import { isNativeApp, Plugins } from '@/plugins/native-app/capacitor'

const openWindow = {
  install(vm) {
    vm.prototype.$openWindow = async (url, openInAppIfAvailable = false) => {
      // If using native and we want to open with the overlayed in-app browser
      if (openInAppIfAvailable && isNativeApp && Plugins) {
        const { Browser } = Plugins

        let nativeInAppBrowserWindow = await Browser.open({ url })

        return nativeInAppBrowserWindow
      }

      console.log('Opening window', url)

      // If native switch to and open with system browser, otherwise new tab
      const target = isNativeApp ? null : '_blank'

      window.open(url, target)
    }
  }
}

Vue.use(openWindow)
