var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.activeParentItem)?_c('a',{staticClass:"menu-back",on:{"click":function($event){return _vm.clearActive()}}},[_c('FontAwesomeIcon',{staticClass:"mr-2",attrs:{"icon":_vm.faChevronLeft}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('nav.back')))])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mobile-items-section overflow-y-auto scrolling-touch"},[(!_vm.compIsStacked || (_vm.compIsStacked && !_vm.activeParentItem))?[_vm._l((_vm.menuItems),function(item){return _c('UtilMenuItem',{key:("parent-" + (item.label)),attrs:{"label":item.label,"to":item.path,"hasChildren":item.children && item.children.length > 0},on:{"click":function () {
            if (item.click) {
              item.click()
              return
            }

            if (item.path) {
              _vm.$emit('close')
            } else {
              _vm.setActiveItem(item.label, 'parent')
            }
          }}},[(item.children && !_vm.compIsStacked)?_c('span',{staticClass:"menu-subnav overflow-y-auto scrolling-touch",class:[_vm.activeParentItem === item.label ? 'block' : 'hidden']},_vm._l((item.children),function(subItem){return _c('UtilMenuItem',{key:("child-" + (subItem.label)),attrs:{"label":subItem.label,"to":subItem.path},on:{"click":function($event){return _vm.$emit('close')}}})}),1):_vm._e()])}),_vm._v(" "),_c('div',{staticClass:"p-2"},[_c('UtilButton',{staticClass:"mt-4 mr-2",attrs:{"to":"/post","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("\n          "+_vm._s(_vm.$t('nav.post'))+"\n        ")]),_vm._v(" "),(!this.appIs.native)?_c('UtilButton',{staticClass:"mt-4",attrs:{"to":"/app-download","color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("\n          "+_vm._s(_vm.$t('nav.downloadMobileApp'))+"\n        ")]):_vm._e()],1)]:_vm._e(),_vm._v(" "),(_vm.compIsStacked && _vm.activeParentItem && _vm.compActiveSubMenuItems.length)?_c('div',_vm._l((_vm.compActiveSubMenuItems),function(subItem){return _c('UtilMenuItem',{key:("mobile-child-" + (subItem.label)),attrs:{"label":subItem.label,"to":subItem.children ? null : subItem.path,"hasChildren":subItem.children && subItem.children.length > 0,"logoFileRef":subItem.logoFileRef || ''},on:{"click":function () {
            if (subItem.click) {
              subItem.click()
              return
            }

            if (subItem.path  && !subItem.children) {
              _vm.$emit('close')
            } else {
              _vm.setActiveItem(subItem.label, _vm.activeChildItem ? 'grandchild' : 'child')
            }
          }}})}),1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }