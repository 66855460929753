export default {
  LIVE: 'L',
  DRAFT: 'D',
  PENDING: 'P',
  FINISHED: 'F',
  SOLD: 'X',
  SOLD_ALT: 'S',
  WITHDRAWN: 'W'
}

export const listingStatusCodeToHuman = {
  L: 'Live',
  D: 'Draft',
  P: 'Pending',
  F: 'Finished',
  X: 'Sold',
  S: 'Sold',
  W: 'Withdrawn'
}

export const listingStatusFilters = value => {
  return listingStatusCodeToHuman[value]
}
