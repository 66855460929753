import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app.module'
import account from './modules/account.module'
import stats from './modules/stats.module'
import auth from './modules/auth.module'
import categories from './modules/categories.module'
import haulage from './modules/haulage.module'
import listing from './modules/listing.module'
import locale from './modules/locale.module'
import modal from './modules/modal.module'
import message from './modules/message.module'
import messages from './modules/messages.module'
import device from './modules/device.module'
import payments from './modules/payments.module'
import securePayments from './modules/secure-payments.module'
import geo from './modules/geo.module'
import notifications from './modules/notifications.module'
import watchlist from './modules/watchlist.module'
import search from './modules/search.module'
import storage from './modules/storage.module'
import cart from './modules/cart.module'
import portals from './modules/portals.module'
import recommendations from './modules/recommendations.module'
import adverts from './modules/adverts.module'
import shop from './modules/shop.module'
import bcms from './modules/bcms.module'
import cookies from './modules/cookies.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    account,
    stats,
    auth,
    categories,
    haulage,
    listing,
    locale,
    modal,
    message,
    messages,
    device,
    payments,
    securePayments,
    geo,
    notifications,
    watchlist,
    search,
    storage,
    cart,
    portals,
    recommendations,
    adverts,
    shop,
    bcms,
    cookies
  },
  strict: false
})
