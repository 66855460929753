<template>
  <div
    id="app"
    :class="[
      `${app.platform} ${$breakpoint.classList}`,
      { isAuth: appIs.auth, isMobileOrNative: appIs.mobileOrNative }
    ]"
    :data-breakpoint="$mq"
  >
    <MaintenanceMode v-if="maintenanceMode" />

    <template v-else>
      <LayoutIosStatusBar v-if="app.platform === 'ios'" />

      <LayoutNavTop />

      <!-- todo Probably a better way to do this within the actual nav component-->
      <UtilSearchBar v-if="$breakpoint.smAndDown || appIs.mobileOrNative" class="px-4 py-2 bg-brand-darker" />

      <div class="body-content">
        <UtilWarningOutdatedBrowser class="my-4" />

        <LayoutFloatingNotifications />

        <LayoutNativeSplashScreen v-if="appIs.native && showNativeSplashScreen" ref="splashScreen" />

        <UtilLoader
          :isLoading="compIsAppLoading"
          longLoadingTextClass="text-primary"
          :class="{ 'container py-16 px-0 h-screen': compIsAppLoading }"
        >
          <router-view />
        </UtilLoader>
      </div>

      <WidgetCookieConsent />

      <LayoutNavBottom v-if="$breakpoint.smAndDown || appIs.mobileOrNative" />

      <LayoutFooter v-else />

      <keep-alive v-for="(modal, index) in $store.state.modal.list" :key="`modal-${modal.modalName}`">
        <component v-if="index === 0" :is="modal.component" v-bind="{ modalData: modal.modalData }" />
      </keep-alive>

      <div id="confirm-portal" />
    </template>
  </div>
</template>

<script>
  import axios from 'axios'
  import {getUrlParam} from '@/lib/url-params'
  import {registerServiceWorker} from '@/plugins/service-worker/service-worker-register'
  import logRocket from '@/plugins/third-party/log-rocket'
  import {generateMetaTags, metaTitle} from '@/lib/generate-meta'
  import LayoutFloatingNotifications from '@/components/layout/LayoutFloatingNotifications'
  import LayoutFooter from '@/components/layout/LayoutFooter'
  import LayoutIosStatusBar from '@/components/layout/LayoutIosStatusBar'
  import LayoutNativeSplashScreen from '@/components/layout/LayoutNativeSplashScreen'
  import UtilLoader from '@/components/utils/UtilLoader'
  import UtilWarningOutdatedBrowser from '@/components/utils/UtilWarningOutdatedBrowser'
  import MaintenanceMode from '@/views/MaintenanceMode'
  import WidgetCookieConsent from '@/components/widgets/WidgetCookieConsent'
  import LayoutNavTop from '@/components/layout/navigation/LayoutNavTop'
  import UtilSearchBar from '@/components/utils/search/UtilSearchBar'

  export default {
  name: 'app',

  components: {
    LayoutFooter,
    LayoutFloatingNotifications,
    LayoutIosStatusBar,
    LayoutNativeSplashScreen,
    UtilLoader,
    UtilWarningOutdatedBrowser,
    MaintenanceMode,
    WidgetCookieConsent,
    LayoutNavBottom: () => import('@/components/layout/navigation/LayoutNavBottom'),
    LayoutNavTop,
    UtilSearchBar
  },

  data() {
    return {
      showNativeSplashScreen: true,
      maintenanceMode: false,
      startTime: null,
      statusCheckTimer: null,
      impressionCacheTimer: null
    }
  },

  created() {
    this.initialLoad()

    this.startSessionRecording()
  },

  mounted() {
    // These need to happen when the DOM is ready
    if (this.appIs.native) {
      this.startTime = this.$date()
    }

    if (this.app.platform === 'web') {
      this.$store.dispatch('device/setupFocusChangeHandler')
    }

    this.$store.dispatch('device/setupConnectionChangeHandler')

    if (this.appIs.ios) {
      setTimeout(() => this.$store.dispatch('payments/setupNativeIAPRegistration'), 2000)
    }

    // Only load scripts when the page has fully loaded
    if (document.readyState === 'complete') {
      this.setupServiceWorker()
    } else {
      window.addEventListener('load', () => {
        this.setupServiceWorker()
      })
    }
  },

  computed: {
    compIsAppLoading() {
      return this.$store.state.app.routeLoading || !!this.$store.state.auth.singleUseToken
    }
  },

  methods: {
    // These can happen before the DOM is ready and mounted
    async initialLoad() {
      // Is single use token in the URL? We can't use $route/$router here because they may not have been initialised yet
      // Don't do anything if the user is already logged in because
      if (!this.appIs.auth) {
        const singleUseToken = getUrlParam('token')

        if (singleUseToken) {
          await this.$store.dispatch('auth/loginWithSingleUsetoken', singleUseToken)

          let query = Object.assign({}, this.$route.query)
          delete query.token
          this.$router.replace({ query })
        }
      }

      await this.checkStatus()

      // Internet connection tester
      this.$store.dispatch('device/checkConnection')

      this.$store.dispatch('device/getInfo')

      // If we have a locale loaded from localStorage then we need to
      // manually set it in the various plugins otherwise they won't see
      // a change until the user manually changes language
      if (this.$store.state.locale.language) {
        this.$store.dispatch('locale/setLanguage', this.$store.state.locale.language)
      } else {
        // Set our locale against Sentry
        this.$store.dispatch('locale/tagLanguage')
      }

      await Promise.all([
        this.$store.dispatch('app/get'),
        this.$store.dispatch('payments/getPlans', null, { root: true })
      ])

      this.$store.dispatch('payments/fetchRestrictedFeaturesUsage')

      this.nativeAppLoad()

      this.$store.dispatch('portals/get')

      this.statusCheckTimer = setInterval(this.checkStatus, 60000)
      this.impressionCacheTimer = setInterval(() => this.$store.dispatch('adverts/sendImpressionsCache'), 5000)
    },

    setupServiceWorker() {
      // Check for older browsers and spotty es6 support. Don't register on the dashboard as we already have a registration there during push setup
      if (
        !this.appIs.native &&
        'serviceWorker' in navigator &&
        this.$route.name !== 'account-dashboard' &&
        !this.appIs.legacyBrowser
      ) {
        this.$log.debug('Setting up app load service worker')
        registerServiceWorker().catch(() => {})
      }
    },

    async checkStatus() {
      try {
        // If we are offline we still need to get rid of the native app splash screen
        let status = await axios.get(process.env.VUE_APP_PING_URL, {
          timeout: 10000
        })

        this.maintenanceMode = status.data.isMaintenanceModeEnabled

        let { isMaintenanceModeEnabled, isMaintenanceModeBypassed } = status.data

        this.$store.commit('app/setMaintenanceMode', { isMaintenanceModeEnabled, isMaintenanceModeBypassed })

        if (isMaintenanceModeEnabled) {
          // Since we are now in maintenance mode let's check the status more often
          setTimeout(this.checkStatus, 10000)
        }
      } catch (error) {
        // Possible network error, don't jump to conclusions about maintenance mode
      }
    },

    async nativeAppLoad() {
      // Hide splash screen on native app load
      if (this.appIs.native) {
        // Now the first app.get has finished loading we need to see if we
        // are authed, and if not then show login modal
        await this.$store.dispatch('auth/checkNativeLogin')

        // Give our sexy animation time to show and for the user to feast their eyes on our brand logos
        let splashActiveSeconds = this.$date().diff(this.startTime, 'second')

        splashActiveSeconds = splashActiveSeconds || 0

        if (splashActiveSeconds < 2) {
          setTimeout(() => {
            this.hideSplashScreen()
          }, 2000 - splashActiveSeconds * 1000)
        } else {
          this.hideSplashScreen()
        }

        // Save a flag that we've used the native app to hide banners around site about it
        if (this.appIs.auth) {
          this.$store.dispatch('storage/save', {
            key: 'nativeAppUser',
            value: this.app.platform
          })
        }
      }
    },

    hideSplashScreen() {
      this.showNativeSplashScreen = false
      this.$store.commit('device/setFinishedNativeSplashScreen')
    },

    startSessionRecording() {
      if (process.env.VUE_APP_REPORT_ERRORS === 'true' && this.appIs.native) {
        logRocket.init()
      }
    }
  },

  head: {
    title() {
      return {
        inner: metaTitle
      }
    },
    meta() {
      return generateMetaTags()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/app.scss';

#app.mdAndUp {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#app.mdAndUp .body-content {
  flex: 1 0 auto;
}

#app.mdAndUp footer {
  flex-shrink: 0;
}
</style>
