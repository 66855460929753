import Vue from 'vue'
import router from '@/router'
import thirdPartyPlugins from '@/plugins/third-party'

// We store in both localstorage and app.storage because we also need to support non logged in users
// The initial page load flow is:
// 1. Read from localstorage on page load and set some state defaults
// 2. Wait for WidgetCookieConsent to detect app.storage has finished initial load
// 3. Read preferences either from localstorage if guest or app.storage for authed users
// 4. Load third party scripts based on preferences

const COOKIE_ANALYTICS_STORAGE_KEY = 'sml-cookie-allow-analytics'
const COOKIE_ADVERTISING_STORAGE_KEY = 'sml-cookie-allow-advertising'

const existingGuestAnalyticsDecision = window.localStorage[COOKIE_ANALYTICS_STORAGE_KEY]
const existingGuestAdvertisingDecision = window.localStorage[COOKIE_ADVERTISING_STORAGE_KEY]

const initialState = () => {
  return {
    hasLoadedThirdPartyScripts: false,
    hasGuestSetPreferences: false,
    allowAnalytics:
      typeof existingGuestAnalyticsDecision !== 'undefined' ? JSON.parse(existingGuestAnalyticsDecision) : false,
    allowAdvertising:
      typeof existingGuestAdvertisingDecision !== 'undefined'
        ? JSON.parse(existingGuestAdvertisingDecision)
        : false
  }
}

const getters = {
  hasAlreadyCompletedForm(state, getters, rootState, rootGetters) {
    return (
      state.hasGuestSetPreferences ||
      rootGetters['storage/hasKey'](COOKIE_ANALYTICS_STORAGE_KEY) ||
      typeof existingGuestAnalyticsDecision !== 'undefined'
    )
  }
}

const actions = {
  loadExistingConsent({ state, commit, dispatch, rootState, rootGetters }) {
    let allowAnalytics = state.allowAnalytics
    let allowAdvertising = state.allowAdvertising

    // Now app.storage has loaded let's check against otherwise continue with local options for guest users
    if (rootGetters['storage/hasKey'](COOKIE_ANALYTICS_STORAGE_KEY)) {
      allowAnalytics = JSON.parse(rootState.storage.data[COOKIE_ANALYTICS_STORAGE_KEY])
      allowAdvertising = JSON.parse(rootState.storage.data[COOKIE_ADVERTISING_STORAGE_KEY])
    }

    if (existingGuestAnalyticsDecision) {
      commit('setState', { key: 'hasGuestSetPreferences', value: true })
    }

    dispatch('updateConsent', { savePreferences: false, allowAnalytics, allowAdvertising })

    dispatch('loadThirdPartyScripts')
  },

  acceptAll({ dispatch, commit }) {
    dispatch('updateConsent', { allowAnalytics: true, allowAdvertising: true })

    Vue.prototype.$analytics.addEvent({
      category: 'cookies',
      action: 'Accepted All',
      label: router.currentRoute.fullPath
    })

    commit('setState', { key: 'hasLoadedThirdPartyScripts', value: false })

    dispatch('loadThirdPartyScripts')
  },

  savePreferences({ dispatch }, { allowAnalytics, allowAdvertising }) {
    dispatch('updateConsent', { allowAnalytics, allowAdvertising })

    Vue.prototype.$analytics.addEvent({
      category: 'cookies',
      action: 'Saved custom preferences',
      label: `allowAnalytics: ${allowAnalytics.toString()}, allowAdvertising: ${allowAdvertising.toString()}`
    })
  },

  updateConsent({ commit, dispatch }, { allowAnalytics, allowAdvertising, savePreferences = true }) {
    if (savePreferences) {
      dispatch(
        'storage/save',
        {
          key: COOKIE_ANALYTICS_STORAGE_KEY,
          value: allowAnalytics
        },
        { root: true }
      )

      dispatch(
        'storage/save',
        {
          key: COOKIE_ADVERTISING_STORAGE_KEY,
          value: allowAdvertising
        },
        { root: true }
      )

      // For ICO compliance we need to store when the user made the decision
      dispatch(
        'storage/save',
        {
          key: 'cookiesSavedPreference',
          value: new Date().toISOString()
        },
        { root: true }
      )

      window.localStorage.setItem(COOKIE_ANALYTICS_STORAGE_KEY, allowAnalytics)
      window.localStorage.setItem(COOKIE_ADVERTISING_STORAGE_KEY, allowAdvertising)

      commit('setState', { key: 'hasGuestSetPreferences', value: true })
    }

    commit('setState', { key: 'allowAnalytics', value: allowAnalytics })
    commit('setState', { key: 'allowAdvertising', value: allowAdvertising })

    Vue.prototype.$analytics.updateConsent(allowAnalytics, allowAdvertising)
  },

  loadThirdPartyScripts({ state, commit }) {
    if (!state.hasLoadedThirdPartyScripts) {
      thirdPartyPlugins.init(state.allowAnalytics, state.allowAdvertising)

      commit('setState', { key: 'hasLoadedThirdPartyScripts', value: true })
    }
  }
}

const mutations = {
  setState(state, { key, value }) {
    state[key] = value
  },

  reset(state) {
    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
}
