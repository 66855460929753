import axios from 'axios'
import { detect as detectBrowser } from 'detect-browser'
import { isNativeApp } from '@/plugins/native-app/capacitor'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Device } from '@capacitor/device'
import log from '@/plugins/log'
import { isEmpty, get } from 'lodash'
import appVersion from '@/lib/app-version'

let offlineRetryTimer = null
let lastCheckedNetworkTimestamp = 0

const initialState = () => {
  return {
    network: {
      isOnline: true,
      lastChecked: 0
    },
    browser: {},
    info: {},
    hasFocus: true,
    version: appVersion,
    nativeAppOTAVersion: null,
    hasSetupPushNotifications: false,
    nativeKeyboardVisible: false,
    statusBarColor: 'green',
    needsFullRefresh: false,
    hasFinishedNativeSplashScreen: false
  }
}

const getters = {
  isNativeKeyboardVisible(state) {
    return state.nativeKeyboardVisible
  }
}

const actions = {
  async checkConnection({ state, commit, rootState, dispatch }, force = false) {
    // This is only here manually to prevent an infinite loop. To use automatic throttling use:
    // await yourApi.get('/url', undefined, { throttleMs: 5000 })
    const secondsSinceLastCheck = Math.ceil((Date.now() - lastCheckedNetworkTimestamp) / 1000)

    // force allows us to skip the timer lockout
    if (secondsSinceLastCheck < 3 && !force) {
      return
    }

    lastCheckedNetworkTimestamp = Date.now()

    try {
      let pingResult = await axios.get(process.env.VUE_APP_PING_URL, {
        timeout: 5000
      })

      if (get(pingResult, 'data.latestVersion.web')) {
        commit('app/setLatestVersion', pingResult.data.latestVersion, { root: true })
      }

      if (typeof get(pingResult, 'data.globalMessage') !== 'undefined') {
        commit('app/setGlobalMessage', pingResult.data.globalMessage, { root: true })
      }

      // If we were previously offline and the app hadn't loaded let's call app.get
      if (!state.network.isOnline && isEmpty(rootState.app.api.data)) {
        log.debug('App started offline, now online, fetching app.get')
        dispatch('app/get', null, { root: true })
      }

      commit('setIsOnline', true)

      // If we were previously polling for a connection while offline then let's clear that timer
      if (offlineRetryTimer) {
        clearInterval(offlineRetryTimer)
      }
    } catch (error) {
      console.warn('Internet is offline', error)

      // Once offline has been detected, let's check every x seconds since the
      // online / offline events aren't reliable enough
      if (!offlineRetryTimer) {
        offlineRetryTimer = setInterval(() => dispatch('checkConnection'), 5000)
      }

      commit('setIsOnline', false)
    }
  },

  async getInfo({ commit }) {
    if (isNativeApp) {
      const deviceInfo = await Device.getInfo()
      const languageCode = await Device.getLanguageCode()
      commit('setDeviceInfo', {
        ...deviceInfo,
        locale: languageCode.value
      })
    }

    if (!isNativeApp) {
      // Work out if we need to show the outdated browser message
      const browser = detectBrowser()

      if (browser && browser.name) {
        let outOfDate = false
        let version = parseInt(browser.version)

        // IE 10 and below are already take care of by index.html blocker
        if (browser.name === 'ie' && version === 11) {
          outOfDate = true
        }

        if (browser.name === 'chrome' && version <= 60) {
          outOfDate = true
        }

        if (browser.name === 'edge' && version <= 20) {
          outOfDate = true
        }

        let browserInfo = {
          ...browser,
          outOfDate
        }

        commit('setBrowser', browserInfo)
      }
    }
  },

  setupFocusChangeHandler({ commit }) {
    document.addEventListener('visibilitychange', () => commit('setHasFocus', !document.hidden), false)
  },

  // Extra listeners for browser online/offline events to re-trigger our checks
  // Since we only check before/after API calls
  setupConnectionChangeHandler({ dispatch }) {
    window.addEventListener('online', () => {
      log.debug('Browser online event')
      dispatch('checkConnection', true)
    })

    window.addEventListener('offline', () => {
      log.debug('Browser offline event')
      dispatch('checkConnection', true)
    })
  }
}

const mutations = {
  setIsOnline(state, isOnline) {
    state.network.isOnline = isOnline
  },

  setDeviceInfo(state, deviceInfo) {
    state.info = deviceInfo
  },

  setHasFocus(state, hasFocus) {
    state.hasFocus = hasFocus
  },

  setHasSetupPushNotifications(state, hasSetupPushNotifications) {
    state.hasSetupPushNotifications = hasSetupPushNotifications
  },

  setNativeAppOTAVersion(state, nativeAppOTAVersion) {
    state.nativeAppOTAVersion = nativeAppOTAVersion
  },

  setNativeKeyboardVisible(state, visible) {
    state.nativeKeyboardVisible = visible
  },

  setBrowser(state, browserInfo) {
    state.browser = browserInfo
  },

  setNeedsFullRefresh(state) {
    state.needsFullRefresh = true
  },

  setFinishedNativeSplashScreen(state) {
    state.hasFinishedNativeSplashScreen = true
  },

  setStatusBarColor(state, color) {
    state.statusBarColor = color

    if (isNativeApp) {
      const style = color === 'white' ? Style.Light : Style.Dark

      StatusBar.setStyle({
        style
      })
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
}
