import Vue from 'vue'
import VueI18n from 'vue-i18n'
import dateTimeFormats from '@/locales/formats/date-time'
import numberFormats from '@/locales/formats/number'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('../locales', false, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  return messages
}

export default new VueI18n({
  locale: 'en-GB',
  fallbackLocale: 'en-GB',
  messages: loadLocaleMessages(),
  silentFallbackWarn: process.env.VUE_APP_ENVIRONMENT === 'local',
  dateTimeFormats,
  numberFormats
})
