import axios from 'axios'
import { get } from 'lodash'
import log from '@/plugins/log'
// Do not import store up here due to circular dependancy crashing hell

class ApiCore {
  constructor(model = {}, timeout = 30000) {
    this.loading = false
    this.status = null
    this.message = null
    this.responseCode = null
    this.data = []
    this.progress = 0
    this.links = {}
    this.timeout = timeout
    this.model = model
    this.axios = axios.create()
    this.cancelSource = axios.CancelToken.source()
    // Used for throttling requests per method
    this.lastCalled = {
      GET: 0,
      POST: 0,
      DELETE: 0,
      HEAD: 0
    }
    this.hooks = {
      transformRequest: [],
      transformResponse: []
    }
  }

  async send(path, method = 'GET', data = this.model, config) {
    // We require store here due to circular dependancy hell (ask Alex)
    const store = require('@/store').default

    // Maintenance mode, block all requests except app.get
    if (store.state.app.isMaintenanceModeEnabled && !store.state.app.isMaintenanceModeBypassed) {
      return false
    }

    // Check throttling
    if (config.throttleMs) {
      const lastCalledMs = Date.now() - this.lastCalled[method]

      // Called too often
      if (lastCalledMs < config.throttleMs) {
        log.debug('Throttling call to', path)
        return false
      }
    }

    store.dispatch('device/checkConnection')

    // Log our last call for use in throttling
    this.lastCalled[method] = Date.now()

    let options = {
      method,
      timeout: this.timeout,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      url: path,
      responseType: 'json',
      isFormData: config.isFormData,
      cancelToken: this.cancelSource.token,
      transformRequest: this.hooks.transformRequest,
      transformResponse: this.hooks.transformResponse,
      onUploadProgress: progressEvent => {
        this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      }
    }

    if (method === 'GET') {
      options.params = data
    } else {
      options.data = data
    }

    this.loading = true
    this.status = null
    this.message = null

    let response = await this.axios(options)
      .catch(error => {
        // Pete returns 200 for all responses so need to look in different place vs normal rest API
        let statusCode = error.status || get(error, 'response.status') || 500

        this.responseCode = statusCode

        const ignoreError =
          error.response === undefined ||
          error.code === 'ECONNABORTED' ||
          !error.message ||
          statusCode < 500 ||
          axios.isCancel(error)

        // Let's not spam Sentry with errors caused by bad internet
        if (!ignoreError) {
          log.error(`API error ${path}`, error)
        } else {
          log.debug(`API ignored error ${path}`, error)
        }

        this.status = 'error'

        // Pete and Derek's APIs return different error layouts
        let errorMessage = get(error, 'data.message')

        // Some APIs will give us useful data in a 40x response
        if (get(error, 'response.data.data')) {
          this.data = error.response.data.data
        }

        // Derek uses data.text
        if (get(error, 'response.data.text')) {
          errorMessage = error.response.data.text
        } else if (get(error, 'response.data.message')) {
          errorMessage = error.response.data.message
        }

        if (!errorMessage) {
          errorMessage = 'Something went wrong, please reload and try again'
        }

        this.message = errorMessage

        throw new Error(this.message)
      })
      .finally(() => {
        this.loading = false
        store.dispatch('device/checkConnection')
      })

    this.loading = false

    if (response && response.data) {
      this.status = response.data.status
      this.message = response.data.message

      if (config.forceEmptyArrayToObject && Array.isArray(response.data.data) && response.data.data.length === 0) {
        this.data = {}
      } else {
        this.data = response.data.data
      }

      if (response.data._links) {
        this.links = response.data._links
      }
    } else if (this.status !== 'error') {
      log.error(`Request to ${path} failed`, JSON.stringify(response))
    }

    // Support method chaining
    return this
  }

  async get(path, params = undefined, config = {}) {
    return this.send(path, 'GET', params, config)
  }

  async post(path, data = undefined, config = {}) {
    return this.send(path, 'POST', data, config)
  }

  async postFormData(path, data = undefined, config = {}) {
    config = { ...config, isFormData: true }
    return this.send(path, 'POST', data, config)
  }

  async patch(path, data = undefined, config = {}) {
    return this.send(path, 'PATCH', data, config)
  }

  async put(path, data = undefined, config = {}) {
    return this.send(path, 'PUT', data, config)
  }

  async delete(path, data = undefined, config = {}) {
    return this.send(path, 'DELETE', data, config)
  }

  async head(path, data = undefined, config = {}) {
    return this.send(path, 'HEAD', data, config)
  }

  resetResponse() {
    this.status = null
    this.message = null
    this.responseCode = null
    this.data = []
  }

  cancel() {
    this.cancelSource.cancel()
  }

  // This is usually called on first page load to set
  // existing data from a local storage source
  hydrate(data) {
    if (!data) {
      return this
    }

    this.data = data

    return this
  }
}

export default ApiCore
