import ApiCore from './core'
import axios from 'axios'
import { forEach, mapValues, isObject, isPlainObject, mapKeys, camelCase, isArrayLikeObject } from 'lodash'

class SecurePaymentsApi extends ApiCore {
  constructor(model, timeout = 30000) {
    super(model, timeout)

    this.axios.interceptors.request.use(config => {
      const store = require('@/store').default

      config.baseURL = process.env.VUE_APP_FARMPAY_BASE_URL
      config.headers = {
        ...config.headers,
        platform: 'sellmylivestock',
        Authorization: `Bearer ${store.state.auth.token}`
      }

      // Derek's APIs require userRef to be in header instead of a parameter or body
      let app = store.getters['app/getApp']
      config.headers.userRef = app.user.userRef

      config.data = JSON.stringify(config.data)

      return config
    })

    // We don't want to work with ucfirst keys so let's camelCase the APIs response
    this.hooks.transformResponse = axios.defaults.transformResponse.concat(data => {
      return camelCaseKeys(data)
    })
  }
}

const camelCaseKeys = obj => {
  return deep(obj, x => {
    return mapKeys(x, (val, key) => {
      return camelCase(key)
    })
  })
}

const deep = (obj, mapper) => {
  return mapper(
    mapValues(obj, v => {
      let response = false
      let array = []

      if (isPlainObject(v)) {
        response = deep(v, mapper)
      } else if (isArrayLikeObject(v)) {
        forEach(v, value => {
          if (isObject(value)) {
            array.push(deep(value, mapper))
          } else {
            array.push(value)
          }
        })

        response = array
      } else {
        response = v
      }

      return response
    })
  )
}

export default SecurePaymentsApi
