var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-none",class:{ 'flex flex-col h-full': _vm.fullHeight }},_vm._l((_vm.compNav),function(item,i){return _c('RouterLink',{key:("item-" + i),staticClass:"account-nav-link",class:[
      _vm.linkClass,
      ("account-" + (item.text.toLowerCase())),
      { 'flex flex-col justify-center items-start flex-grow text-lg border-b border-gray-lighter': _vm.fullHeight }
    ],attrs:{"to":item.to,"exact":item.exactUrlMatch},nativeOn:{"click":function($event){return (function () {
        if (item.click) {
          item.click()
        }

        _vm.$emit('click')
      })($event)}}},[(_vm.showActiveIndicator)?_c('span',{staticClass:"account-nav-indicator"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"account-nav-text",class:{ 'h-full self-start': _vm.fullHeight }},[_c('FontAwesomeIcon',{staticClass:"account-nav-icon",attrs:{"icon":item.icon,"fixed-width":""}}),_vm._v(" "),_c('span',{staticClass:"relative"},[_vm._v("\n        "+_vm._s(item.text)+"\n      ")]),_vm._v(" "),(item.new)?_c('span',{staticClass:"new-tag ml-2"},[_vm._v("NEW")]):_vm._e()],1),_vm._v(" "),(item.badge)?_c('UtilBadgeCount',{staticClass:"account-nav-badge",attrs:{"count":item.badge < 10 ? item.badge : '9+',"unread":""}}):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }