import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode, configure } from 'vee-validate'
import { required, email, min, max, min_value as minValue, length, digits } from 'vee-validate/dist/rules'
import i18n from '@/plugins/i18n'
import fileTypes from '@/lib/file-types'
import { priceType } from '@/lib/mapping'
import vat from '@/lib/helpers/vat'
import log from '@/plugins/log'

// All our form field names must be translated
configure({
  defaultMessage: (field, values) => {
    // Override the field name.
    values._field_ = i18n.t(`formFields.${field}`)
    return i18n.t(`formValidation.${values._rule_}`, values)
  }
})

extend('required', required)
extend('email', email)
extend('min', min)
extend('max', max)
extend('digits', digits)
extend('minValue', minValue)
extend('length', length)

// Empty radio selections return TRUE, so we need to make sure there's actually a value
// This is untested with a radio that has a "true" option value...
extend('radioRequired', {
  validate: value => {
    if (value === true || value === '') {
      return false
    }

    return true
  }
})

extend('dateDashes', {
  message: i18n.t('formValidation.dateDashes'),
  validate: value => {
    if (value === '') {
      return true
    }

    const dateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/gm
    return value.match(dateRegex)
  }
})

extend('minAge18', {
  message: i18n.t('formValidation.minAge18'),
  validate: value => {
    if (value === '') {
      return true
    }

    let date = Vue.prototype.$date

    const today = date()
    const yearsOld = today.diff(date(value, date.legacyFormatDateOnly), 'year')

    return yearsOld >= 18
  }
})

extend('price', {
  message: i18n.t('formValidation.price'),
  validate: value => {
    value = parseInt(value)

    if (value === 999 || value === 123 || value === 1234 || value === 12345) {
      return i18n.t('formValidation.realisticPrice')
    }

    if (value > 1000000) {
      return i18n.t('formValidation.belowOneMillion')
    }

    return true
  }
})

extend('earTag', {
  message: i18n.t('formValidation.eartag'),
  validate: value => {
    if (value.length !== 14) {
      return i18n.t('formValidation.invalidEarTagLength')
    }

    const removeCheckSumCharacter = (string, charPosition) => {
      let part1 = string.substring(0, charPosition)
      let part2 = string.substring(charPosition + 1, string.length)
      return part1 + part2
    }

    const handleValidation = checkSumCharacterPosition => {
      // Strip whitespace
      let earTag = value.replace(/\s/g, '')

      // Remove the UK and the checksum digit
      let originalTag = removeCheckSumCharacter(earTag.slice(2), checkSumCharacterPosition - 2)

      // Get MOD 7 of original tag number
      let mod7 = parseInt(originalTag % 7)

      // Add 1 to MOD 7 result
      let checkSum = mod7 + 1

      if (parseInt(earTag.charAt(checkSumCharacterPosition)) === checkSum) {
        return true
      }
      return false
    }

    const isUKValid = handleValidation(8)
    const isNIValid = handleValidation(13)

    if (isUKValid || isNIValid) {
      return true
    }

    return i18n.t('formValidation.invalidEarTag')
  }
})

extend('requiresAllEarTags', {
  message: i18n.t('feedback.unknownError'),
  params: ['listingQuantity'],
  validate: (earTagsJsonString, { listingQuantity }) => {
    let earTags = JSON.parse(earTagsJsonString)

    let validEarTags = earTags.filter(tag => tag.eartag)

    if (validEarTags.length < listingQuantity) {
      return `Please enter all ${listingQuantity} eartags (${listingQuantity - validEarTags.length} missing)`
    }

    return true
  }
})

extend('holdingNumber', {
  message: i18n.t('formValidation.holdingNumber'),
  validate: value => {
    const holdingNumberRegex = /^[0-9][0-9]\/[0-9][0-9][0-9]\/[0-9][0-9][0-9][0-9]$/gm
    return value.match(holdingNumberRegex)
  }
})

extend('acceptTerms', {
  message: i18n.t('formValidation.acceptTerms'),
  validate: value => {
    return value
  }
})

extend('confirmTickbox', {
  validate: value => {
    if (value === true || value === 1) {
      return true
    }

    return '{_field_}'
  }
})

extend('bidOfferInRange', {
  message: i18n.t('formValidation.bidNotInRange'),
  params: ['priceData'],
  validate: (value, { priceData }) => {
    let bidPrice = Number(value)
    let askingPrice = priceData.type === priceType.RANGE ? Number(priceData.min) : Number(priceData.price)
    let twentyPercentOfAsking = 0.2 * askingPrice

    // If they are bidding less than 20% of the asking price let's fail validation
    if (bidPrice < askingPrice - twentyPercentOfAsking) {
      return false
    }

    return true
  }
})

// If the correct number of breeds has been selected
extend('workingDogsBreeds', {
  params: ['listing'],
  validate: (value, { listing }) => {
    if (!listing.categories.length) {
      return i18n.t('formValidation.dogsMinBreed')
    }

    if (listing.meta.cross === 0 && listing.categories.length > 1) {
      return i18n.t('formValidation.dogsSingleBreedPedigree')
    }

    if (listing.meta.cross === 1 && listing.categories.length < 2) {
      return i18n.t('formValidation.dogsSingleBreedCross')
    }

    return true
  }
})

// VAT validation
extend('vat', {
  message: i18n.t('formValidation.vat'),
  params: ['countryCode'],
  validate: (value, { countryCode }) => {
    // If empty then skip validation
    if (!value) {
      return true
    }

    // If we don't have validation for this countryCode then skip
    if (!vat[countryCode] || !(vat[countryCode].validate instanceof Function)) {
      return true
    }

    return vat[countryCode].validate(value)
  }
})

// Our custom file upload
extend('fileUpload', {
  message: i18n.t('feedback.unknownError'),
  params: ['requiredTypes', 'minFiles', 'maxFiles'],
  validate: (uploadedFiles, { requiredTypes, minFiles, maxFiles }) => {
    let validationErrors = []

    // Check overall counts
    if (uploadedFiles.length < minFiles) {
      const filesRemaining = minFiles - uploadedFiles.length
      validationErrors.push(i18n.tc('upload.minFilesRequired', filesRemaining))
    }

    if (uploadedFiles.length > maxFiles) {
      const filesToDeleteCount = maxFiles - uploadedFiles.length
      validationErrors.push(i18n.t('upload.deleteFilesToContinue', { filesToDeleteCount }))
    }

    // Check files have min/max counts for their type
    if (requiredTypes) {
      requiredTypes.forEach(requirement => {
        let type = requirement.type
        let extensionsForType = fileTypes[type]
        let minRequired = requirement.min || 0
        let maxAllowed = requirement.max

        let typeCount = 0
        uploadedFiles.forEach(file => {
          if (extensionsForType.includes(file.type)) {
            typeCount++
          }
        })

        if (typeCount < minRequired) {
          const remainingCount = minRequired - typeCount
          validationErrors.push(i18n.t('upload.uploadMoreTypes', { remainingCount, type }))
        }

        if (maxAllowed && typeCount > maxAllowed) {
          const deleteCount = maxAllowed - typeCount
          validationErrors.push(i18n.t('upload.maxUploaded', { minRequired, type, deleteCount }))
        }
      })
    }

    if (validationErrors.length) {
      log.debug('File upload errors', validationErrors)

      return validationErrors.join('. ')
    }

    return true
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// https://baianat.github.io/vee-validate/guide/interaction-and-ux.html#interaction-modes
setInteractionMode('eager-change', ({ errors, value }) => {
  if (errors.length) {
    return {
      on: ['input', 'change']
    }
  }

  // If the user hasn't entered a value yet then don't error on blur.
  // This is useful when using autofocus on a modal when the user
  // immediately clicks close modal, we won't want an error to show
  if (!value) {
    return {
      on: ['change']
    }
  } else {
    return {
      on: ['change', 'blur']
    }
  }
})
