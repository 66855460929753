import LegacyApi from '@/lib/api/legacy'

const initialState = () => {
  return {
    threadApi: new LegacyApi({ threadRef: '' }),
    replyApi: new LegacyApi({ threadRef: '', message: '', fileRefs: [] })
  }
}

const actions = {
  async getThread({ state, rootGetters }) {
    let appIs = rootGetters['app/appIs']

    // Don't auto mark thread as read if logged in as someone else
    let markAsRead = appIs.loggedInAsAnotherUser ? 0 : 1

    await state.threadApi.post('messages.get', {
      ...state.threadApi.model,
      includeMetaData: 1,
      markRead: markAsRead
    })
  },

  async replyThread({ state }, replyModel) {
    await state.replyApi.post('messages.add', replyModel)
  }
}

const mutations = {
  setThread(state, threadRef) {
    state.threadApi.model.threadRef = threadRef
    state.replyApi.model.threadRef = threadRef
  },

  addMessageToThread(state, message) {
    state.threadApi.data[0].messages.push(message)
  },

  resetReply(state, previousMessageModel = initialState().replyApi.model) {
    state.replyApi.model = {
      ...previousMessageModel,
      threadRef: state.replyApi.model.threadRef
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations
}
